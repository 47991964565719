import * as effects from 'redux-saga/effects';

import { storyTellerActions } from '../actions';
// eslint-disable-next-line prettier/prettier
import {
  fetchStory,
  fetchStoryMessages,
  loadMoreStoryMessages,
  createStoryChat,
  deleteStoryChat,
  updateStoryChat,
  regenerateImage,
  fetchRatioList,
} from './workers';
// IMPORTS

function* watchFetchStory() {
  yield effects.takeEvery(storyTellerActions.fetchStory.type, fetchStory);
}
function* watchFetchStoryMessages() {
  yield effects.takeEvery(
    storyTellerActions.fetchStoryMessages.type,
    fetchStoryMessages,
  );
}
function* watchLoadMoreStoryMessages() {
  yield effects.takeEvery(
    storyTellerActions.loadMoreStoryMessages.type,
    loadMoreStoryMessages,
  );
}
function* watchCreateStoryChat() {
  yield effects.takeEvery(
    storyTellerActions.createStoryChat.type,
    createStoryChat,
  );
}
function* watchDeleteStoryChat() {
  yield effects.takeEvery(
    storyTellerActions.deleteStoryChat.type,
    deleteStoryChat,
  );
}
function* watchUpdateStoryChat() {
  yield effects.takeEvery(
    storyTellerActions.updateStoryChat.type,
    updateStoryChat,
  );
}
function* watchRegenerateImage() {
  yield effects.takeEvery(
    storyTellerActions.regenerateImage.type,
    regenerateImage,
  );
}
function* watchFetchRatioList() {
  yield effects.takeEvery(
    storyTellerActions.fetchRatioList.type,
    fetchRatioList,
  );
}
// WATCHERS
export function* watchStoryTeller() {
  // eslint-disable-next-line prettier/prettier
  yield effects.all([
    effects.call(watchFetchStory),
    effects.call(watchFetchStoryMessages),
    effects.call(watchLoadMoreStoryMessages),
    effects.call(watchCreateStoryChat),
    effects.call(watchDeleteStoryChat),
    effects.call(watchUpdateStoryChat),
    effects.call(watchRegenerateImage),
    effects.call(watchFetchRatioList),
    // INJECT
  ]);
}
