import { Theme } from '@mui/material';

export const styles = {
  dotsAnimation: {
    position: 'relative',
    width: '12px',
    height: '12px',
  },
  first: (theme: Theme) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '10px',
    height: '10px',
    background: theme.palette.error.light,
    borderRadius: '50%',
  }),
  second: (theme: Theme) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '8px',
    height: '8px',
    background: theme.palette.common.warning.light,
    borderRadius: '50%',
  }),
  third: (theme: Theme) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '6px',
    height: '6px',
    background: theme.palette.error.light,
    borderRadius: '50%',
  }),
};
