// @ts-nocheck
import { handleError } from '@bus/ui/saga/workers/handleError';
import * as effects from 'redux-saga/effects';
import { createdImagesActions } from '@bus/createdImages/actions';
import { subscriptionsDetail } from '@bus/subscription/saga/workers';
import { WSAction, WSEvent } from '@bus/ws/typedefs';

export function* handleResponseStudio(payload: any) {
  try {
    if (
      payload.action === WSAction.notification &&
      payload.event === WSEvent.visualize
    ) {
      yield effects.put(
        createdImagesActions.addStudioImages({ images: payload.data.images }),
      );
    }
    if (
      payload.action === WSAction.response &&
      payload.event === WSEvent.visualize
    ) {
      yield effects.put(
        createdImagesActions.updateStudioImages({
          images: payload.data.images,
        }),
      );
    }
    if (
      payload.action === WSAction.error &&
      payload.event === WSEvent.visualize
    ) {
      yield effects.put(
        createdImagesActions.updateStudioImages({
          images: payload.data.images.map((item) => ({
            ...item,
            error: payload.data.error,
          })),
        }),
      );
    }
    if (
      payload.action === WSAction.notification &&
      payload.event === WSEvent.face_swap
    ) {
      yield effects.put(
        createdImagesActions.addSwapImages({ image: payload.data.image }),
      );
    }
    if (
      payload.action === WSAction.response &&
      payload.event === WSEvent.face_swap
    ) {
      yield effects.put(
        createdImagesActions.updateFaceSwapImages({ image: payload.data }),
      );
    }
    if (
      payload.action === WSAction.error &&
      payload.event === WSEvent.face_swap
    ) {
      const message = {
        ...payload.data.image,
        error: payload.data.error,
      };
      yield effects.put(
        createdImagesActions.updateFaceSwapImages({ image: message }),
      );
    }
    yield effects.call(subscriptionsDetail);
  } catch (e) {
    yield handleError(e);
  }
}
