import * as effects from 'redux-saga/effects';

import { handleError } from '@bus/ui/saga/workers/handleError';

import { getSubscriptionStatus } from '@bus/profile/selectors';
import { subscriptionActions } from '@bus/subscription/actions';

export function* intervalDetail() {
  try {
    while (true) {
      const status: string = yield effects.select(getSubscriptionStatus);
      if (status === 'approval_pending' || status === 'active') {
        break;
      }
      yield effects.put(subscriptionActions.subscriptionsDetail());
      yield effects.put(subscriptionActions.fetchSubscriptionPlans());
      yield effects.delay(5000);
    }
  } catch (e) {
    yield handleError(e);
  }
}
