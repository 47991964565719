import { Theme } from '@mui/material/styles';

export const styles = {
  authAppBar: (theme: Theme) => ({
    position: 'fixed',
    zIndex: 9998,
    width: '100%',
    top: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '18px 20px',
    background: theme.palette.common.surface['surface 5'],
  }),
};
