import * as effects from 'redux-saga/effects';
import { api } from '@REST/api';
import { handleError } from '@bus/ui/saga/workers/handleError';
import { throwError } from '@bus/ui/saga/workers/throwError';

import { optionsActions } from '../../actions';
import { FillMidjourneyParametersActionPayload } from '@bus/options/typedefs';

export function* fetchStorytellersStyles() {
  try {
    const response: Response = yield effects.apply(api, api.get, [
      { endpoint: 'storytellers/prompts/images/styles/' },
    ]);
    if (!response.ok) {
      yield throwError(response);
    }

    const data: FillMidjourneyParametersActionPayload = yield effects.call([
      response,
      'json',
    ]);
    const newData: FillMidjourneyParametersActionPayload = data.map((item) => ({
      ...item,
      parameter_type: { id: 5, name: 'Style', code: 'storyteller_style' },
    }));

    yield effects.put(optionsActions.fillMidjourneyParameters(newData));
  } catch (e) {
    yield handleError(e);
  }
}
