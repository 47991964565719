import { Theme } from '@mui/material';

export const styles = {
  share: (theme: Theme) => ({
    position: 'absolute',
    width: '100%',
    zIndex: 0,
    left: 0,
    bottom: 0,
    background: theme.palette.common.surface['surface 3'],
    padding: '10px 20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  }),
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    overflowX: 'auto',
    flexWrap: 'nowrap',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  button: (theme: Theme) => ({
    borderRadius: '50%',
    width: '40px',
    height: '40px',
    '&.Mui-disabled': {
      border: 'none',
    },
    '& svg': {
      '& path': {
        fill: theme.palette.text.disabled,
      },
    },
  }),
  copy: (theme: Theme) => ({
    '& svg': {
      '& path': {
        fill: theme.palette.text.primary,
      },
    },
  }),
  progress: (theme: Theme) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    color: theme.palette.divider,
    m: '0!important',
  }),
  leftWrapper: (theme: Theme) => ({
    borderRight: `1px solid ${theme.palette.divider}`,
    mr: '10px',
  }),
};
