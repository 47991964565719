import * as effects from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { api } from '@REST/api';
import { handleError } from '@bus/ui/saga/workers/handleError';
import { throwError } from '@bus/ui/saga/workers/throwError';

import { chatActions } from '../../actions';
import {
  LoadMoreMessagesActionPayload,
  PushMoreMessagesActionPayload,
} from '../../typedefs';
import { projectsActions } from '@bus/projects/actions';

export function* loadMoreMessages({
  payload,
}: PayloadAction<LoadMoreMessagesActionPayload>) {
  try {
    yield effects.put(chatActions.startFetching());
    const response: Response = yield effects.apply(api, api.get, [
      { endpoint: payload.url, direct: true },
    ]);
    if (!response.ok) {
      yield throwError(response);
    }
    const data: PushMoreMessagesActionPayload = yield effects.call([
      response,
      'json',
    ]);
    if (payload.isCustom) {
      yield effects.put(
        projectsActions.pushMoreCustomMessages({
          data,
          chatId: payload.chatId,
        }),
      );
    }
    if (payload.isNext) {
      yield effects.put(
        projectsActions.pushMoreMessages({ data, chatId: payload.chatId }),
      );
    }
    if (payload.isPrev) {
      yield effects.put(
        projectsActions.pushPrevMoreMessages({ data, chatId: payload.chatId }),
      );
    }
  } catch (e) {
    yield handleError(e);
  } finally {
    yield effects.put(chatActions.stopFetching());
  }
}
