import { createAction } from '@reduxjs/toolkit';

import aiConversationSlice from './slice';
import prepareActions from '@helpers/prepareActions';

export const aiConversationActions = {
  ...aiConversationSlice.actions,
  nextSession: createAction('aiConversation/nextSession'),
  fetchSessions: createAction('aiConversation/fetchSessions'),
  fetchMessages: createAction(
    'aiConversation/fetchMessages',
    (sessionId: number, isLive: boolean) => ({
      payload: { sessionId, isLive },
    }),
  ),
  loadMoreMessages: createAction(
    'aiConversation/loadMoreMessages',
    ({ url, sessionId }) => ({
      payload: { url, sessionId },
    }),
  ),
  sendEmail: createAction(
    'sendEmail/sendEmail',
    prepareActions.movePromiseToMeta,
  ),
  fetchMessageAudio: createAction(
    'aiConversation/fetchMessageAudio',
    prepareActions.movePromiseToMeta,
  ),
  // INJECT
};
