import * as effects from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { api } from '@REST/api';
import { handleError } from '@bus/ui/saga/workers/handleError';
import { throwError } from '@bus/ui/saga/workers/throwError';

import { storyTellerActions } from '../../actions';
import {
  FetchStoryMessagesActionPayload,
  FillStoryMessagesActionPayload,
} from '../../typedefs';

export function* fetchStoryMessages({
  payload,
}: PayloadAction<FetchStoryMessagesActionPayload>) {
  try {
    yield effects.put(storyTellerActions.startFetchingStory());
    const body: string = yield effects.call(JSON.stringify, payload);
    const response: Response = yield effects.apply(api, api.get, [
      { endpoint: `storytellers/chats/${payload.chatId}/messages/`, body },
    ]);
    if (!response.ok) {
      yield throwError(response);
    }

    const data: FillStoryMessagesActionPayload = yield effects.call([
      response,
      'json',
    ]);
    yield effects.put(
      storyTellerActions.fillStoryMessages({
        data,
        chatId: payload.chatId,
      }),
    );
    if (
      data.results[0].status !== 'completed' &&
      data.results[0].status !== 'error'
    ) {
      yield effects.put(
        storyTellerActions.setProgressChatId(data.results[0].chat),
      );
    }
  } catch (e) {
    yield handleError(e);
  } finally {
    yield effects.put(storyTellerActions.stopFetchingStory());
  }
}
