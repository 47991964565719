import { createSelector } from 'reselect';

import { RootState } from '@setup/typedefs';

const storyTellerSelector = (state: RootState) => state.storyTeller;

export const getIsStoryTellerFetching = createSelector(
  [storyTellerSelector],
  ({ isFetching }) => {
    return isFetching;
  },
);

export const getIsStoryUploadFetching = createSelector(
  [storyTellerSelector],
  ({ isFetchingStoryUpload }) => {
    return isFetchingStoryUpload;
  },
);

export const getIsStoryFetching = createSelector(
  [storyTellerSelector],
  ({ isFetchingStory }) => {
    return isFetchingStory;
  },
);

export const getStories = createSelector(
  [storyTellerSelector],
  ({ storyTeller }) => {
    return storyTeller;
  },
);

export const getStoriesMessages = (chatId?: string) =>
  createSelector([storyTellerSelector], ({ storyTeller }) => {
    if (chatId && storyTeller?.chats.length) {
      return (
        storyTeller.chats.find((chat) => chat.id === +chatId)?.messages ?? null
      );
    }

    return null;
  });

export const getIsChatInStory = (chatId?: string) =>
  createSelector([storyTellerSelector], ({ storyTeller }) => {
    if (chatId && storyTeller?.chats.length) {
      return storyTeller.chats.some((chat) => chat.id === +chatId);
    }

    return false;
  });

export const getNextMessagesUrl = (chatId?: string) =>
  createSelector([storyTellerSelector], ({ storyTeller }) => {
    if (chatId) {
      return (
        storyTeller?.chats.find((chat) => chat.id === +chatId)?.messages
          ?.next ?? null
      );
    }

    return null;
  });

export const getProgress = (chatId: number) =>
  createSelector([storyTellerSelector], ({ storyTeller }) => {
    if (chatId) {
      return storyTeller?.chats
        .find((chat) => chat.id === chatId)
        ?.messages?.results.find((message) => message.status !== 'completed');
    }
  });

export const getProgressChatId = createSelector(
  [storyTellerSelector],
  ({ progressChatId }) => {
    return progressChatId;
  },
);

export const getChatName = (storyId?: string) =>
  createSelector([storyTellerSelector], ({ storyTeller }) => {
    if (storyId) {
      return (
        storyTeller?.chats.find((chat) => chat.id === +storyId)?.name ?? ''
      );
    }

    return '';
  });

export const getAllRatio = createSelector(
  [storyTellerSelector],
  ({ allRatio }) => {
    return allRatio;
  },
);

export const getFile = createSelector(
  [storyTellerSelector],
  ({ storyFile }) => {
    return storyFile;
  },
);

export const getIsFileLoading = createSelector(
  [storyTellerSelector],
  ({ isFileLoading }) => {
    return isFileLoading;
  },
);

export const getIsShowStoryTellerSettings = createSelector(
  [storyTellerSelector],
  ({ isShowStoryTellerSettings }) => {
    return isShowStoryTellerSettings;
  },
);
