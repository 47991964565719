import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const CircleBar: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <mask id="path-1-inside-1_240_45463" fill="white">
        <path d="M23.9962 178.581C11.0672 162.367 2.98465 142.829 0.680883 122.22C-1.62288 101.611 1.94597 80.7704 10.9758 62.1021C20.0056 43.4338 34.1286 27.6983 51.7158 16.7104C69.303 5.72249 89.638 -0.070126 110.375 0.000640482C131.113 0.071407 151.408 6.00267 168.92 17.1103C186.431 28.218 200.447 44.0496 209.349 62.7791C218.251 81.5085 221.678 102.373 219.233 122.966C216.789 143.559 208.573 163.041 195.534 179.166L187.744 172.868C199.596 158.211 207.064 140.503 209.286 121.785C211.507 103.068 208.393 84.1032 200.301 67.0793C192.21 50.0555 179.471 35.6657 163.554 25.5695C147.637 15.4734 129.19 10.0823 110.341 10.018C91.4923 9.95367 73.0092 15.2188 57.0236 25.206C41.038 35.1933 28.2012 49.4958 19.9937 66.464C11.7862 83.4323 8.54232 102.375 10.6363 121.107C12.7303 139.84 20.0767 157.598 31.8284 172.336L23.9962 178.581Z" />
      </mask>
      <path
        d="M23.9962 178.581C11.0672 162.367 2.98465 142.829 0.680883 122.22C-1.62288 101.611 1.94597 80.7704 10.9758 62.1021C20.0056 43.4338 34.1286 27.6983 51.7158 16.7104C69.303 5.72249 89.638 -0.070126 110.375 0.000640482C131.113 0.071407 151.408 6.00267 168.92 17.1103C186.431 28.218 200.447 44.0496 209.349 62.7791C218.251 81.5085 221.678 102.373 219.233 122.966C216.789 143.559 208.573 163.041 195.534 179.166L187.744 172.868C199.596 158.211 207.064 140.503 209.286 121.785C211.507 103.068 208.393 84.1032 200.301 67.0793C192.21 50.0555 179.471 35.6657 163.554 25.5695C147.637 15.4734 129.19 10.0823 110.341 10.018C91.4923 9.95367 73.0092 15.2188 57.0236 25.206C41.038 35.1933 28.2012 49.4958 19.9937 66.464C11.7862 83.4323 8.54232 102.375 10.6363 121.107C12.7303 139.84 20.0767 157.598 31.8284 172.336L23.9962 178.581Z"
        strokeWidth="20"
        mask="url(#path-1-inside-1_240_45463)"
      />
    </SvgIcon>
  );
};

export default CircleBar;
