import prepareActions from '@helpers/prepareActions';
import { createAction } from '@reduxjs/toolkit';

import authSlice from './slice';

export const authActions = {
  ...authSlice.actions,
  authenticate: createAction('auth/authenticate'),
  signUp: createAction('auth/signUp', prepareActions.movePromiseToMeta),
  applyMembership: createAction(
    'applications',
    prepareActions.movePromiseToMeta,
  ),
  signIn: createAction('auth/signIn', prepareActions.withPromiseNavigate),
  forgotPassword: createAction(
    'auth/reset-password-request',
    prepareActions.movePromiseToMeta,
  ),
  resetPassword: createAction(
    'auth/reset-password',
    prepareActions.movePromiseToMeta,
  ),
  confirmEmail: createAction(
    'auth/confirm-email',
    prepareActions.movePromiseToMeta,
  ),
  verificationEmail: createAction(
    'verificationEmail/verificationEmail',
    prepareActions.movePromiseToMeta,
  ),
  resendCode: createAction('auth/resendCode', (payload: any) => {
    return { payload };
  }),
  // INJECT
};
