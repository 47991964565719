import * as effects from 'redux-saga/effects';

import { createdImagesActions } from '../actions';
// eslint-disable-next-line prettier/prettier
import {
  fetchCreatedImages,
  loadMoreImages,
  fetchSummary,
  fetchStudioImages,
  loadMoreStudioImages,
  fetchImageStudioFaces,
  createSwap,
  updateSwap,
  fetchFaceSwapImages,
  loadMoreSwapImages,
  fetchStudioImageSummary,
  regenerateGeneratedImage,
  deleteImageStudioFaces,
  deleteCreatedImages,
  deleteFaceSwapImages,
  deleteStudioImages,
} from './workers';
// IMPORTS

function* watchFetchCreatedImages() {
  yield effects.takeEvery(
    createdImagesActions.fetchCreatedImages.type,
    fetchCreatedImages,
  );
}
function* watchLoadMoreImages() {
  yield effects.takeEvery(
    createdImagesActions.loadMoreImages.type,
    loadMoreImages,
  );
}
function* watchFetchSummary() {
  yield effects.takeEvery(createdImagesActions.fetchSummary.type, fetchSummary);
}
function* watchFetchStudioImages() {
  yield effects.takeEvery(
    createdImagesActions.fetchStudioImages.type,
    fetchStudioImages,
  );
}
function* watchLoadMoreStudioImages() {
  yield effects.takeEvery(
    createdImagesActions.loadMoreStudioImages.type,
    loadMoreStudioImages,
  );
}
function* watchFetchImageStudioFaces() {
  yield effects.takeEvery(
    createdImagesActions.fetchImageStudioFaces.type,
    fetchImageStudioFaces,
  );
}
function* watchCreateSwap() {
  yield effects.takeEvery(createdImagesActions.createSwap.type, createSwap);
}
function* watchUpdateSwap() {
  yield effects.takeEvery(createdImagesActions.updateSwap.type, updateSwap);
}
function* watchFetchFaceSwapImages() {
  yield effects.takeEvery(
    createdImagesActions.fetchFaceSwapImages.type,
    fetchFaceSwapImages,
  );
}
function* watchLoadMoreSwapImages() {
  yield effects.takeEvery(
    createdImagesActions.loadMoreSwapImages.type,
    loadMoreSwapImages,
  );
}
function* watchFetchStudioImageSummary() {
  yield effects.takeEvery(
    createdImagesActions.fetchStudioImageSummary.type,
    fetchStudioImageSummary,
  );
}
function* watchRegenerateGeneratedImage() {
  yield effects.takeEvery(
    createdImagesActions.regenerateGeneratedImage.type,
    regenerateGeneratedImage,
  );
}
function* watchDeleteImageStudioFaces() {
  yield effects.takeEvery(
    createdImagesActions.deleteImageStudioFaces.type,
    deleteImageStudioFaces,
  );
}
function* watchDeleteCreatedImages() {
  yield effects.takeEvery(
    createdImagesActions.deleteCreatedImages.type,
    deleteCreatedImages,
  );
}
function* watchDeleteFaceSwapImages() {
  yield effects.takeEvery(
    createdImagesActions.deleteFaceSwapImages.type,
    deleteFaceSwapImages,
  );
}
function* watchDeleteStudioImages() {
  yield effects.takeEvery(
    createdImagesActions.deleteStudioImages.type,
    deleteStudioImages,
  );
}
// WATCHERS
export function* watchCreatedImages() {
  // eslint-disable-next-line prettier/prettier
  yield effects.all([
    effects.call(watchFetchCreatedImages),
    effects.call(watchLoadMoreImages),
    effects.call(watchFetchSummary),
    effects.call(watchFetchStudioImages),
    effects.call(watchLoadMoreStudioImages),
    effects.call(watchFetchImageStudioFaces),
    effects.call(watchCreateSwap),
    effects.call(watchUpdateSwap),
    effects.call(watchFetchFaceSwapImages),
    effects.call(watchLoadMoreSwapImages),
    effects.call(watchFetchStudioImageSummary),
    effects.call(watchRegenerateGeneratedImage),
    effects.call(watchDeleteImageStudioFaces),
    effects.call(watchDeleteCreatedImages),
    effects.call(watchDeleteFaceSwapImages),
    effects.call(watchDeleteStudioImages),
    // INJECT
  ]);
}
