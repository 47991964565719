import React from 'react';

import { SvgIcon, SvgIconProps, useTheme } from '@mui/material';

const CircleBack: React.FC<SvgIconProps> = (props) => {
  const theme = useTheme();

  return (
    <SvgIcon {...props}>
      <circle cx="110" cy="110" r="105" strokeWidth="10" fill="none" />
      <line
        x1="25"
        y1="180.5"
        x2="195"
        y2="180.5"
        stroke={theme.palette.divider}
      />
      <path
        d="M25 181H194L174 213.5L125.5 220H108H73L30.5 204L25 181Z"
        fill={theme.palette.background.paper}
        stroke="none"
      />
    </SvgIcon>
  );
};

export default CircleBack;
