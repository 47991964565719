import React from 'react';

import {
  Box,
  Button,
  Link as MuiLink,
  Typography,
  useTheme,
} from '@mui/material';

import { ModalTypes } from '@core/Modal/types';

import { styles } from './styles';
import { useDispatch } from 'react-redux';
import { profileActions } from '@bus/profile/actions';
import sibylStoryTellerImage from '@assets/sibyl-storyteller.jpg';

type AppUpdatesProps = {
  title: string;
  description: string;
};

export const AppUpdates: React.FC<
  ModalTypes.ModalComponentProps<AppUpdatesProps>
> = ({ closeFn, title, description }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const updateProfile = () => {
    new Promise((resolve, reject) => {
      dispatch(
        profileActions.updateProfile({
          values: { show_update_modal_window: false },
          resolve,
          reject,
        }),
      );
    })
      .then(() => closeFn())
      .catch();
  };

  return (
    <Box sx={styles.appUpdates}>
      <Box sx={styles.iconWrapper}>
        <Box component={'img'} src={sibylStoryTellerImage} sx={styles.image} />
      </Box>
      <Box p={'20px'}>
        <Box mb={'20px'}>
          <Typography variant={'h5'} color={'text.primary'} mb={'20px'}>
            {title}
          </Typography>
          <Typography variant={'body1'} color={'text.primary'}>
            {description}
          </Typography>
        </Box>
        <Box
          width={'100%'}
          display={'flex'}
          flexDirection={'column'}
          gap={'20px'}>
          <Box
            sx={{
              width: '100%',
              background: theme.palette.primary.main,
              padding: '14px 16px',
              borderRadius: '10px',
            }}>
            <MuiLink
              onClick={updateProfile}
              href={
                'https://support.secretenergy.com/en/articles/8654074-what-is-storyteller-and-how-do-i-use-it'
              }
              target={'_blank'}
              style={{
                display: 'flex',
                justifyContent: 'center',
                textDecoration: 'none',
                color: theme.palette.primary.contrastText,
              }}>
              <Typography variant={'button'}>Read More</Typography>
            </MuiLink>
          </Box>
          <Button
            fullWidth
            sx={{
              padding: '0 16px',
              height: 'max-content',
            }}
            onClick={updateProfile}
            variant={'secondary'}
            color={'secondary'}>
            <Typography variant={'button'} color={'common.text.link'}>
              Got it
            </Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default AppUpdates;
