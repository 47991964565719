import { FORM_ERROR } from 'final-form';
import * as effects from 'redux-saga/effects';
import { api } from '@REST/api';
import { handleError } from '@bus/ui/saga/workers/handleError';
import { throwError } from '@bus/ui/saga/workers/throwError';
import { PayloadActionWithPromiseMeta } from '@core/FinalForm/typedefs';

import { aiConversationActions } from '../../actions';
import { SendEmailActionPayload } from '../../typedefs';

export function* sendEmail({
  payload,
  meta: { resolve, reject },
}: PayloadActionWithPromiseMeta<SendEmailActionPayload>) {
  try {
    yield effects.put(aiConversationActions.startFetching());
    const body: string = yield effects.call(JSON.stringify, payload);
    const response: Response = yield effects.apply(api, api.post, [
      {
        endpoint: 'aiconversation/send-email-notification',
        body,
        unsafe: true,
      },
    ]);
    if (!response.ok) {
      reject({
        [FORM_ERROR]: 'Submit failed',
      });
      yield throwError(response);
    }
    resolve();
  } catch (e) {
    yield handleError(e);
  } finally {
    yield effects.put(aiConversationActions.stopFetching());
  }
}
