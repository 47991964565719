import * as effects from 'redux-saga/effects';

import { aiConversationActions } from '../actions';
// eslint-disable-next-line prettier/prettier
import {
  nextSession,
  fetchSessions,
  fetchMessages,
  loadMoreMessages,
  sendEmail,
  fetchMessageAudio,
} from './workers';

// IMPORTS
function* watchNextSession() {
  yield effects.takeEvery(aiConversationActions.nextSession.type, nextSession);
}
function* watchFetchSessions() {
  yield effects.takeEvery(
    aiConversationActions.fetchSessions.type,
    fetchSessions,
  );
}
function* watchFetchMessages() {
  yield effects.takeEvery(
    aiConversationActions.fetchMessages.type,
    fetchMessages,
  );
}
function* watchLoadMoreMessages() {
  yield effects.takeEvery(
    aiConversationActions.loadMoreMessages.type,
    loadMoreMessages,
  );
}
function* watchSendEmail() {
  yield effects.takeEvery(aiConversationActions.sendEmail.type, sendEmail);
}
function* watchFetchMessageAudio() {
  yield effects.takeEvery(
    aiConversationActions.fetchMessageAudio.type,
    fetchMessageAudio,
  );
}
// WATCHERS
export function* watchAiConversation() {
  // eslint-disable-next-line prettier/prettier
  yield effects.all([
    effects.call(watchNextSession),
    effects.call(watchFetchSessions),
    effects.call(watchFetchMessages),
    effects.call(watchLoadMoreMessages),
    effects.call(watchSendEmail),
    effects.call(watchFetchMessageAudio),
    // INJECT
  ]);
}
