import { useEffect, useState, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getIsProjectsAudioFetching } from '@bus/projects/selectors';
import { useAudioRecorder } from '@components/pages/private/ChatPage/MessageInput/useAudioRecorder';
import {
  getCanDoAction,
  getShowBuyTokensModal,
  getShowUpdatePlanToUseTokensModal,
} from '@bus/profile/selectors';
import { handleErrors } from '@helpers/handleErrors';
import { getIsAudioPlaying, getIsChatTyping } from '@bus/chat/selectors';
import { getIsGlobalSpeaking } from '@bus/ui/selectors';
import { chatActions } from '@bus/chat/actions';
import { useLimits } from './useLimits';

const useVoiceRecognition = (setIsError: (e: boolean) => void) => {
  const recognitionRef = useRef<any>(null);
  const [isListening, setIsListening] = useState(false);
  const [hasStartedSpeaking, setHasStartedSpeaking] = useState(false);
  const dispatch = useDispatch();
  const globalSpeaking = useSelector(getIsGlobalSpeaking);
  const isTyping = useSelector(getIsChatTyping);
  const isAudioPlaying = useSelector(getIsAudioPlaying);
  const isProjectsAudioFetching = useSelector(getIsProjectsAudioFetching);
  const canDoAction = useSelector(getCanDoAction);
  const showBuyTokensModal = useSelector(getShowBuyTokensModal);
  const showUpdatePlanToUseTokensModal = useSelector(
    getShowUpdatePlanToUseTokensModal,
  );

  const { handleLimitExceeded } = useLimits(
    showUpdatePlanToUseTokensModal,
    showBuyTokensModal,
  );

  const scrollToBottom = () => {
    const id = document.getElementById('anchor');
    if (id) {
      id.scrollIntoView();
    }
  };

  const { handleStartRecording } = useAudioRecorder(scrollToBottom);
  const handleSpeechRecognition = useCallback(
    (event: any) => {
      if (!isListening || event.results.length === 0) {
        return;
      }

      setHasStartedSpeaking(true);
    },
    [isListening],
  );

  useEffect(() => {
    if (!recognitionRef.current) {
      if ('SpeechRecognition' in window) {
        recognitionRef.current = new (window as any).SpeechRecognition();
      } else if ('webkitSpeechRecognition' in window) {
        recognitionRef.current = new (window as any).webkitSpeechRecognition();
      } else {
        handleErrors('SpeechRecognition is not supported in this browser.');

        return;
      }
      recognitionRef.current.continuous = true;
      recognitionRef.current.interimResults = false;
      recognitionRef.current.lang = 'en-US';

      recognitionRef.current.onerror = (event: any) => {
        setIsError(true);
        handleErrors(event.error);
      };
    }

    if (recognitionRef.current) {
      if (isListening) {
        recognitionRef.current.addEventListener(
          'result',
          handleSpeechRecognition,
        );
        recognitionRef.current.start();
      } else {
        recognitionRef.current.removeEventListener(
          'result',
          handleSpeechRecognition,
        );
        recognitionRef.current.continuous = false;
        recognitionRef.current.stop();
      }
    }

    return () => {
      recognitionRef.current?.removeEventListener(
        'result',
        handleSpeechRecognition,
      );
    };
  }, [isListening, handleSpeechRecognition]);

  useEffect(() => {
    if (hasStartedSpeaking) {
      if (!canDoAction) {
        handleLimitExceeded();

        return;
      }

      dispatch(chatActions.stopVoiceDetected());
      handleStartRecording();
      setHasStartedSpeaking(false);
    }
  }, [hasStartedSpeaking, isListening]);

  const startListening = () => {
    if (!isListening) {
      setIsListening(true);
    }

    return true;
  };

  const stopListening = () => {
    setIsListening(false);
    setHasStartedSpeaking(false);

    return false;
  };

  useEffect(() => {
    if (
      (isTyping || isAudioPlaying || isProjectsAudioFetching) &&
      globalSpeaking
    ) {
      stopListening();
    }
    if (
      !isTyping &&
      !isAudioPlaying &&
      !isProjectsAudioFetching &&
      globalSpeaking
    ) {
      startListening();
    }
  }, [isTyping, isAudioPlaying, isProjectsAudioFetching]);

  return { startListening, stopListening, isListening };
};

export default useVoiceRecognition;
