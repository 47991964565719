import * as effects from 'redux-saga/effects';
import { api } from '@REST/api';
import { handleError } from '@bus/ui/saga/workers/handleError';
import { throwError } from '@bus/ui/saga/workers/throwError';

import { aiConversationActions } from '../../actions';
import { FillNextSessionActionPayload } from '@bus/aiConversation/typedefs';

export function* nextSession() {
  try {
    yield effects.put(aiConversationActions.startFetching());
    const response: Response = yield effects.apply(api, api.get, [
      { endpoint: 'aiconversation/next-session', unsafe: true },
    ]);

    if (!response.ok) {
      yield throwError(response);
    }

    const data: FillNextSessionActionPayload = yield effects.call([
      response,
      'json',
    ]);

    if (data.detail === 'Not found') {
      yield effects.put(
        aiConversationActions.fillNextSession({
          id: 0,
          end_at: '',
          start_at: '',
          is_completed: true,
          project_description: '',
        }),
      );
    } else {
      yield effects.put(aiConversationActions.fillNextSession(data));
    }
  } catch (e) {
    yield handleError(e);
  } finally {
    yield effects.put(aiConversationActions.stopFetching());
  }
}
