import * as effects from 'redux-saga/effects';
import { api } from '@REST/api';
import { handleError } from '@bus/ui/saga/workers/handleError';
import { throwError } from '@bus/ui/saga/workers/throwError';

import { createdImagesActions } from '../../actions';
import {
  IRegenerateStudio,
  RegenerateGeneratedImageActionPayload,
} from '../../typedefs';
import { profileActions } from '@bus/profile/actions';
import { wsActions } from '@bus/ws/actions';
import { PayloadActionWithPromiseMeta } from '@core/FinalForm/typedefs';

export function* regenerateGeneratedImage({
  payload,
  meta: { resolve, reject },
}: PayloadActionWithPromiseMeta<RegenerateGeneratedImageActionPayload>) {
  try {
    yield effects.put(createdImagesActions.startFetching());
    const body: string = yield effects.call(JSON.stringify, payload);
    const response: Response = yield effects.apply(api, api.get, [
      { endpoint: `image-studio/images/${payload.id}/`, body },
    ]);
    if (!response.ok) {
      yield throwError(response);
    }

    const data: IRegenerateStudio = yield effects.call([response, 'json']);

    yield effects.put(
      wsActions.sendImageRequest({
        resolve,
        reject,
        values: {
          action: 'request',
          app: 'image_studio',
          event: 'visualize',
          data: data.result,
        },
      }),
    );
    yield effects.put(profileActions.setScaleImage(null));
  } catch (e) {
    yield handleError(e);
  } finally {
    yield effects.put(createdImagesActions.stopFetching());
  }
}
