import { Theme } from '@mui/material/styles';

export const styles = {
  label: (theme: Theme) => ({
    margin: 0,
    '& .MuiButtonBase-root': {
      padding: 0,
      '& svg': {
        '& rect': {
          stroke: theme.palette.divider,
        },
      },
      '&:hover': {
        '& svg': {
          '& rect': {
            stroke: theme.palette.common.surface.contrast,
          },
        },
      },
    },
  }),
  error: (theme: Theme) => ({
    margin: 0,
    '& .MuiButtonBase-root': {
      padding: 0,
      '& svg': {
        '& rect': {
          stroke: theme.palette.error.main,
        },
      },
    },
  }),
};
