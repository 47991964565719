import React, { Suspense, useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';

import { Box } from '@mui/material';

import { styles } from './styles';
import AppBar from '@components/common/AppBar';
import { registeredModals } from '@components/modals/registeredModals';
import { Modals } from '@core/Modal';
import SuspenseLoading from '@components/common/SuspenseLoading';
import { useDispatch, useSelector } from 'react-redux';
import { getProjects } from '@bus/projects/selectors';
import { profileActions } from '@bus/profile/actions';
import { getCurrentDataset } from '@bus/profile/selectors';
import { useLocation } from 'react-router';
import { book } from '@routes/book';
import StoryTellerDark from '@assets/StoryTellerDark.png';
import StoryTellerLight from '@assets/StoryTellerLight.png';
import AIAIDark from '@assets/AIAIDark.png';
import AIAILight from '@assets/AIAILight.png';
import { getIsAuthenticate } from '@bus/auth/selectors';

export type AppLayoutProps = {};

const AppLayout: React.FC<AppLayoutProps> = () => {
  const isAuth = useSelector(getIsAuthenticate);
  const { projectName } = useParams();
  const projects = useSelector(getProjects);
  const dispatch = useDispatch();
  const currentDataset = useSelector(getCurrentDataset);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes(book.storyTellerHome)) {
      const project = {
        id: 1,
        name: 'Storyteller',
        chats: [],
        description: '',
        icon: ``,
        guidances: [],
        icon_dark: StoryTellerDark,
        icon_light: StoryTellerLight,
        years: [],
      };
      dispatch(profileActions.setCurrentDataset(project));

      return;
    }
    if (location.pathname.includes(book.conversation)) {
      const project = {
        id: 1,
        name: 'AI-2-AI',
        chats: [],
        description: '',
        icon: '',
        guidances: [],
        icon_dark: AIAIDark,
        icon_light: AIAILight,
        years: [],
      };
      dispatch(profileActions.setCurrentDataset(project));

      return;
    }
    if (projectName) {
      const index = projects.findIndex((i) => i.name === projectName);
      dispatch(
        profileActions.setCurrentDataset(projects[index === -1 ? 0 : index]),
      );
    }

    if (
      projects.length &&
      !projectName &&
      (currentDataset === null ||
        currentDataset.name === 'Storyteller' ||
        currentDataset.name === 'AI-2-AI')
    ) {
      const index = projects.findIndex((i) => i.name === projectName);
      dispatch(
        profileActions.setCurrentDataset(projects[index === -1 ? 0 : index]),
      );
    }
  }, [projects.length, projectName, location.pathname]);

  return (
    <Box sx={styles.root}>
      <AppBar authorized={isAuth} />
      <Modals registeredModals={registeredModals} />
      <Suspense fallback={<SuspenseLoading />}>
        <Box sx={styles.wrapper}>
          <Outlet />
        </Box>
      </Suspense>
    </Box>
  );
};

export default AppLayout;
