import * as effects from 'redux-saga/effects';
import { api } from '@REST/api';
import { handleError } from '@bus/ui/saga/workers/handleError';
import { throwError } from '@bus/ui/saga/workers/throwError';

import { storyTellerActions } from '../../actions';
import {
  CreateStoryChatActionPayload,
  UpdateStoryChatActionPayload,
} from '../../typedefs';
import { book } from '@routes/book';
import { PayloadActionWithPromiseNavigateMeta } from '@core/FinalForm/typedefs';

export function* createStoryChat({
  payload,
  meta: { resolve, reject, navigate },
}: PayloadActionWithPromiseNavigateMeta<CreateStoryChatActionPayload>) {
  try {
    yield effects.put(storyTellerActions.startFetching());
    const body: string = yield effects.call(JSON.stringify, payload);
    const response: Response = yield effects.apply(api, api.post, [
      { endpoint: 'storytellers/chats/', body },
    ]);
    if (!response.ok) {
      reject();
      yield throwError(response);
    }

    const data: UpdateStoryChatActionPayload = yield effects.call([
      response,
      'json',
    ]);
    yield effects.put(storyTellerActions.updateChat(data));

    navigate(`${book.storyTeller}/${data.id}`);
    resolve(data);
  } catch (e) {
    yield handleError(e);
  } finally {
    yield effects.put(storyTellerActions.stopFetching());
  }
}
