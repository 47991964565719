// eslint-disable-next-line @typescript-eslint/no-unused-vars,prettier/prettier
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// eslint-disable-next-line prettier/prettier
import {
  SubscriptionState,
  FillSubscriptionPlansActionPayload,
  BillingStatus,
} from './typedefs';

const initialState: SubscriptionState = {
  isFetching: false,
  isSubscriptionCancelFetching: false,
  subscriptionPlans: [],
  billingStatus: null,
};

const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    startFetching(state) {
      state.isFetching = true;
    },
    stopFetching(state) {
      state.isFetching = false;
    },
    startSubscriptionCancelFetching(state) {
      state.isSubscriptionCancelFetching = true;
    },
    stopSubscriptionCancelFetching(state) {
      state.isSubscriptionCancelFetching = false;
    },
    fillSubscriptionPlans(
      state,
      action: PayloadAction<FillSubscriptionPlansActionPayload>,
    ) {
      state.subscriptionPlans = action.payload;
    },
    setBillingStatus(state, action: PayloadAction<BillingStatus | null>) {
      state.billingStatus = action.payload;
    },
    // INJECT
  },
});

export default subscriptionSlice;
