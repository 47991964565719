// eslint-disable-next-line @typescript-eslint/no-unused-vars,prettier/prettier
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// eslint-disable-next-line prettier/prettier
import {
  PersistState,
} from './typedefs';
import { ModalTypes } from '@core/Modal/types';

const initialState: PersistState = {
  modalData: {},
  formValues: {},
};

const persistSlice = createSlice({
  name: 'persist',
  initialState,
  reducers: {
    modal(state, action: PayloadAction<ModalTypes.ModalPayload<any>>) {
      state.modalData = {
        ...state.modalData,
        [action.payload.component]: action.payload,
      };
    },
    closeModal(state, action: PayloadAction<any>) {
      delete state.modalData[action.payload];
    },
    fillForm(state, action: PayloadAction<any>) {
      state.formValues = action.payload;
    },
    clearForm(state) {
      state.formValues = {};
    },
    // INJECT
  },
});

export default persistSlice;
