export const styles = {
  limitExhausted: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: '0 32px',
  },
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: '16px',
    padding: '40px 0',
    maxWidth: '1180px',
    width: '100%',
  },
};
