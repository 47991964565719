import { Theme } from '@mui/material/styles';

export const styles = {
  appUpdates: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '480px',
    width: '100%',
  },
  iconWrapper: (theme: Theme) => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    background: theme.palette.common.surface['surface 1'],
  }),
  image: {
    width: '100%',
    objectFit: 'cover',
  },
};
