import * as effects from 'redux-saga/effects';
import { api } from '@REST/api';
import { handleError } from '@bus/ui/saga/workers/handleError';
import { throwError } from '@bus/ui/saga/workers/throwError';

import { createdImagesActions } from '../../actions';
import { DeleteCreatedImagesActionPayload } from '@bus/createdImages/typedefs';
import { PayloadActionWithPromiseMeta } from '@core/FinalForm/typedefs';
import { projectsActions } from '@bus/projects/actions';

export function* deleteCreatedImages({
  payload,
  meta: { resolve, reject },
}: PayloadActionWithPromiseMeta<DeleteCreatedImagesActionPayload>) {
  try {
    yield effects.put(createdImagesActions.startFetching());
    const response: Response = yield effects.apply(api, api.delete, [
      { endpoint: `users/images/${payload.id}/` },
    ]);
    if (!response.ok) {
      reject();
      yield throwError(response);
    }
    yield effects.put(
      createdImagesActions.removeCreatedImage({ id: payload.id }),
    );
    yield effects.put(
      projectsActions.removeImages({
        imageId: payload.id,
        chatId: payload.chatId,
        messageId: payload.messageId,
      }),
    );
    resolve();
  } catch (e) {
    yield handleError(e);
  } finally {
    yield effects.put(createdImagesActions.stopFetching());
  }
}
