import * as effects from 'redux-saga/effects';
import { api } from '@REST/api';
import { handleError } from '@bus/ui/saga/workers/handleError';
import { throwError } from '@bus/ui/saga/workers/throwError';

import { projectsActions } from '../../actions';
import { PostRateActionPayload } from '../../typedefs';
import { PayloadActionWithPromiseMeta } from '@core/FinalForm/typedefs';
export function* postRate({
  payload,
  meta: { resolve, reject },
}: PayloadActionWithPromiseMeta<PostRateActionPayload>) {
  const newPayload = {
    rate: payload.rate,
    message_id: payload.message_id,
  };
  try {
    const body: string = yield effects.call(JSON.stringify, newPayload);
    const response: Response = yield effects.apply(api, api.post, [
      { endpoint: `messages/${payload.message_id}/rate`, body },
    ]);

    if (!response.ok) {
      yield throwError(response);
      reject();
    }

    yield effects.put(projectsActions.changeRate(payload));
    resolve();
  } catch (e) {
    yield handleError(e);
  } finally {
    yield effects.put(projectsActions.stopFetching());
  }
}
