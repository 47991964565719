import { createAction } from '@reduxjs/toolkit';

import guidanceSlice from './slice';

export const guidanceActions = {
  ...guidanceSlice.actions,
  fetchGuidance: createAction('guidance/fetchGuidance'),
  loadMoreGuidance: createAction(
    'guidance/loadMoreGuidance',
    (nextUrl: string) => ({
      payload: { nextUrl },
    }),
  ),
  // INJECT
};
