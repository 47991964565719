import React from 'react';

import { Box } from '@mui/material';

import { ModalTypes } from '@core/Modal/types';

import { styles } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { BillingStatus } from '@bus/subscription/typedefs';
import { profileActions } from '@bus/profile/actions';
import { handleErrors } from '@helpers/handleErrors';
import { PayPalButtons } from '@paypal/react-paypal-js';
import { CreateOrderActions, CreateOrderData } from '@paypal/paypal-js';
import { getProfile } from '@bus/profile/selectors';
import { subscriptionActions } from '@bus/subscription/actions';
import {
  OnApproveActions,
  OnApproveData,
} from '@paypal/paypal-js/types/components/buttons';
import { settingsActions } from '@bus/settings/actions';
import { ActionLogsEvent, ActionLogsType } from '@bus/settings/typedefs';

type OrderTokensProps = {
  text: string;
  cancelButton: {
    text: string;
  };
  confirmButton: {
    text: string;
  };
  price: number;
};

export const OrderTokens: React.FC<
  ModalTypes.ModalComponentProps<OrderTokensProps>
> = ({ price }) => {
  const profile = useSelector(getProfile);
  const dispatch = useDispatch();
  const onCreateOrder = (
    data: CreateOrderData,
    actions: CreateOrderActions,
  ) => {
    return actions.order
      .create({
        purchase_units: [
          {
            amount: {
              value: String(price.toFixed(2)),
            },
            custom_id: profile?.uuid,
          },
        ],
      })
      .then((data) => {
        dispatch(
          settingsActions.actionLogs({
            type: ActionLogsType.info,
            event: ActionLogsEvent.order,
            text: data,
            action: 'Create Order',
          }),
        );

        return data;
      })
      .catch((error) => {
        dispatch(
          settingsActions.actionLogs({
            type: ActionLogsType.error,
            event: ActionLogsEvent.order,
            text: JSON.stringify(error),
            action: 'Create Order',
          }),
        );

        return error;
      });
  };

  const onApprove = (data: OnApproveData, actions: OnApproveActions) => {
    return actions
      .order!.capture()
      .then((data) => {
        dispatch(
          settingsActions.actionLogs({
            type: ActionLogsType.info,
            event: ActionLogsEvent.order,
            text: JSON.stringify(data),
            action: 'Approve',
          }),
        );
        dispatch(
          subscriptionActions.setBillingStatus(BillingStatus.processing),
        );

        new Promise((resolve, reject) => {
          dispatch(
            profileActions.updateProfile({
              values: { paypal_payment_status: BillingStatus.processing },
              resolve,
              reject,
            }),
          );
        })
          .then()
          .catch();

        return data;
      })
      .catch((error) => {
        dispatch(
          settingsActions.actionLogs({
            type: ActionLogsType.error,
            event: ActionLogsEvent.order,
            text: JSON.stringify(error),
            action: 'Approve',
          }),
        );

        return error;
      });
  };

  const onError = (err: Record<string, unknown>) => {
    dispatch(
      settingsActions.actionLogs({
        type: ActionLogsType.error,
        event: ActionLogsEvent.order,
        text: JSON.stringify(err),
        action: 'Error',
      }),
    );
    handleErrors(err, 'log', 'ORDER_ERROR');
  };

  return (
    <Box sx={styles.orderTokens}>
      <Box width={'100%'} sx={styles.buttonWrapper}>
        <PayPalButtons
          style={{
            shape: 'pill',
          }}
          createOrder={onCreateOrder}
          onApprove={onApprove as any}
          onError={onError}
        />
      </Box>
    </Box>
  );
};

export default OrderTokens;
