import React, { FC } from 'react';

import { Box, Typography } from '@mui/material';

import { styles } from './styles';
import { useNavigate } from 'react-router-dom';
import { useTimer } from '../../../hooks/useTimer';
import { useLastSessionLink } from '../../../hooks/useLastSessionLink';
import { useDispatch, useSelector } from 'react-redux';
import {
  getIsLive,
  getIsNextSession,
  getIsStartSession,
} from '@bus/aiConversation/selectors';
import LiveAnimation from '@components/common/uiKit/LiveAnimation';
import { profileActions } from '@bus/profile/actions';
import AIAIDark from '@assets/AIAIDark.png';
import AIAILight from '@assets/AIAILight.png';
import IconContainer from '@components/common/IconContainer';

type TimeLeftToConversationProps = {
  targetDate: string;
};

export const TimeLeftToConversation: FC<TimeLeftToConversationProps> = ({
  targetDate,
}) => {
  const navigate = useNavigate();
  const isLive = useSelector(getIsLive);
  const isStartSession = useSelector(getIsStartSession);
  const isNextSession = useSelector(getIsNextSession);
  const { remainingTime } = useTimer(targetDate);
  const { link } = useLastSessionLink();
  const dispatch = useDispatch();

  const handleOnClick = () => {
    const project = {
      id: 1,
      name: 'AI-2-AI',
      chats: [],
      description: '',
      icon: '',
      guidances: [],
      icon_dark: AIAIDark,
      icon_light: AIAILight,
      years: [],
    };
    dispatch(profileActions.setCurrentDataset(project));
    dispatch(profileActions.setDatasetsClosed());
    navigate(link);
  };

  return (
    <Box sx={styles.timeLeftToConversation} onClick={handleOnClick}>
      <Box sx={styles.wrapper}>
        <IconContainer darkIcon={AIAIDark} lightIcon={AIAILight} size={32} />
        <Box
          display={'flex'}
          alignItems={'center'}
          width={'100%'}
          justifyContent={'flex-end'}>
          {isNextSession && isStartSession && targetDate && (
            <Box sx={styles.beta}>
              <Typography
                sx={styles.text}
                mr={'6px'}
                variant={'overline'}
                color={'common.warning.contrast text'}>
                The next session
              </Typography>
              <Typography
                variant={'overline'}
                fontWeight={700}
                letterSpacing={'0.4px'}
                whiteSpace={'nowrap'}
                color={'common.warning.contrast text'}>
                {`${remainingTime.days}d : ${remainingTime.hours}h : ${remainingTime.minutes}m : ${remainingTime.seconds}s`}
              </Typography>
            </Box>
          )}
          {isLive && !isStartSession && (
            <Box sx={styles.live}>
              <LiveAnimation />
              <Typography
                ml={'6px'}
                variant={'overline'}
                letterSpacing={'0.4px'}
                color={'common.warning.contrast text'}>
                Live right now
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      <Box display={'flex'} flexDirection={'column'}>
        <Typography variant={'h6'} color={'text.primary'}>
          AI-2-AI
        </Typography>
        <Typography variant={'caption'} color={'text.primary'} mt={'8px'}>
          Watch metaphysical AI expound and visualize the mysteries.
        </Typography>
      </Box>
    </Box>
  );
};

export default TimeLeftToConversation;
