import * as effects from 'redux-saga/effects';
import { api } from '@REST/api';
import { handleError } from '@bus/ui/saga/workers/handleError';
import { throwError } from '@bus/ui/saga/workers/throwError';

import { createdImagesActions } from '../../actions';
import {
  CreateSwapActionPayload,
  FillSwapActionPayload,
} from '@bus/createdImages/typedefs';
import { PayloadAction } from '@reduxjs/toolkit';

export function* createSwap({
  payload,
}: PayloadAction<CreateSwapActionPayload>) {
  try {
    yield effects.put(createdImagesActions.startFetching());
    const body: string = yield effects.call(JSON.stringify, payload);
    const response: Response = yield effects.apply(api, api.post, [
      { endpoint: 'image-studio/face-swaps/', body },
    ]);
    if (!response.ok) {
      yield throwError(response);
    }

    const data: FillSwapActionPayload = yield effects.call([response, 'json']);
    yield effects.put(createdImagesActions.fillSwap(data));
  } catch (e) {
    yield handleError(e);
  } finally {
    yield effects.put(createdImagesActions.stopFetching());
  }
}
