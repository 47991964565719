import { Theme } from '@mui/material/styles';

export const styles = {
  primary: (theme: Theme) => ({
    width: '100%',
    padding: '4px 8px',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    background: theme.palette.common.action['normal 2'],
  }),
  outlined: (theme: Theme) => ({
    width: '100%',
    padding: '4px 8px',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    border: `1px solid ${theme.palette.divider}`,
  }),
  ratioSelected: (theme: Theme) => ({
    background: theme.palette.common.surface.contrast,
  }),
};
