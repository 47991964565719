import * as effects from 'redux-saga/effects';
import { api } from '@REST/api';
import { handleError } from '@bus/ui/saga/workers/handleError';
import { throwError } from '@bus/ui/saga/workers/throwError';

import { projectsActions } from '../../actions';
import { FetchMessageAudioActionPayload } from '../../typedefs';
import { PayloadActionWithPromiseMeta } from '@core/FinalForm/typedefs';

export function* fetchMessageAudio({
  payload,
  meta: { resolve, reject },
}: PayloadActionWithPromiseMeta<FetchMessageAudioActionPayload>) {
  try {
    yield effects.put(projectsActions.startAudioFetching());
    const response: Response = yield effects.apply(api, api.get, [
      { endpoint: `messages/${payload.messageId}/play` },
    ]);
    if (!response.ok) {
      reject();
      yield throwError(response);
    }
    const data: Blob = yield effects.call([response, 'blob']);
    const mp3Url = URL.createObjectURL(data);
    yield effects.put(
      projectsActions.fillMessageAudio({
        audio: mp3Url,
        messageId: payload.messageId,
      }),
    );

    resolve();
  } catch (e) {
    yield handleError(e);
  } finally {
    yield effects.put(projectsActions.stopAudioFetching());
  }
}
