import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const Circle: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <circle
        cx="130"
        cy="130"
        r="125"
        fill="none"
        strokeWidth="10"
        strokeDasharray="5 5"
      />
    </SvgIcon>
  );
};

export default Circle;
