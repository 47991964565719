// @ts-nocheck
import { handleError } from '@bus/ui/saga/workers/handleError';
import * as effects from 'redux-saga/effects';
import { subscriptionActions } from '@bus/subscription/actions';
import { BillingStatus } from '@bus/subscription/typedefs';
import { fetchProfile } from '@bus/profile/saga/workers';
import { modalActions } from '@core/Modal/state/actions';

export function* handleMessageBilling({ payload }: any) {
  try {
    yield effects.put(
      subscriptionActions.setBillingStatus(
        payload.data.status ? BillingStatus.success : BillingStatus.error,
      ),
    );
    yield effects.put(modalActions.clearModals());
    yield effects.call(fetchProfile);
  } catch (e) {
    yield handleError(e);
  }
}
