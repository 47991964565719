import * as effects from 'redux-saga/effects';
import { api } from '@REST/api';
import { handleError } from '@bus/ui/saga/workers/handleError';
import { throwError } from '@bus/ui/saga/workers/throwError';

import { createdImagesActions } from '../../actions';
import { FillFaceSwapImagesActionPayload } from '@bus/createdImages/typedefs';

export function* fetchFaceSwapImages() {
  try {
    yield effects.put(createdImagesActions.startFetching());
    const response: Response = yield effects.apply(api, api.get, [
      { endpoint: 'image-studio/face-swaps/' },
    ]);
    if (!response.ok) {
      yield throwError(response);
    }

    const data: FillFaceSwapImagesActionPayload = yield effects.call([
      response,
      'json',
    ]);
    yield effects.put(createdImagesActions.fillFaceSwapImages(data));
  } catch (e) {
    yield handleError(e);
  } finally {
    yield effects.put(createdImagesActions.stopFetching());
  }
}
