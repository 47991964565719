//state type____________________________________
export type SettingsState = {
  isFetching: boolean;
  settings: null | ISettings;
  allowCountdown: boolean;
};

//payload types_________________________________
export type FillSettingsActionPayload = ISettings;
export type ActionLogsActionPayload = IActionLogs;
// INJECT

//common types__________________________________
export interface ISettings {
  audio_recording_limit: number;
  time_left_to_visualize: number;
}
export interface IActionLogs {
  type: ActionLogsType;
  event: ActionLogsEvent;
  text: string;
  action: string;
}

export enum ActionLogsEvent {
  subscription = 'subscription',
  order = 'order',
}

export enum ActionLogsType {
  error = 'error',
  info = 'info',
}
