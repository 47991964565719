export const truncateText = (
  text: string,
  maxLength: number,
  isUrl: boolean,
  social: string,
  shortImage: string,
) => {
  if (
    text.length + `${shortImage}\n\n`.length + `\n\n${social}`.length <=
    maxLength
  ) {
    const socialText = social.length ? `\n\n${social}` : '';

    return text + socialText;
  }

  const test =
    maxLength +
    (isUrl
      ? `${shortImage}\n\n`.length - `\n\n${social}`.length
      : -`\n\n${social}`.length);

  const truncated = text.slice(0, test);
  const lastSpaceIndex = truncated.lastIndexOf(' ');

  if (lastSpaceIndex === -1 || lastSpaceIndex < test - 3) {
    return truncated + (social.length ? `\n\n${social}` : '');
  }

  return `${truncated.slice(0, lastSpaceIndex - 3)}...${
    social.length ? `\n\n${social}` : ''
  }`;
};
