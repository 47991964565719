import * as effects from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { api } from '@REST/api';
import { handleError } from '@bus/ui/saga/workers/handleError';
import { throwError } from '@bus/ui/saga/workers/throwError';

import { createdImagesActions } from '../../actions';
import {
  FetchStudioImageSummaryActionPayload,
  FillStudioImageSummaryActionPayload,
} from '../../typedefs';

export function* fetchStudioImageSummary({
  payload,
}: PayloadAction<FetchStudioImageSummaryActionPayload>) {
  try {
    yield effects.put(createdImagesActions.startFetchingSummary());
    const response: Response = yield effects.apply(api, api.get, [
      { endpoint: `image-studio/images/${payload.imageId}/summary/` },
    ]);
    if (!response.ok) {
      yield throwError(response);
    }

    const data: FillStudioImageSummaryActionPayload = yield effects.call([
      response,
      'json',
    ]);
    yield effects.put(
      createdImagesActions.fillSummary({ summary: data.result, chatId: 0 }),
    );
  } catch (e) {
    yield handleError(e);
  } finally {
    yield effects.put(createdImagesActions.stopFetchingSummary());
  }
}
