import React, { useEffect, useState } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import NotFoundIcon from '@components/icons/NotFoundIcon';
import NotFoundMediumIcon from '@components/icons/NotFoundMediumIcon';
import NotFoundSmallIcon from '@components/icons/NotFoundSmallIcon';

export const useSize = () => {
  const [icon, setIcon] = useState<React.JSX.Element>();
  const theme = useTheme();
  const isUpLg = useMediaQuery(theme.breakpoints.up('lg'));
  const isLg = useMediaQuery(theme.breakpoints.down('lg'));
  const isMd = useMediaQuery(theme.breakpoints.down('md'));
  useEffect(() => {
    if (isUpLg) {
      setIcon(
        <NotFoundIcon
          sx={{
            width: '815px',
            height: '280px',
            mb: '26px',
          }}
          width={'815px'}
          height={'280px'}
          viewBox={`0 0 815 280`}
          htmlColor={theme.palette.action.disabledBackground}
        />,
      );
    }

    if (isLg) {
      setIcon(
        <NotFoundMediumIcon
          sx={{
            width: '728px',
            height: '249px',
            mb: '26px',
          }}
          width={'728px'}
          height={'249px'}
          viewBox={`0 0 728 249`}
          htmlColor={theme.palette.action.disabledBackground}
        />,
      );
    }

    if (isMd) {
      setIcon(
        <NotFoundSmallIcon
          sx={{
            width: '335px',
            height: '114px',
            mb: '26px',
          }}
          width={'335px'}
          height={'114px'}
          viewBox={`0 0 335 114`}
          htmlColor={theme.palette.action.disabledBackground}
        />,
      );
    }
  }, [isUpLg, isLg, isMd]);

  return { icon };
};
