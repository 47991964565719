// @ts-nocheck
import { handleError } from '@bus/ui/saga/workers/handleError';
import * as effects from 'redux-saga/effects';
import { aiConversationActions } from '@bus/aiConversation/actions';
import { IResponseAiMessage } from '@bus/ws/typedefs';

export function* aiError(payload: IResponseAiMessage) {
  const { data } = payload;
  try {
    const messageError = {
      id: 'error',
      created_at: '',
      type: data.type,
      text: 'Oops, something went wrong! Wait until the session resumes automatically',
      images: [],
      audios: [],
      dataset: {
        id: 0,
        project: {
          description: '',
          id: 0,
          name: '',
        },
      },
    };

    yield effects.put(
      aiConversationActions.addMessageError({
        message: messageError,
        id: data.session_id,
      }),
    );
  } catch (e) {
    yield handleError(e);
  }
}
