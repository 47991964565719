import React from 'react';

import { Box, Typography } from '@mui/material';

import { ModalTypes } from '@core/Modal/types';

import { styles } from './styles';
import { schema } from '@components/forms/ChatEdit';
import FinalForm from '@core/FinalForm';
import StoryChatEdit from '@components/forms/StoryChatEdit';
import { OnFormSubmitPayload } from '@core/FinalForm/typedefs';
import { modalActions } from '@core/Modal/state/actions';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { storyTellerActions } from '@bus/storyTeller/actions';

type EditStoryChatProps = {
  text: string;
  storyId: number;
  projectName: string;
  cancelButton: {
    text: string;
  };
  confirmButton: {
    text: string;
  };
};

export const EditStoryChat: React.FC<
  ModalTypes.ModalComponentProps<EditStoryChatProps>
> = ({ storyId, projectName }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = (payload: OnFormSubmitPayload) => {
    dispatch(
      storyTellerActions.updateStoryChat({
        ...payload,
        values: {
          chat_id: storyId,
          ...payload.values,
        },
      }),
    );
  };

  const handleSuccess = () => {
    dispatch(modalActions.closeModal('EditStoryChat'));
    enqueueSnackbar('Changes saved', {
      variant: 'infoSnackbar',
    });
  };

  return (
    <Box sx={styles.editStoryChat}>
      <Box sx={styles.titleWrapper}>
        <Typography variant={'body1'}>
          You can edit the name of the story or delete it
        </Typography>
      </Box>
      <FinalForm
        initialValues={{ name: projectName }}
        component={StoryChatEdit}
        extraProps={{ storyId }}
        onSubmit={handleSubmit}
        onSubmitSuccess={handleSuccess}
        schema={schema}
      />
    </Box>
  );
};

export default EditStoryChat;
