import { createAction } from '@reduxjs/toolkit';

import createdImagesSlice from './slice';
import { getEndpointParams } from '@helpers/getEndpointParams';
import { CreateSwapActionPayload } from '@bus/createdImages/typedefs';
import prepareActions from '@helpers/prepareActions';

export const createdImagesActions = {
  ...createdImagesSlice.actions,
  fetchCreatedImages: createAction(
    'createdImages/fetchCreatedImages',
    ({ ...value }) => {
      return {
        payload: {
          query: getEndpointParams(
            {
              ...value,
            },
            new URLSearchParams(),
          ),
        },
      };
    },
  ),
  loadMoreImages: createAction('createdImages/loadMoreImages', ({ url }) => ({
    payload: { url },
  })),
  fetchSummary: createAction('createdImages/fetchSummary', ({ chatId }) => ({
    payload: { chatId },
  })),
  fetchStudioImages: createAction('createdImages/fetchStudioImages'),
  loadMoreStudioImages: createAction(
    'createdImages/loadMoreStudioImages',
    ({ url }) => ({
      payload: { url },
    }),
  ),
  fetchImageStudioFaces: createAction('createdImages/fetchImageStudioFaces'),
  createSwap: createAction(
    'createdImages/createSwap',
    ({ target_image_url, swap_image_url }: CreateSwapActionPayload) => ({
      payload: { target_image_url, swap_image_url },
    }),
  ),
  updateSwap: createAction('createdImages/updateSwap', (payload: any) => {
    // modify payload place
    return payload;
  }),
  fetchFaceSwapImages: createAction('createdImages/fetchFaceSwapImages'),
  loadMoreSwapImages: createAction(
    'createdImages/loadMoreSwapImages',
    ({ url }) => ({
      payload: { url },
    }),
  ),
  fetchStudioImageSummary: createAction(
    'createdImages/fetchStudioImageSummary',
    ({ imageId }) => ({
      payload: { imageId },
    }),
  ),
  regenerateGeneratedImage: createAction(
    'createdImages/regenerateGeneratedImage',
    prepareActions.movePromiseToMeta,
  ),
  deleteImageStudioFaces: createAction(
    'createdImages/deleteImageStudioFaces',
    prepareActions.movePromiseToMeta,
  ),
  deleteStudioImages: createAction(
    'createdImages/deleteStudioImages',
    prepareActions.movePromiseToMeta,
  ),
  deleteFaceSwapImages: createAction(
    'createdImages/deleteFaceSwapImages',
    prepareActions.movePromiseToMeta,
  ),
  deleteCreatedImages: createAction(
    'createdImages/deleteCreatedImages',
    prepareActions.movePromiseToMeta,
  ),
  // INJECT
};
