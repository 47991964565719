export const styles = {
  editChat: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '450px',
    width: '100%',
    '& form': {
      width: '100%',
    },
  },
  wrapper: {
    padding: '0 20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 67.91px 0 20px',
  },
};
