import * as effects from 'redux-saga/effects';

import { savedMessagesActions } from '../actions';
// eslint-disable-next-line prettier/prettier
import {
  saveMessage,
  fetchSavedProjects,
  deleteSavedMessage,
  fetchMessagesSaved,
  loadMoreSavedMessages,
  fetchMessagePagination,
} from './workers';

// IMPORTS
function* watchSaveMessage() {
  yield effects.takeEvery(savedMessagesActions.saveMessage.type, saveMessage);
}
function* watchFetchSavedProjects() {
  yield effects.takeEvery(
    savedMessagesActions.fetchSavedProjects.type,
    fetchSavedProjects,
  );
}
function* watchDeleteSavedMessage() {
  yield effects.takeEvery(
    savedMessagesActions.deleteSavedMessage.type,
    deleteSavedMessage,
  );
}
function* watchFetchMessagesSaved() {
  yield effects.takeEvery(
    savedMessagesActions.fetchMessagesSaved.type,
    fetchMessagesSaved,
  );
}
function* watchLoadMoreSavedMessages() {
  yield effects.takeEvery(
    savedMessagesActions.loadMoreSavedMessages.type,
    loadMoreSavedMessages,
  );
}
function* watchFetchMessagePagination() {
  yield effects.takeEvery(
    savedMessagesActions.fetchMessagePagination.type,
    fetchMessagePagination,
  );
}
// WATCHERS
export function* watchSavedMessages() {
  // eslint-disable-next-line prettier/prettier
  yield effects.all([
    effects.call(watchSaveMessage),
    effects.call(watchFetchSavedProjects),
    effects.call(watchDeleteSavedMessage),
    effects.call(watchFetchMessagesSaved),
    effects.call(watchLoadMoreSavedMessages),
    effects.call(watchFetchMessagePagination),
    // INJECT
  ]);
}
