import React from 'react';
import { FieldRenderProps } from 'react-final-form';

import { Box, TextField, Typography } from '@mui/material';

import { getFieldError } from '@core/FinalForm/getFieldError';
import { styles } from '../styles';
import { getIsPromptFetching } from '@bus/projects/selectors';
import { useSelector } from 'react-redux';

export const RenderPromptField: React.FC<FieldRenderProps<string>> = ({
  input,
  meta,
}) => {
  const isFetching = useSelector(getIsPromptFetching);

  return (
    <Box mt={'10px'} display={'flex'} flexDirection={'column'}>
      <Typography variant={'caption'} color={'text.secondary'} mb={'10px'}>
        Prompt
      </Typography>
      <TextField
        sx={styles.input}
        multiline
        inputProps={{
          'data-testid': `visual-adjustments-form-prompt`,
        }}
        required
        rows={6}
        disabled={isFetching}
        fullWidth
        placeholder={'Loading Prompt...'}
        error={!!getFieldError(meta)}
        helperText={getFieldError(meta)}
        {...input}
      />
    </Box>
  );
};
