// @ts-nocheck
import { handleError } from '@bus/ui/saga/workers/handleError';
import * as effects from 'redux-saga/effects';
import { wsActions } from '@bus/ws/actions';

export function* reopenSockets() {
  try {
    yield effects.put(wsActions.setSocketsStatus('CLOSED'));
    yield effects.put(wsActions.setClosedSockets(true));
    yield effects.put(wsActions.setOpenedSockets(false));
  } catch (e) {
    yield handleError(e);
  }
}
