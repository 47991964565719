// @ts-nocheck
import { handleError } from '@bus/ui/saga/workers/handleError';
import { put } from 'redux-saga/effects';
import * as effects from 'redux-saga/effects';
import { storyTellerActions } from '@bus/storyTeller/actions';
import { profileActions } from '@bus/profile/actions';
import { IResponseStoryMessage, WSAction, WSEvent } from '@bus/ws/typedefs';

export function* handleResponseStoryteller(payload: IResponseStoryMessage) {
  try {
    const skeletonArray = Array(6)
      .fill({
        id: Date.now(),
        prompt: '',
        created_at: '',
        isSkeleton: true,
        images: [],
      })
      .map((item, index) => ({
        ...item,
        id: Date.now() + index,
      }));
    const message =
      payload.data.message.prompts.length === 0
        ? {
            ...payload.data.message,
            prompts: skeletonArray,
          }
        : payload.data.message;

    yield put(storyTellerActions.updateStoryMessages(message));

    yield effects.put(
      storyTellerActions.setProgressChatId(payload.data.message.chat),
    );

    if (payload.event === WSEvent.process_file) {
      yield effects.put(profileActions.fetchProfile());
    }

    if (
      payload.action === WSAction.notification &&
      payload.event === WSEvent.generate_images &&
      payload.data.action === 'end'
    ) {
      yield effects.put(
        storyTellerActions.updateProgressChatId(payload.data.message.chat),
      );
    }

    if (payload.action === WSAction.error) {
      yield effects.put(
        storyTellerActions.updateProgressChatId(payload.data.message.chat),
      );
    }
  } catch (e) {
    yield handleError(e);
  }
}
