import React from 'react';
import { Navigate, Route, Routes, useRoutes } from 'react-router-dom';

import { publicRoutes } from '@pages/public/routes';
import AuthLayout from '@layouts/AuthLayout';
import { book } from '@routes/book';
import { useLocation } from 'react-router';
import { useLastSessionLink } from '../hooks/useLastSessionLink';
import { v4 as uuidv4 } from 'uuid';

const PublicRoutes = () => {
  const location = useLocation();
  const { link } = useLastSessionLink();
  if (location.pathname.includes(book.chat)) {
    sessionStorage.setItem('redirectTo', location.pathname);
  }

  const matchMaintenance = useRoutes([
    {
      path: book.maintenance,
      element: <Navigate to={book.signIn} replace />,
    },
  ]);

  if (matchMaintenance) {
    return <Navigate to={book.signIn} replace />;
  }

  return (
    <Routes>
      {publicRoutes.map(({ path, Component, Layout, layoutProps }) => (
        <Route element={<Layout {...layoutProps} />} key={uuidv4()}>
          <Route path={path} element={Component} />
        </Route>
      ))}
      {link && (
        <Route path={book.conversation} element={<Navigate to={link} />} />
      )}
      {location.pathname !== book.conversation && (
        <Route element={<AuthLayout />}>
          <Route path={'/*'} element={<Navigate replace to={book.signIn} />} />
        </Route>
      )}
    </Routes>
  );
};

export default PublicRoutes;
