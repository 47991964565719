import * as effects from 'redux-saga/effects';

import { wsActions } from '../actions';
// eslint-disable-next-line prettier/prettier
import {
  sendImageRequest,
  sendMessageRequest,
  openedSockets,
  reopenSockets,
  handleResponse,
} from './workers';
import {
  DEFAULT_PREFIX,
  WEBSOCKET_CLOSED,
  WEBSOCKET_OPEN,
} from '@giantmachines/redux-websocket';
// IMPORTS

function* watchSendMessageRequest() {
  yield effects.takeEvery(
    wsActions.sendMessageRequest.type,
    sendMessageRequest,
  );
}
function* watchSendImageRequest() {
  yield effects.takeEvery(wsActions.sendImageRequest.type, sendImageRequest);
}
function* watchHandleResponse() {
  yield effects.takeEvery(wsActions.handleResponse.type, handleResponse);
}
function* watchReopenSockets() {
  yield effects.takeEvery(
    `${DEFAULT_PREFIX}::${WEBSOCKET_CLOSED}`,
    reopenSockets,
  );
}
function* watchOpenedSockets() {
  yield effects.takeEvery(
    `${DEFAULT_PREFIX}::${WEBSOCKET_OPEN}`,
    openedSockets,
  );
}
// WATCHERS
export function* watchWs() {
  // eslint-disable-next-line prettier/prettier
  yield effects.all([
    effects.call(watchSendMessageRequest),
    effects.call(watchSendImageRequest),
    effects.call(watchHandleResponse),
    effects.call(watchReopenSockets),
    effects.call(watchOpenedSockets),
    // INJECT
  ]);
}
