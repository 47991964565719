// @ts-nocheck
import * as effects from 'redux-saga/effects';
import { call } from 'redux-saga/effects';
import { handleError } from '@bus/ui/saga/workers/handleError';
import { handleMessageText } from '@bus/ws/saga/workers/handleMessageText';
import { handleMessageImage } from '@bus/ws/saga/workers/handleMessageImage';
import { fetchSettings } from '@bus/settings/saga/workers';
import { subscriptionsDetail } from '@bus/subscription/saga/workers';
import { chatActions } from '@bus/chat/actions';
import { projectsActions } from '@bus/projects/actions';
import { IResponseChatMessage, WSAction, WSEvent } from '@bus/ws/typedefs';
import { handleMessageChatError } from '@bus/ws/saga/workers/handleMessageChatError';

export function* handleResponseChat(payload: IResponseChatMessage) {
  try {
    if (
      payload.action === WSAction.response &&
      (payload.event === WSEvent.message ||
        payload.event === WSEvent.message_continue)
    ) {
      yield call(handleMessageText, payload.data);
    }
    if (
      payload.action === WSAction.error &&
      (payload.event === WSEvent.message ||
        payload.event === WSEvent.message_continue)
    ) {
      yield call(handleMessageChatError, payload.data);
    }
    if (
      payload.action !== WSAction.notification &&
      payload.event === WSEvent.visualize
    ) {
      yield call(handleMessageImage, payload.data);
    }
    if (
      payload.action === WSAction.notification &&
      payload.event === WSEvent.visualize &&
      payload.data.action === 'start'
    ) {
      yield effects.put(chatActions.stopIsImageLoading());
      yield effects.put(
        projectsActions.addImages({
          id: payload.data.message_id,
          chatId: payload.data.chat_id,
          images: payload.data.images,
        }),
      );
      yield call(fetchSettings);
      yield effects.call(subscriptionsDetail);
    }
  } catch (e) {
    yield handleError(e);
  }
}
