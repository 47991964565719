import * as effects from 'redux-saga/effects';

import { subscriptionActions } from '../actions';
// eslint-disable-next-line prettier/prettier
import {
  fetchSubscriptionPlans,
  subscriptionsDetail,
  cancelSubscription,
  intervalDetail,
  createSubscription,
  closeSubscription,
} from './workers';

// IMPORTS
function* watchFetchSubscriptionPlans() {
  yield effects.takeEvery(
    subscriptionActions.fetchSubscriptionPlans.type,
    fetchSubscriptionPlans,
  );
}
function* watchSubscriptionsDetail() {
  yield effects.takeEvery(
    subscriptionActions.subscriptionsDetail.type,
    subscriptionsDetail,
  );
}
function* watchCancelSubscription() {
  yield effects.takeEvery(
    subscriptionActions.cancelSubscription.type,
    cancelSubscription,
  );
}
function* watchIntervalDetail() {
  yield effects.takeEvery(
    subscriptionActions.intervalDetail.type,
    intervalDetail,
  );
}
function* watchCreateSubscription() {
  yield effects.takeEvery(
    subscriptionActions.createSubscription.type,
    createSubscription,
  );
}
function* watchCloseSubscription() {
  yield effects.takeEvery(
    subscriptionActions.closeSubscription.type,
    closeSubscription,
  );
}
// WATCHERS
export function* watchSubscription() {
  // eslint-disable-next-line prettier/prettier
  yield effects.all([
    effects.call(watchFetchSubscriptionPlans),
    effects.call(watchSubscriptionsDetail),
    effects.call(watchCancelSubscription),
    effects.call(watchIntervalDetail),
    effects.call(watchCreateSubscription),
    effects.call(watchCloseSubscription),
    // INJECT
  ]);
}
