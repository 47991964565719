import React, { FC, useEffect, useState } from 'react';

import { Box, LinearProgress, Typography, useTheme } from '@mui/material';

import { styles } from './styles';
import Logo from '@components/common/Logo';
import { IconButton } from '@components/common/uiKit/IconButton';
import AccountCircle from '@components/icons/AccountCircle';
import { useDispatch, useSelector } from 'react-redux';
import { getIsGlobalSpeaking } from '@bus/ui/selectors';
import { uiActions } from '@bus/ui/actions';
import { useRouterChange } from '../../../hooks/useRouterChange';
import { chatActions } from '@bus/chat/actions';
import { getIsDatasetsOpen, getProfile } from '@bus/profile/selectors';
import useVoiceRecognition from '../../../hooks/useVoiceRecognition';
import { getIsAudioPlaying, getIsChatTyping } from '@bus/chat/selectors';
import {
  getIsProjectsAudioFetching,
  getProjects,
} from '@bus/projects/selectors';
import { Tooltip } from '@components/common/uiKit/Tooltip';
import AppBarActions from '@components/common/AppBarActions';
import MasterMenu from 'src/components/common/MasterMenu';
import AppBarTooltip from '@components/common/AppBarTooltip';
import { shortTokensAmount } from '@helpers/shortTokensAmount';
import { useNavigate } from 'react-router-dom';
import { book } from '@routes/book';
import { profileActions } from '@bus/profile/actions';

type AppBarProps = {
  authorized?: boolean;
};

export const AppBar: FC<AppBarProps> = ({ authorized = true }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const globalSpeaking = useSelector(getIsGlobalSpeaking);
  const profile = useSelector(getProfile);
  const dispatch = useDispatch();
  const { progress } = useRouterChange();
  const [isError, setIsError] = useState(false);
  const { startListening, stopListening } = useVoiceRecognition(setIsError);
  const isTyping = useSelector(getIsChatTyping);
  const isAudioPlaying = useSelector(getIsAudioPlaying);
  const isProjectsAudioFetching = useSelector(getIsProjectsAudioFetching);
  const ref = document.getElementById('myButton');
  const projects = useSelector(getProjects);
  const isDatasetOpen = useSelector(getIsDatasetsOpen);
  const [datasets, setDatasets] = useState(projects);

  useEffect(() => {
    setDatasets(projects);
  }, [projects]);

  useEffect(() => {
    if (
      profile?.subscription &&
      profile.subscription.limits === 0 &&
      globalSpeaking
    ) {
      dispatch(uiActions.toggleGlobalSpeaking());
      dispatch(chatActions.stopVoiceDetected());

      return;
    }
    if (
      globalSpeaking &&
      !isTyping &&
      !isAudioPlaying &&
      !isProjectsAudioFetching
    ) {
      dispatch(chatActions.startVoiceDetected());
    }
  }, [profile]);

  useEffect(() => {
    if (globalSpeaking) {
      startListening();
    } else {
      stopListening();
    }
  }, []);

  useEffect(() => {
    const event = new MouseEvent('click', { bubbles: true });
    if (isError && ref) {
      ref.dispatchEvent(event);
      setTimeout(() => {
        ref.dispatchEvent(event);
      }, 100);
    }
  }, [isError, ref]);

  const handleClickToken = () => {
    navigate(book.profile);
    dispatch(profileActions.setScrollTo('token'));
  };

  return (
    <>
      <Box sx={styles.appBar}>
        {progress && (
          <Box sx={styles.loader}>
            <LinearProgress />
          </Box>
        )}
        <Box>
          <Logo />
        </Box>
        {authorized && <AppBarActions />}
        <Box sx={styles.wrapper}>
          {authorized && (
            <Box sx={styles.menuWrapper}>
              {!!profile?.tokens && profile?.tokens > 0 && (
                <Box sx={styles.tokens} onClick={handleClickToken}>
                  <Typography variant={'subtitle2'} color={'text.primary'}>
                    {shortTokensAmount(profile?.tokens, true, false)}
                  </Typography>
                  <Typography variant={'subtitle2'} color={'text.primary'}>
                    CREATE
                  </Typography>
                </Box>
              )}
              <Tooltip
                title={<AppBarTooltip />}
                placement={'bottom-end'}
                id={`profileAction`}
                open={true}
                arrow={false}
                isOpacityBackground
                zIndex={10000}>
                <Box
                  sx={(theme) =>
                    styles.buttonWrapper(theme, profile?.tokens === 0)
                  }>
                  <IconButton sx={styles.button}>
                    {profile?.photo ? (
                      <Box
                        component={'img'}
                        src={profile.photo}
                        alt={'Profile image'}
                      />
                    ) : (
                      <AccountCircle
                        fontSize={'small'}
                        htmlColor={theme.palette.text.secondary}
                      />
                    )}
                  </IconButton>
                </Box>
              </Tooltip>
            </Box>
          )}
        </Box>
      </Box>
      <Box sx={[styles.wrapperProjects, isDatasetOpen && styles.open]}>
        <Box>
          <Box>
            <Box sx={styles.margin} />
            <MasterMenu list={datasets} />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AppBar;
