// @ts-nocheck
import { handleError } from '@bus/ui/saga/workers/handleError';
import * as effects from 'redux-saga/effects';
import { aiConversationActions } from '@bus/aiConversation/actions';
import { IResponseAiMessage, WSEvent } from '@bus/ws/typedefs';

export function* aiAction(payload: IResponseAiMessage) {
  const { data } = payload;
  try {
    const settings = window.localStorage?.getItem('isSessionProcessing');
    let session = null;
    if (settings) {
      session = JSON.parse(settings);
    }

    if (payload.event !== WSEvent.session_completed) {
      window.localStorage.setItem(
        'isSessionProcessing',
        JSON.stringify(data.session_id),
      );

      const message = {
        id: payload.message_type === 'image' ? payload.message_id : 'mock',
        created_at: '',
        type: data.message_type,
        text: null,
        images: data.images ? data.images : [],
        audios: [],
        dataset: {
          id: data.dataset_id,
          project: {
            description: data.project_description,
            id: data.project_id,
            name: data.project_name,
          },
        },
      };

      yield effects.put(
        aiConversationActions.addMessageMock({
          message,
          id: data.session_id,
          isCompleted: false,
        }),
      );
    }

    if (payload.event === WSEvent.session_completed && session) {
      window.localStorage.setItem('isSessionCompleted', JSON.stringify(true));
    }

    if (payload.event === WSEvent.session_completed && !session) {
      yield effects.put(aiConversationActions.nextSession());

      yield effects.put(
        aiConversationActions.setLastMessageSessionId(data.session_id),
      );

      yield effects.put(
        aiConversationActions.finishSession({
          id: data.session_id,
          isCompleted: true,
        }),
      );
    }
  } catch (e) {
    yield handleError(e);
  }
}
