import * as effects from 'redux-saga/effects';
import { api } from '@REST/api';
import { handleError } from '@bus/ui/saga/workers/handleError';
import { throwError } from '@bus/ui/saga/workers/throwError';

import { aiConversationActions } from '../../actions';
import { FillSessionsActionPayload } from '@bus/aiConversation/typedefs';

export function* fetchSessions() {
  try {
    yield effects.put(aiConversationActions.startFetching());
    const response: Response = yield effects.apply(api, api.get, [
      { endpoint: 'aiconversation/sessions', unsafe: true },
    ]);
    if (!response.ok) {
      yield throwError(response);
    }
    const data: FillSessionsActionPayload = yield effects.call([
      response,
      'json',
    ]);
    if (data.every((session) => session.is_completed)) {
      window.localStorage.removeItem('isSessionCompleted');
    }
    yield effects.put(aiConversationActions.fillSessions(data));
  } catch (e) {
    yield handleError(e);
  } finally {
    yield effects.put(aiConversationActions.stopFetching());
  }
}
