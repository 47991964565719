import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getIsLive, getSessions } from '@bus/aiConversation/selectors';
import { book } from '@routes/book';

export const useLastSessionLink = () => {
  const isLive = useSelector(getIsLive);
  const sessions = useSelector(getSessions);
  const [link, setLink] = useState('');

  useEffect(() => {
    if (isLive && sessions.length) {
      const lastYear = sessions.find((year) =>
        year.months.some((month) =>
          month.sessions.some((session) => !session.is_completed),
        ),
      );
      const lastMonth = lastYear?.months.find((month) =>
        month.sessions.some((session) => !session.is_completed),
      );
      const lastSession = lastMonth?.sessions.find(
        (session) => !session.is_completed,
      );

      setLink(
        `${book.conversation}/${lastYear?.id}/${lastMonth?.id}/${lastSession?.id}`,
      );
    }
    if (!isLive && sessions.length) {
      const lastYear = sessions[0];
      const lastMonth = lastYear.months[0];
      const lastSession = lastMonth.sessions[0];

      setLink(
        `${book.conversation}/${lastYear.id}/${lastMonth.id}/${lastSession.id}`,
      );
    }
  }, [sessions.length, isLive]);

  return { link };
};
