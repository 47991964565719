import * as effects from 'redux-saga/effects';

import { optionsActions } from '../actions';
// eslint-disable-next-line prettier/prettier
import {
  parameters,
  fetchStorytellersStyles,
} from './workers';

// IMPORTS
function* watchMidjourneyParameters() {
  yield effects.takeEvery(optionsActions.midjourneyParameters.type, parameters);
}
function* watchFetchStorytellersStyles() {
  yield effects.takeEvery(
    optionsActions.fetchStorytellersStyles.type,
    fetchStorytellersStyles,
  );
}
// WATCHERS
export function* watchOptions() {
  // eslint-disable-next-line prettier/prettier
  yield effects.all([
    effects.call(watchMidjourneyParameters),
    effects.call(watchFetchStorytellersStyles),
    // INJECT
  ]);
}
