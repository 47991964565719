import { Theme } from '@mui/material/styles';

export const styles = {
  timeLeftToConversation: (theme: Theme) => ({
    overflow: 'hidden',
    borderRadius: '16px',
    border: `1px solid ${theme.palette.divider}`,
    padding: '16px',
    minHeight: '140px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    cursor: 'pointer',
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
      background: theme.palette.action.hover,
    },
    '&:active': {
      background: theme.palette.primary.light,
      '& svg': {
        '& path': {
          fill: theme.palette.primary.main,
        },
      },
    },
    [theme.breakpoints.down('xl')]: {
      gap: '20px',
    },
    [theme.breakpoints.down('xl')]: {
      width: '100%',
    },
    [theme.breakpoints.down('md')]: {
      padding: '15px',
      gap: '15px',
    },
  }),
  beta: (theme: Theme) => ({
    display: 'flex',
    padding: '6px 8px',
    borderRadius: '6px',
    background: theme.palette.common.warning.light,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      width: '100%',
    },
  }),
  text: (theme: Theme) => ({
    [theme.breakpoints.down(1920)]: {
      display: 'none',
    },
    [theme.breakpoints.down('lg')]: {
      display: 'block',
    },
  }),
  live: (theme: Theme) => ({
    display: 'flex',
    padding: '6px 8px',
    borderRadius: '6px',
    background: theme.palette.common.warning.light,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  }),
  wrapper: (theme: Theme) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    gap: '8px',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  }),
};
