import * as effects from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { api } from '@REST/api';
import { handleError } from '@bus/ui/saga/workers/handleError';
import { throwError } from '@bus/ui/saga/workers/throwError';

import { projectsActions } from '../../actions';
import {
  FillGuidanceActionPayload,
  ReadGuidanceActionPayload,
} from '../../typedefs';
import { profileActions } from '@bus/profile/actions';

export function* readGuidance({
  payload,
}: PayloadAction<ReadGuidanceActionPayload>) {
  const newData = {
    guidance_id: payload.guidance_id,
    subguidance_id: payload.subguidance_id,
  };
  try {
    const body: string = yield effects.call(JSON.stringify, newData);
    const response: Response = yield effects.apply(api, api.post, [
      { endpoint: 'guidances/view', body },
    ]);
    if (!response.ok) {
      yield throwError(response);
    }
    const data: FillGuidanceActionPayload = yield effects.call([
      response,
      'json',
    ]);
    const newGuide = {
      guide: data.guidance,
      projectId: payload.projectId,
    };
    yield effects.put(projectsActions.updateGuidance(newGuide));
    yield effects.put(profileActions.updateGuidance(newGuide));
  } catch (e) {
    yield handleError(e);
  } finally {
    // yield effects.put(projectsActions.stopFetching());
  }
}
