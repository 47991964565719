import * as React from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Field, FormRenderProps } from 'react-final-form';

import { Box, Button, Typography } from '@mui/material';

import { RenderNameField } from '@components/forms/StoryChatEdit/fields/RenderNameField';
// RENDER_FIELDS
import { schema } from './schema';
import { styles } from './styles';
import { modalActions } from '@core/Modal/state/actions';
import { book } from '@routes/book';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { storyTellerActions } from '@bus/storyTeller/actions';

const StoryChatEdit = ({
  storyId,
  ...props
}: FormRenderProps & { storyId: number }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleSubmit = () => {
    const values = { chat_id: storyId };

    return new Promise((resolve, reject) => {
      dispatch(storyTellerActions.deleteStoryChat({ values, resolve, reject }));
    })
      .then(() => {
        dispatch(modalActions.closeModal('EditStoryChat'));
        navigate(book.storyTellerHome);
      })
      .catch((errors) => {
        return errors;
      });
  };
  const handleClick = () => {
    dispatch(
      modalActions.modal({
        component: 'ConfirmOrCancel',
        forceClose: false,
        onCancel: () => handleSubmit(),
        onConfirm: () => {
          dispatch(modalActions.closeModal('ConfirmOrCancel'));
        },
        modalPayload: {
          title: 'Confirm Action',
          body: 'Are you sure you want to delete this chat? You will not be able to recover it and its saved messages',
        },
        cancelButton: {
          text: 'Delete',
        },
        confirmButton: {
          text: 'Cancel',
        },
      }),
    );
  };

  return (
    <form noValidate onSubmit={props.handleSubmit}>
      <Box
        maxWidth={'450px'}
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}>
        <Box p={'20px'} width={'100%'}>
          <Field name={`name`} component={RenderNameField} />
        </Box>
        {/*FIELDS*/}
        <Box sx={styles.buttonWrapper}>
          <Button variant={'primary'} fullWidth type={'submit'} role={'submit'}>
            <Typography variant={'button'} color={'primary.contrastText'}>
              Save changes
            </Typography>
          </Button>
          <Button variant={'secondary'} fullWidth onClick={handleClick}>
            <Typography variant={'button'} color={'text.primary'}>
              Delete chat
            </Typography>
          </Button>
        </Box>
      </Box>
    </form>
  );
};

export default StoryChatEdit;

export { schema, StoryChatEdit };

/*
Paste to the component where you want to use the form

import { EvneFinalForm } from '@packages/evne-form';
import { StoryChatEdit, schema } from '@components/forms/StoryChatEdit';
import { storyTellerActions } from '@bus/storyTeller/actions';

<EvneFinalForm
  component={ StoryChatEdit }
  sagaAction={ storyTellerActions. }
  schema={schema}
/>
* */
