import { createAction } from '@reduxjs/toolkit';

import storyTellerSlice from './slice';
import prepareActions from '@helpers/prepareActions';

export const storyTellerActions = {
  ...storyTellerSlice.actions,
  fetchStory: createAction(
    'storyTeller/fetchStory',
    prepareActions.movePromiseToMeta,
  ),
  fetchStoryMessages: createAction(
    'storyTeller/fetchStoryMessages',
    ({ chatId, isDelay, elementsCount }) => ({
      payload: { chatId, isDelay, elementsCount },
    }),
  ),
  loadMoreStoryMessages: createAction(
    'storyTeller/loadMoreStoryMessages',
    ({ url, chatId }) => ({
      payload: { url, chatId },
    }),
  ),
  createStoryChat: createAction(
    'storyTeller/createStoryChat',
    prepareActions.withPromiseNavigate,
  ),
  deleteStoryChat: createAction(
    'storyTeller/deleteStoryChat',
    prepareActions.movePromiseToMeta,
  ),
  updateStoryChat: createAction(
    'storyTeller/updateStoryChat',
    prepareActions.movePromiseToMeta,
  ),
  regenerateImage: createAction(
    'storyTeller/regenerateImage',
    ({ chatId, promptId }) => ({
      payload: { chatId, promptId },
    }),
  ),
  fetchRatioList: createAction('storyTeller/fetchRatioList'),
  // INJECT
};
