import React, { FC } from 'react';

import { Box, Typography, useTheme } from '@mui/material';

import { styles } from './styles';
import LogoIcon from '@components/common/Logo/LogoIcon';
import { Link } from 'react-router-dom';
import { book } from '@routes/book';
import Visibility1 from '@components/icons/Visibility1';
import { getAmountOfUsers } from '@bus/profile/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { getIsLive } from '@bus/aiConversation/selectors';
import { profileActions } from '@bus/profile/actions';

type LogoProps = {};

export const Logo: FC<LogoProps> = () => {
  const theme = useTheme();
  const amountOfUsers = useSelector(getAmountOfUsers);
  const location = useLocation();
  const isLive = useSelector(getIsLive);
  const dispatch = useDispatch();

  const handleDatasets = () => {
    dispatch(profileActions.setDatasetsClosed());
  };

  return (
    <Link
      to={book.home}
      onClick={handleDatasets}
      style={{ textDecoration: 'none' }}>
      <Box sx={styles.logo}>
        <LogoIcon
          sx={{
            width: '41px',
            height: '40px',
          }}
          viewBox={'0 0 41 40'}
          htmlColor={theme.palette.text.primary}
        />
        <Box
          mx={'10px'}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}>
          {location.pathname.includes(book.conversation) && isLive && (
            <Box display={'flex'} alignItems={'center'} mt={'3px'}>
              <Visibility1
                sx={{ fontSize: '16px' }}
                htmlColor={theme.palette.text.secondary}
              />
              <Typography
                variant={'caption'}
                ml={'4px'}
                color={'text.secondary'}>
                {amountOfUsers.toLocaleString('en-US')}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Link>
  );
};

export default Logo;
