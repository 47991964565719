export const styles = {
  serverError: {
    display: 'flex',
    flexDirection: 'column',
    margin: '32px 32px 24px',
    alignItems: 'center',
    maxWidth: '416px',
    gap: '29px',
  },
};
