import { Theme } from '@mui/material/styles';

export const styles = {
  featureItem: (theme: Theme) => ({
    borderRadius: '16px',
    border: `1px solid ${theme.palette.divider}`,
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    cursor: 'pointer',
    transition: 'all 0.3s ease-in-out',
    height: '100%',
    '&:hover': {
      background: theme.palette.action.hover,
    },
    [theme.breakpoints.down('xl')]: {
      gap: '20px',
    },
    [theme.breakpoints.down('md')]: {
      padding: '15px',
      gap: '15px',
    },
  }),
  beta: (theme: Theme) => ({
    display: 'flex',
    padding: '6px 8px',
    borderRadius: '6px',
    background: theme.palette.common.warning.light,
  }),
};
