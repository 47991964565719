// eslint-disable-next-line @typescript-eslint/no-unused-vars,prettier/prettier
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// eslint-disable-next-line prettier/prettier
import {
  OptionsState,
  FillMidjourneyParametersActionPayload,
} from './typedefs';

const initialState: OptionsState = {};

const optionsSlice = createSlice({
  name: 'options',
  initialState,
  reducers: {
    fillMidjourneyParameters(
      state,
      action: PayloadAction<FillMidjourneyParametersActionPayload>,
    ) {
      action.payload.forEach((item) => {
        const existingItems = state[item.parameter_type.code] || [];
        state[item.parameter_type.code] = [...existingItems, item];
      });
    },
    clearOptions(state) {
      Object.keys(state).forEach((key) => {
        delete state[key];
      });
    },
    // INJECT
  },
});

export default optionsSlice;
