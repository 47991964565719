export const publicBook = {
  signIn: '/sign-in',
  signUp: '/sign-up',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password',
  thankYou: '/thank-you',
  emailVerification: '/email-verification',
  successForgotEmail: '/success-forgot-email',
  successPasswordReset: '/success-password-reset', // INJECT
};
