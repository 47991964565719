import * as effects from 'redux-saga/effects';
import { api } from '@REST/api';
import { handleError } from '@bus/ui/saga/workers/handleError';
import { throwError } from '@bus/ui/saga/workers/throwError';

import { storyTellerActions } from '../../actions';
import { DeleteStoryChatActionPayload } from '../../typedefs';
import { PayloadActionWithPromiseMeta } from '@core/FinalForm/typedefs';

export function* deleteStoryChat({
  payload,
  meta: { resolve, reject },
}: PayloadActionWithPromiseMeta<DeleteStoryChatActionPayload>) {
  try {
    yield effects.put(storyTellerActions.startFetching());
    const body: string = yield effects.call(JSON.stringify, payload);
    const response: Response = yield effects.apply(api, api.delete, [
      { endpoint: `storytellers/chats/${payload.chat_id}/delete/`, body },
    ]);
    if (!response.ok) {
      reject();
      yield throwError(response);
    }

    yield effects.put(storyTellerActions.removeStoryChat(payload.chat_id));
    resolve();
  } catch (e) {
    yield handleError(e);
  } finally {
    yield effects.put(storyTellerActions.stopFetching());
  }
}
