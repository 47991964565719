import React, { FC, useEffect, useState } from 'react';

import { Box } from '@mui/material';

import { styles } from './styles';
import { useSelector } from 'react-redux';
import { getTheme } from '@bus/ui/selectors';
import { ThemeVariant } from '@bus/ui/typedefs';

type IconContainerProps = {
  darkIcon: string;
  lightIcon: string;
  size: number;
};

export const IconContainer: FC<IconContainerProps> = ({
  darkIcon,
  lightIcon,
  size,
}) => {
  const themes = useSelector(getTheme);
  const [icon, setIcon] = useState('');

  useEffect(() => {
    setIcon(themes === ThemeVariant.dark ? darkIcon : lightIcon);
  }, [themes, darkIcon, lightIcon]);

  return (
    <Box
      sx={{
        ...styles.iconContainer,
        width: `${size}px`,
        height: `${size}px`,
      }}>
      {icon && (
        <Box
          component={'img'}
          alt={'icon'}
          src={icon}
          sx={{ width: `${size}px`, height: `${size}px` }}
        />
      )}
    </Box>
  );
};

export default IconContainer;
