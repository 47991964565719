// @ts-nocheck
import { call } from 'redux-saga/effects';
import { handleError } from '@bus/ui/saga/workers/handleError';
import { handleNotificationAlive } from '@bus/ws/saga/workers/handleNotificationAlive';
import { aiAction } from '@bus/ws/saga/workers/aiAction';
import { aiMessage } from '@bus/ws/saga/workers/aiMessage';
import { aiError } from '@bus/ws/saga/workers/aiError';
import { IResponseAiMessage, WSAction, WSEvent } from '@bus/ws/typedefs';

export function* handleResponseAi(payload: IResponseAiMessage) {
  try {
    if (
      payload.action === WSAction.notification &&
      payload.event !== WSEvent.alive
    ) {
      yield call(aiAction, payload);
    }
    if (payload.action === WSAction.response) {
      yield call(aiMessage, payload);
    }
    if (payload.action === WSAction.error) {
      yield call(aiError, payload);
    }
    if (
      payload.action === WSAction.notification &&
      payload.event === WSEvent.alive
    ) {
      yield call(handleNotificationAlive, payload);
    }
  } catch (e) {
    yield handleError(e);
  }
}
