import * as effects from 'redux-saga/effects';
import { api } from '@REST/api';
import { handleError } from '@bus/ui/saga/workers/handleError';
import { throwError } from '@bus/ui/saga/workers/throwError';

import { projectsActions } from '../../actions';
import {
  CreateNewMessageChatActionPayload,
  FillMessageChatActionPayload,
  IProjects,
} from '../../typedefs';
import { PayloadActionWithNavigateMeta } from '@core/FinalForm/typedefs';
import { book } from '@routes/book';
import { getProject } from '@bus/projects/selectors';
import { profileActions } from '@bus/profile/actions';

export function* createNewMessageChat({
  payload,
  meta: { navigate },
}: PayloadActionWithNavigateMeta<CreateNewMessageChatActionPayload>) {
  try {
    yield effects.put(projectsActions.startFetching());
    const response: Response = yield effects.apply(api, api.post, [
      { endpoint: `messages/${payload.messageId}/create_chat` },
    ]);
    if (!response.ok) {
      yield throwError(response);
    }

    const data: FillMessageChatActionPayload = yield effects.call([
      response,
      'json',
    ]);
    yield effects.put(projectsActions.fillMessageChat(data));
    yield effects.put(
      projectsActions.fetchAutoName({ chatId: data.new_chat.id }),
    );
    const project: IProjects = yield effects.select(
      getProject(data.new_message.project.id),
    );
    yield effects.put(profileActions.setCurrentDataset(project));
    navigate(
      `${book.chat}/${data.new_message.project.name}/${data.new_chat.id}`,
    );
  } catch (e) {
    yield handleError(e);
  } finally {
    yield effects.put(projectsActions.stopFetching());
  }
}
