import React from 'react';

import { Box, Button, Typography } from '@mui/material';

import { ModalTypes } from '@core/Modal/types';

import { styles } from './styles';
import { profileActions } from '@bus/profile/actions';
import { useDispatch, useSelector } from 'react-redux';
import { shortTokensAmount } from '@helpers/shortTokensAmount';
import { getProfile } from '@bus/profile/selectors';

type TrialInformationWindowProps = {
  text: string;
  buttonLabel: string;
  tokens?: number;
};

export const TrialInformationWindow: React.FC<
  ModalTypes.ModalComponentProps<TrialInformationWindowProps>
> = ({ closeFn, text, buttonLabel, tokens }) => {
  const dispatch = useDispatch();
  const profile = useSelector(getProfile);
  const closeModal = () => {
    closeFn();
    dispatch(profileActions.finishOnboarding({ on_boarding: false }));
  };

  return (
    <Box sx={styles.trialInformationWindow}>
      <Box sx={styles.container}>
        <Typography variant={'h5'} color={'text.primary'}>
          Congratulations! The trial period has begun.
        </Typography>
        <Typography variant={'h5'} color={'text.primary'} mt={'24px'}>
          {tokens} CREATE Tokens added
        </Typography>
        <Box sx={styles.wrapper}>
          <Typography variant={'subtitle2'} color={'text.primary'} mb={'10px'}>
            Possibilities:
          </Typography>
          <Box mb={'20px'} display={'flex'} justifyContent={'space-between'}>
            <Typography
              variant={'subtitle2'}
              color={'text.disabled'}
              sx={{ wordSpacing: '16px' }}>
              or Questions
            </Typography>
            <Typography variant={'subtitle2'} color={'text.primary'}>
              {tokens && profile?.token_question_price
                ? shortTokensAmount(
                    tokens / profile.token_question_price,
                    true,
                    false,
                  )
                : 0}
            </Typography>
          </Box>
          <Box mb={'20px'} display={'flex'} justifyContent={'space-between'}>
            <Typography
              variant={'subtitle2'}
              color={'text.disabled'}
              sx={{ wordSpacing: '16px' }}>
              or Visualizations
            </Typography>
            <Typography variant={'subtitle2'} color={'text.primary'}>
              {tokens && profile?.token_visualization_price
                ? shortTokensAmount(
                    tokens / profile.token_visualization_price,
                    true,
                    false,
                  )
                : 0}
            </Typography>
          </Box>
          <Box display={'flex'} justifyContent={'space-between'}>
            <Typography
              variant={'subtitle2'}
              color={'text.disabled'}
              sx={{ wordSpacing: '16px' }}>
              or Stories
            </Typography>
            <Typography variant={'subtitle2'} color={'text.primary'}>
              {tokens && profile?.token_story_price
                ? shortTokensAmount(
                    tokens / profile.token_story_price,
                    true,
                    false,
                  )
                : 0}
            </Typography>
          </Box>
        </Box>
        <Typography variant={'body1'} color={'text.primary'} mt={'16px'}>
          {`We're giving you ${tokens} tokens to use within 7 days for an unforgettable experience with Sibyl.`}
        </Typography>
        <Button
          variant={'primary'}
          fullWidth
          sx={{ marginTop: '40px' }}
          onClick={closeModal}>
          {buttonLabel}
        </Button>
      </Box>
    </Box>
  );
};

export default TrialInformationWindow;
