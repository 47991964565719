import * as effects from 'redux-saga/effects';

import { settingsActions } from '../actions';
// eslint-disable-next-line prettier/prettier
import {
  fetchSettings,
  actionLogs,
} from './workers';

// IMPORTS
function* watchFetchSettings() {
  yield effects.takeEvery(settingsActions.fetchSettings.type, fetchSettings);
}
function* watchActionLogs() {
  yield effects.takeEvery(settingsActions.actionLogs.type, actionLogs);
}
// WATCHERS
export function* watchSettings() {
  // eslint-disable-next-line prettier/prettier
  yield effects.all([
    effects.call(watchFetchSettings),
    effects.call(watchActionLogs),
    // INJECT
  ]);
}
