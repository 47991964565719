import React from 'react';

import { Box, Typography } from '@mui/material';

import { ModalTypes } from '@core/Modal/types';

import { styles } from './styles';
import FinalForm from '@core/FinalForm';
import { schema, ChatEdit } from '@components/forms/ChatEdit';
import { useDispatch } from 'react-redux';
import { OnFormSubmitPayload } from '@core/FinalForm/typedefs';
import { modalActions } from '@core/Modal/state/actions';
import { projectsActions } from '@bus/projects/actions';
import { useSnackbar } from 'notistack';

type EditChatProps = {
  text: string;
  chatId: number;
  projectName: string;
  cancelButton: {
    text: string;
  };
  confirmButton: {
    text: string;
  };
};

export const EditChat: React.FC<
  ModalTypes.ModalComponentProps<EditChatProps>
> = ({ chatId, projectName }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = (payload: OnFormSubmitPayload) => {
    dispatch(
      projectsActions.updateChat({
        ...payload,
        values: {
          id: chatId,
          ...payload.values,
        },
      }),
    );
  };

  const handleSuccess = () => {
    dispatch(modalActions.closeModal('EditChat'));
    enqueueSnackbar('Changes saved', {
      variant: 'infoSnackbar',
    });
  };

  return (
    <Box sx={styles.editChat}>
      <Box sx={styles.titleWrapper}>
        <Typography variant={'body1'}>
          You can edit the name of the chat or delete it
        </Typography>
      </Box>
      <FinalForm
        initialValues={{ name: projectName }}
        component={ChatEdit}
        extraProps={{ chatId }}
        onSubmit={handleSubmit}
        onSubmitSuccess={handleSuccess}
        schema={schema}
      />
    </Box>
  );
};

export default EditChat;
