import * as React from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Field, FormRenderProps } from 'react-final-form';

import { Box, Button, Typography, useTheme } from '@mui/material';
import { RenderPromptField } from '@components/forms/VisualAdjustmentsForm/fields/RenderPromptField';
import { RenderRatioField } from '@components/forms/VisualAdjustmentsForm/fields/RenderRatioField';
import { RenderStyleField } from '@components/forms/VisualAdjustmentsForm/fields/RenderStyleField';
import { RenderMagicField } from '@components/forms/VisualAdjustmentsForm/fields/RenderMagicField';

// RENDER_FIELDS
import { schema } from './schema';
import Generate from '@components/icons/Generate';
import { styles } from './styles';
import { useSelector } from 'react-redux';
import { getIsPromptFetching } from '@bus/projects/selectors';

const VisualAdjustmentsForm = ({
  isCurrentPrompt,
  ...props
}: FormRenderProps & { isCurrentPrompt: boolean }) => {
  const theme = useTheme();
  const isFetching = useSelector(getIsPromptFetching);

  return (
    <form noValidate onSubmit={props.handleSubmit}>
      <Box
        maxWidth={'450px'}
        width={'100%'}
        display={'flex'}
        flexDirection={'column'}>
        <Field name={`ratio`} component={RenderRatioField} />
        <Field name={`style`} component={RenderStyleField} />
        <Field name={`prompt`} component={RenderPromptField} />
        {/*FIELDS*/}
        <Box display={'flex'} mt={'20px'}>
          <Button
            fullWidth
            type={'submit'}
            role={'submit'}
            variant={'primary'}
            sx={styles.button}
            disabled={isFetching || !isCurrentPrompt}
            endIcon={
              <Generate
                fontSize={'small'}
                htmlColor={theme.palette.primary.contrastText}
              />
            }>
            <Typography variant={'button'} color={'primary.contrastText'}>
              Generate
            </Typography>
          </Button>
        </Box>
        <Field name={`magic`} type={'checkbox'} component={RenderMagicField} />
      </Box>
    </form>
  );
};

export default VisualAdjustmentsForm;

export { schema, VisualAdjustmentsForm };

/*
Paste to the component where you want to use the form

import { EvneFinalForm } from '@packages/evne-form';
import { schema, VisualAdjustmentsForm } from '@components/forms/VisualAdjustmentsForm';

<EvneFinalForm
  component={VisualAdjustmentsForm}
  schema={schema}
/>
* */
