import { apply, call, put } from 'redux-saga/effects';

import { api } from '@REST/api';
import { handleError } from '@bus/ui/saga/workers/handleError';
import { throwError } from '@bus/ui/saga/workers/throwError';
import {
  FormErrors,
  ServerFormErrors,
  getServerFormErrors,
} from '@core/FinalForm/getServerErrors';
import { PayloadActionWithPromiseMeta } from '@core/FinalForm/typedefs';

import { FillSignUpActionPayload, SignUpActionPayload } from '../../typedefs';
import * as effects from 'redux-saga/effects';
import { authActions } from '@bus/auth/actions';

export function* signUp({
  payload,
  meta: { resolve, reject },
}: PayloadActionWithPromiseMeta<SignUpActionPayload>) {
  try {
    const body: string = yield call(JSON.stringify, payload);

    const response: Response = yield apply(api, api.post, [
      { endpoint: `auth/signup`, body, unsafe: true },
    ]);

    if (response.status === 400) {
      const errors: ServerFormErrors = yield call([response, 'json']);
      const formErrors: FormErrors = yield call(getServerFormErrors, errors);
      reject(formErrors);
      yield throwError(response);
    }

    if (!response.ok) {
      alert('sign up - fail');
      reject();
      yield throwError(response);
    }

    const data: FillSignUpActionPayload = yield effects.call([
      response,
      'json',
    ]);

    window.sessionStorage.setItem('email', JSON.stringify(data.user.email));
    yield put(authActions.setResentCodeTimer(data.verification_code.expire_at));
    resolve();
  } catch (e) {
    yield handleError(e);
  }
}
