import React, { useRef, useState } from 'react';

import { Box, Button, Typography } from '@mui/material';

import { ModalTypes } from '@core/Modal/types';

import { styles } from './styles';
import Cropper, { ReactCropperElement } from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { getValidToken, removeQuotes } from '@REST/api';
import { profileActions } from '@bus/profile/actions';
import { handleErrors } from '@helpers/handleErrors';
import { useDispatch } from 'react-redux';
import { modalActions } from '@core/Modal/state/actions';

type CropperWindowProps = {
  image: string;
  onClose: () => void;
};

export const CropperWindow: React.FC<
  ModalTypes.ModalComponentProps<CropperWindowProps>
> = ({ closeFn, onClose, image }) => {
  const cropperRef = useRef<ReactCropperElement>(null);
  const [crop, setCrop] = useState<Blob | null>(null);
  const dispatch = useDispatch();
  const onCrop = () => {
    const imageElement: any = cropperRef?.current;
    const cropper = imageElement?.cropper;

    if (cropper) {
      cropper.getCroppedCanvas().toBlob((blob: Blob) => {
        setCrop(blob);
      }, 'image/jpeg');
    }
  };
  const closeModal = () => {
    onClose();
    closeFn();
  };

  const save = async () => {
    if (crop) {
      const tokenAccess = await getValidToken();
      const formData = new FormData();
      formData.append('photo', crop, 'filename.jpg');
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/users/photo`,
          {
            method: 'PUT',
            body: formData,
            headers: {
              Authorization: 'Bearer ' + removeQuotes(tokenAccess!),
            },
          },
        );

        if (response.ok) {
          const data = await response.json();
          dispatch(modalActions.closeModal('CropperWindow'));
          dispatch(
            profileActions.changePhoto({
              photo: `${data.photo}?${new Date().getTime()}`,
            }),
          );
        }
      } catch (error) {
        handleErrors(error);
      }
    }
  };

  return (
    <Box sx={styles.cropper}>
      <Cropper
        style={{ height: 300, width: '100%' }}
        src={image}
        initialAspectRatio={1}
        aspectRatio={1}
        crop={onCrop}
        ref={cropperRef}
        zoomable={false}
        background={false}
        viewMode={1}
      />
      <Box
        mt={'10px'}
        width={'100%'}
        display={'flex'}
        flexDirection={'column'}
        gap={'10px'}>
        <Button fullWidth onClick={save} variant={'primary'}>
          <Typography variant={'button'}>Save</Typography>
        </Button>
        <Button fullWidth onClick={closeModal} variant={'secondary'}>
          <Typography variant={'button'} color={'text.primary'}>
            Close
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default CropperWindow;
