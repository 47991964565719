import * as effects from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { api } from '@REST/api';
import { handleError } from '@bus/ui/saga/workers/handleError';
import { throwError } from '@bus/ui/saga/workers/throwError';

import { storyTellerActions } from '../../actions';
import { RegenerateImageActionPayload } from '../../typedefs';

export function* regenerateImage({
  payload,
}: PayloadAction<RegenerateImageActionPayload>) {
  try {
    yield effects.put(storyTellerActions.startFetching());
    const response: Response = yield effects.apply(api, api.post, [
      {
        endpoint: `storytellers/chats/${payload.chatId}/prompts/${payload.promptId}/generate_image/`,
      },
    ]);
    if (!response.ok) {
      yield throwError(response);
    }
  } catch (e) {
    yield handleError(e);
  } finally {
    yield effects.put(storyTellerActions.stopFetching());
  }
}
