export const styles = {
  visualAdjustments: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '10px 20px 20px',
    maxWidth: '480px',
    width: '100%',
    '& form': {
      width: '100%',
    },
  },
};
