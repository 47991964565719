import * as effects from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { api } from '@REST/api';
import { handleError } from '@bus/ui/saga/workers/handleError';
import { throwError } from '@bus/ui/saga/workers/throwError';

import { chatActions } from '../../actions';
import {
  FetchMessagesActionPayload,
  FillMessagesActionPayload,
} from '../../typedefs';
import { projectsActions } from '@bus/projects/actions';

export function* fetchMessages({
  payload,
}: PayloadAction<FetchMessagesActionPayload>) {
  try {
    yield effects.put(chatActions.startFetching());
    const response: Response = yield effects.apply(api, api.get, [
      { endpoint: `chats/${payload.chatId}/messages` },
    ]);
    if (!response.ok) {
      yield throwError(response);
    }
    const data: FillMessagesActionPayload = yield effects.call([
      response,
      'json',
    ]);
    yield effects.put(
      projectsActions.fillMessages({ data, chatId: payload.chatId }),
    );
  } catch (e) {
    yield handleError(e);
  } finally {
    yield effects.put(chatActions.stopFetching());
  }
}
