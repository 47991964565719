import { apply, call, put } from 'redux-saga/effects';

import { api } from '@REST/api';
import { handleError } from '@bus/ui/saga/workers/handleError';
import { throwError } from '@bus/ui/saga/workers/throwError';
import { PayloadActionWithPromiseNavigateMeta } from '@core/FinalForm/typedefs';

import { SignInActionPayload } from '../../typedefs';
import { authActions } from '@bus/auth/actions';
import { sessionIdGeneration } from '@helpers/sessionIdGeneration';
import { book } from '@routes/book';

export function* signIn({
  payload,
  meta: { resolve, reject, navigate },
}: PayloadActionWithPromiseNavigateMeta<SignInActionPayload>) {
  try {
    const { password, email } = payload;
    const body: string = yield call(JSON.stringify, { password, email });

    const response: Response = yield apply(api, api.post, [
      { endpoint: `auth/token`, body, unsafe: true },
    ]);

    if (response.status === 400) {
      const { error } = yield call([response, 'json']);
      reject({
        email: error,
        password: error,
      });
      yield throwError(response);
    }

    if (response.status === 202) {
      yield put(authActions.resendCode({ email }));
      yield put(authActions.setEmail(email));
      navigate(book.emailVerification);
      reject();
      yield throwError(response);
    }

    const { access, refresh } = yield call([response, 'json']);
    const sessionId: string = yield call(sessionIdGeneration);

    yield put(authActions.isAuthenticatedTrue());

    window.localStorage.setItem('accessToken', JSON.stringify(access));
    window.localStorage.setItem('refreshToken', JSON.stringify(refresh));
    window.localStorage.setItem('sessionId', JSON.stringify(sessionId));

    resolve();
  } catch (e) {
    yield handleError(e);
  }
}
