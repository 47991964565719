import { createSelector } from 'reselect';

import { RootState } from '@setup/typedefs';

const wsSelector = (state: RootState) => state.ws;

export const getIsWsFetching = createSelector([wsSelector], (result) => {
  return result.isFetching;
});

export const getSocketOpened = createSelector([wsSelector], (result) => {
  return result.socketStatus.toLowerCase();
});

export const getIsClosedSockets = createSelector([wsSelector], (result) => {
  return result.isClosedSockets;
});

export const getIsOpenedSockets = createSelector([wsSelector], (result) => {
  return result.isOpenedSockets;
});

export const getIsApprovedOrder = createSelector([wsSelector], (result) => {
  return result.isApprovedOrder;
});
