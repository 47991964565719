import * as effects from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { api } from '@REST/api';
import { handleError } from '@bus/ui/saga/workers/handleError';
import { throwError } from '@bus/ui/saga/workers/throwError';

import { createdImagesActions } from '../../actions';
import {
  FetchSummaryActionPayload,
  FillSummaryActionPayload,
} from '../../typedefs';

export function* fetchSummary({
  payload,
}: PayloadAction<FetchSummaryActionPayload>) {
  try {
    yield effects.put(createdImagesActions.startFetchingSummary());
    const response: Response = yield effects.apply(api, api.get, [
      { endpoint: `chats/${payload.chatId}/summary` },
    ]);
    if (!response.ok) {
      yield throwError(response);
    }

    const data: FillSummaryActionPayload = yield effects.call([
      response,
      'json',
    ]);
    yield effects.put(
      createdImagesActions.fillSummary({
        ...data,
        chatId: payload.chatId,
      }),
    );
  } catch (e) {
    yield handleError(e);
  } finally {
    yield effects.put(createdImagesActions.stopFetchingSummary());
  }
}
