import { Theme } from '@mui/material/styles';

export const styles = {
  suspenseLoading: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  wrapper: {
    position: 'relative',
    display: 'flex',
  },
  icon: {
    position: 'absolute',
    fontSize: '60px',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
  },
  progress: (theme: Theme) => ({
    color: theme.palette.text.primary,
  }),
};
