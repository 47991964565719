import * as effects from 'redux-saga/effects';

import { chatActions } from '../../actions';
import { getIsChatTyping } from '@bus/chat/selectors';
import { handleError } from '@bus/ui/saga/workers/handleError';
import { subscriptionsDetail } from '@bus/subscription/saga/workers';

export function* debounceChat({ payload }: any) {
  try {
    const isTyping: boolean = yield effects.select(getIsChatTyping);
    if (!isTyping) {
      yield effects.put(chatActions.startTyping());
    }
    if (!payload) {
      yield effects.put(chatActions.stopTyping());
      yield effects.call(subscriptionsDetail);
    }
  } catch (e) {
    yield handleError(e);
  }
}
