import * as effects from 'redux-saga/effects';
import { api } from '@REST/api';
import { handleError } from '@bus/ui/saga/workers/handleError';
import { throwError } from '@bus/ui/saga/workers/throwError';

import { savedMessagesActions } from '../../actions';
import { DeleteSavedMessageActionPayload } from '../../typedefs';
import { projectsActions } from '@bus/projects/actions';
import { PayloadActionWithNavigateMeta } from '@core/FinalForm/typedefs';
import { book } from '@routes/book';
import { fetchSavedProjects } from '@bus/savedMessages/saga/workers/fetchSavedProjects';
import { fetchMessagesSaved } from '@bus/savedMessages/saga/workers/fetchMessagesSaved';

export function* deleteSavedMessage({
  payload,
  meta: { navigate },
}: PayloadActionWithNavigateMeta<DeleteSavedMessageActionPayload>) {
  try {
    yield effects.put(savedMessagesActions.startFetching());
    const response: Response = yield effects.apply(api, api.delete, [
      { endpoint: `messages/${payload.message_id}/unsave` },
    ]);
    if (!response.ok) {
      yield throwError(response);
    }

    yield effects.put(projectsActions.removeSavedMessage(payload.message_id));
    if (payload.isLastMessage && payload.isSavedPage) {
      yield effects.put(
        savedMessagesActions.removeSavedProject(payload.message_id),
      );
      navigate(book.home);
    }
    if (!payload.isLastMessage && payload.isSavedPage) {
      yield effects.put(
        savedMessagesActions.removeSavedMessages(payload.message_id),
      );
      yield effects.call(fetchMessagesSaved, {
        payload: {
          projectId: payload.project,
        },
      } as any);
    }
    if (!payload.isSavedPage || payload.isLastMessage) {
      yield effects.call(fetchSavedProjects);
      yield effects.call(fetchMessagesSaved, {
        payload: {
          projectId: payload.project,
        },
      } as any);
    }
  } catch (e) {
    yield handleError(e);
  } finally {
    yield effects.put(savedMessagesActions.stopFetching());
  }
}
