import * as effects from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { api } from '@REST/api';
import { handleError } from '@bus/ui/saga/workers/handleError';
import { throwError } from '@bus/ui/saga/workers/throwError';

import { subscriptionActions } from '../../actions';
import { CreateSubscriptionActionPayload } from '../../typedefs';

export function* createSubscription({
  payload,
}: PayloadAction<CreateSubscriptionActionPayload>) {
  try {
    yield effects.put(subscriptionActions.startFetching());
    const body: string = yield effects.call(JSON.stringify, payload);
    const response: Response = yield effects.apply(api, api.post, [
      { endpoint: 'subscriptions/create', body },
    ]);
    if (!response.ok) {
      yield throwError(response);
    }
  } catch (e) {
    yield handleError(e);
  } finally {
    yield effects.put(subscriptionActions.stopFetching());
  }
}
