import React from 'react';

import { SvgIcon, SvgIconProps, useTheme } from '@mui/material';

const CheckedIcon: React.FC<SvgIconProps> = () => {
  const theme = useTheme();

  return (
    <SvgIcon
      sx={{ fontSize: '16px' }}
      width={16}
      height={16}
      viewBox={'0 0 16 16'}>
      <rect
        width="16"
        height="16"
        rx="2"
        fill={theme.palette.common.surface.contrast}
      />
      <path
        d="M12.4687 4.00001H11.6495C11.5347 4.00001 11.4257 4.05275 11.3554 4.14298L6.52375 10.2637L4.20695 7.32814C4.1719 7.28364 4.12723 7.24766 4.07629 7.2229C4.02534 7.19814 3.96945 7.18524 3.91281 7.18517H3.09367C3.01515 7.18517 2.97179 7.2754 3.01984 7.33634L6.22961 11.4027C6.37961 11.5926 6.66789 11.5926 6.81906 11.4027L12.5425 4.15001C12.5905 4.09025 12.5472 4.00001 12.4687 4.00001Z"
        fill={theme.palette.primary.contrastText}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.91281 7.18517C3.96945 7.18524 4.02534 7.19814 4.07629 7.2229C4.12723 7.24766 4.1719 7.28364 4.20695 7.32814L6.52375 10.2637L11.3554 4.14298C11.4257 4.05275 11.5347 4.00001 11.6495 4.00001H12.4687C12.5472 4.00001 12.5905 4.09025 12.5425 4.15001L6.81906 11.4027C6.66789 11.5926 6.37961 11.5926 6.22961 11.4027L3.01984 7.33634C2.97179 7.2754 3.01515 7.18517 3.09367 7.18517H3.91281ZM12.7775 4.33653C12.7771 4.33701 12.7767 4.3375 12.7763 4.33798L7.05375 11.5896C6.78294 11.9297 6.26479 11.9312 5.99422 11.5887L2.78436 7.52221C2.58097 7.26425 2.76492 6.88517 3.09367 6.88517H3.91281C4.01477 6.88529 4.11573 6.90851 4.20743 6.95308C4.29904 6.99761 4.37939 7.06229 4.44244 7.14228L6.52377 9.77947L11.1187 3.95859C11.119 3.95832 11.1192 3.95805 11.1194 3.95778C11.2472 3.79422 11.4436 3.70001 11.6495 3.70001H12.4687C12.792 3.70001 12.9855 4.07546 12.7775 4.33653Z"
        fill={theme.palette.primary.contrastText}
      />
    </SvgIcon>
  );
};

export default CheckedIcon;
