import { createAction } from '@reduxjs/toolkit';

import settingsSlice from './slice';

export const settingsActions = {
  ...settingsSlice.actions,
  fetchSettings: createAction('settings/fetchSettings'),
  actionLogs: createAction(
    'settings/actionLogs',
    ({ type, event, text, action }) => ({
      payload: { type, event, text, action },
    }),
  ),
  // INJECT
};
