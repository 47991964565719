import * as effects from 'redux-saga/effects';
import { api } from '@REST/api';
import { handleError } from '@bus/ui/saga/workers/handleError';
import { throwError } from '@bus/ui/saga/workers/throwError';

import { profileActions } from '../../actions';
import { PayloadActionWithNavigateMeta } from '@core/FinalForm/typedefs';
import { IProfile } from '@bus/profile/typedefs';
import { book } from '@routes/book';
import { authActions } from '@bus/auth/actions';
import { optionsActions } from '@bus/options/actions';
import { projectsActions } from '@bus/projects/actions';
import { createdImagesActions } from '@bus/createdImages/actions';

export function* deleteAccount({
  meta: { navigate },
}: PayloadActionWithNavigateMeta<IProfile>) {
  try {
    yield effects.put(profileActions.startFetching());
    const response: Response = yield effects.apply(api, api.delete, [
      { endpoint: `users/` },
    ]);
    if (!response.ok) {
      yield throwError(response);
    }

    yield effects.put(profileActions.clearData());
    yield effects.put(createdImagesActions.clearData());
    yield effects.put(projectsActions.clearProjects());
    yield effects.put(authActions.isAuthenticatedFalse());
    yield effects.put(optionsActions.clearOptions());
    sessionStorage.removeItem('redirectTo');
    navigate(book.signIn);
    localStorage.setItem('snackbar_message', 'The Account has been deleted');
  } catch (e) {
    yield handleError(e);
  } finally {
    yield effects.put(profileActions.stopFetching());
  }
}
