import * as effects from 'redux-saga/effects';
import { api } from '@REST/api';
import { handleError } from '@bus/ui/saga/workers/handleError';
import { throwError } from '@bus/ui/saga/workers/throwError';
import { PayloadActionWithPromiseMeta } from '@core/FinalForm/typedefs';

import { authActions } from '../../actions';
import { VerificationEmailActionPayload } from '../../typedefs';
import {
  FormErrors,
  getServerFormErrors,
  ServerFormErrors,
} from '@core/FinalForm/getServerErrors';
import { call, put } from 'redux-saga/effects';
import { sessionIdGeneration } from '@helpers/sessionIdGeneration';

export function* verificationEmail({
  payload,
  meta: { resolve, reject },
}: PayloadActionWithPromiseMeta<VerificationEmailActionPayload>) {
  try {
    yield effects.put(authActions.startFetching());
    const body: string = yield effects.call(JSON.stringify, payload);
    const response: Response = yield effects.apply(api, api.post, [
      { endpoint: 'auth/check-confirmation-code', body, unsafe: true },
    ]);

    if (response.status === 400) {
      reject({
        confirmation_code: 'Incorrect code',
      });
      yield throwError(response);
    }

    if (!response.ok) {
      const data: ServerFormErrors = yield call([response, 'json']);
      const formErrors: FormErrors = yield call(getServerFormErrors, data);
      reject(formErrors);
      yield throwError(response);
    }

    const { access, refresh } = yield call([response, 'json']);
    const sessionId: string = yield call(sessionIdGeneration);

    yield put(authActions.isAuthenticatedTrue());
    window.localStorage.setItem('accessToken', JSON.stringify(access));
    window.localStorage.setItem('refreshToken', JSON.stringify(refresh));
    window.localStorage.setItem('sessionId', JSON.stringify(sessionId));
    resolve();
  } catch (e) {
    yield handleError(e);
  } finally {
    yield effects.put(authActions.stopFetching());
  }
}
