export const styles = {
  successChangeProfilePassword: {
    display: 'flex',
    flexDirection: 'column',
    margin: '32px 32px 24px',
    alignItems: 'center',
    maxWidth: '360px',
    gap: '29px',
  },
};
