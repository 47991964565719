import { apply, call, put } from 'redux-saga/effects';

import { api } from '@REST/api';
import { handleError } from '@bus/ui/saga/workers/handleError';
import { throwError } from '@bus/ui/saga/workers/throwError';
import {
  FormErrors,
  ServerFormErrors,
  getServerFormErrors,
} from '@core/FinalForm/getServerErrors';
import { PayloadActionWithPromiseMeta } from '@core/FinalForm/typedefs';

import { authActions } from '../../actions';
import { ForgotPasswordPayload } from '../../typedefs';

export function* forgotPassword({
  payload,
  meta: { resolve, reject },
}: PayloadActionWithPromiseMeta<ForgotPasswordPayload>) {
  try {
    const body: string = yield call(JSON.stringify, payload);

    const response: Response = yield apply(api, api.post, [
      { endpoint: `auth/reset-password`, body, unsafe: true },
    ]);

    if (response.status === 400) {
      const errors: ServerFormErrors = yield call([response, 'json']);
      const formErrors: FormErrors = yield call(getServerFormErrors, errors);
      reject(formErrors);
      yield throwError(response);
    }

    if (!response.ok) {
      reject();
      yield throwError(response);
    }

    yield put(authActions.allowCountdown(true));

    resolve();
  } catch (e) {
    yield handleError(e);
  }
}
