// @ts-nocheck
import { handleError } from '@bus/ui/saga/workers/handleError';
import * as effects from 'redux-saga/effects';
import { projectsActions } from '@bus/projects/actions';
import { chatActions } from '@bus/chat/actions';
import { subscriptionsDetail } from '@bus/subscription/saga/workers';
import { IResponseChatVisualizeData } from '@bus/ws/typedefs';

export function* handleMessageImage(payload: IResponseChatVisualizeData) {
  try {
    const message = {
      id: payload.message_id,
      chatId: payload.chat_id,
      images: payload.images,
      error: payload.error || 'Try again',
    };

    yield effects.call(subscriptionsDetail);

    yield effects.put(projectsActions.updateImages(message));
    yield effects.put(chatActions.stopTyping());
    yield effects.put(
      chatActions.setLoadingImageId({
        imageId: message.images[0].id || 0,
        chatId: message.chatId,
        projectId: payload.project_id,
      }),
    );
  } catch (e) {
    yield handleError(e);
  }
}
