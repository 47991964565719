import * as effects from 'redux-saga/effects';
import { api } from '@REST/api';
import { handleError } from '@bus/ui/saga/workers/handleError';
import { throwError } from '@bus/ui/saga/workers/throwError';

import { subscriptionActions } from '../../actions';

export function* closeSubscription() {
  try {
    yield effects.put(subscriptionActions.startFetching());
    const response: Response = yield effects.apply(api, api.post, [
      { endpoint: 'subscriptions/close' },
    ]);
    if (!response.ok) {
      yield throwError(response);
    }

    yield effects.put(subscriptionActions.fetchSubscriptionPlans());
  } catch (e) {
    yield handleError(e);
  } finally {
    yield effects.put(subscriptionActions.stopFetching());
  }
}
