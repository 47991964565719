import * as effects from 'redux-saga/effects';
import { api } from '@REST/api';
import { handleError } from '@bus/ui/saga/workers/handleError';
import { throwError } from '@bus/ui/saga/workers/throwError';

import { subscriptionActions } from '../../actions';
import { profileActions } from '@bus/profile/actions';
import { ISubscriptionsDetail } from '@bus/profile/typedefs';
import { PayloadActionWithPromiseMeta } from '@core/FinalForm/typedefs';

export function* cancelSubscription({
  meta: { resolve, reject },
}: PayloadActionWithPromiseMeta<any>) {
  try {
    yield effects.put(subscriptionActions.startSubscriptionCancelFetching());
    const response: Response = yield effects.apply(api, api.delete, [
      { endpoint: 'subscriptions/cancel' },
    ]);
    if (!response.ok) {
      reject();
      yield throwError(response);
    }

    const data: ISubscriptionsDetail = yield effects.call([response, 'json']);
    yield effects.put(profileActions.updateSubscription(data));
    resolve();
  } catch (e) {
    yield handleError(e);
  } finally {
    yield effects.put(subscriptionActions.stopSubscriptionCancelFetching());
  }
}
