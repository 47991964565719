import * as effects from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { api } from '@REST/api';
import { handleError } from '@bus/ui/saga/workers/handleError';
import { throwError } from '@bus/ui/saga/workers/throwError';

import { createdImagesActions } from '../../actions';
import {
  LoadMoreImagesActionPayload,
  PushMoreImagesActionPayload,
} from '../../typedefs';

export function* loadMoreImages({
  payload,
}: PayloadAction<LoadMoreImagesActionPayload>) {
  try {
    yield effects.put(createdImagesActions.startFetching());
    const response: Response = yield effects.apply(api, api.get, [
      { endpoint: payload.url, direct: true },
    ]);
    if (!response.ok) {
      yield throwError(response);
    }

    const data: PushMoreImagesActionPayload = yield effects.call([
      response,
      'json',
    ]);
    yield effects.put(createdImagesActions.pushMoreImages(data));
  } catch (e) {
    yield handleError(e);
  } finally {
    yield effects.put(createdImagesActions.stopFetching());
  }
}
