import * as effects from 'redux-saga/effects';
import { api } from '@REST/api';
import { handleError } from '@bus/ui/saga/workers/handleError';
import { throwError } from '@bus/ui/saga/workers/throwError';

import { projectsActions } from '../../actions';
import { FetchProjectsActionPayload } from '../../typedefs';
import { processSvg } from '@helpers/processSvg';
import { createdImagesActions } from '@bus/createdImages/actions';

export function* fetchProjects() {
  try {
    yield effects.put(projectsActions.startFetching());
    const response: Response = yield effects.apply(api, api.get, [
      { endpoint: 'projects/' },
    ]);
    if (!response.ok) {
      yield throwError(response);
    }
    const data: FetchProjectsActionPayload = yield effects.call([
      response,
      'json',
    ]);

    const fetchedProjects: FetchProjectsActionPayload = yield effects.all(
      data.map(function* (project) {
        const svgContent: string = yield effects.call(processSvg, project.icon);

        return { ...project, icon: svgContent };
      }),
    );

    yield effects.put(projectsActions.fillProjects(fetchedProjects));
    yield effects.put(
      createdImagesActions.fillFilters(
        fetchedProjects.map((project) => ({
          id: project.id,
          name: project.name,
          icon_dark: project.icon_dark,
          icon_light: project.icon_light,
        })),
      ),
    );
  } catch (e) {
    yield handleError(e);
  } finally {
    yield effects.put(projectsActions.stopFetching());
  }
}
