import { useEffect, useState } from 'react';

export const usePrivateTimer = (targetDate: string) => {
  const [isFinished, setIsFinished] = useState(false);

  useEffect(() => {
    const updateTimer = () => {
      const time = getTimeRemaining(targetDate);
      const finished =
        +time.days === 0 &&
        +time.hours === 0 &&
        +time.minutes === 0 &&
        +time.seconds === 0;

      setIsFinished(finished);
    };

    if (targetDate) {
      updateTimer();
      const timer = setInterval(updateTimer, 1000);

      return () => clearInterval(timer);
    }
  }, [targetDate]);

  return { isFinished };
};

const getTimeRemaining = (targetDate: string) => {
  const targetTime = new Date(targetDate).getTime();
  const currentTime = new Date().getTime();
  const timeDifference = targetTime - currentTime;

  if (timeDifference <= 0 || !timeDifference) {
    return {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  }

  const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
  );
  const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

  return {
    days: days > 9 ? days : '0' + days,
    hours: hours > 9 ? hours : '0' + hours,
    minutes: minutes > 9 ? minutes : '0' + minutes,
    seconds: seconds > 9 ? seconds : '0' + seconds,
  };
};
