//state type____________________________________
export type UiState = {
  isFetching: boolean;
  theme: ThemeVariant | null;
  isSidebarOpen: boolean;
  isMobileSidebarOpen: boolean;
  isSidebarDataset: boolean;
  isSidebarDescription: boolean;
  isSidebarConversationDataset: boolean;
  isSidebarConversationDescription: boolean;
  globalSpeaking: boolean;
  oneTimeSpeaking: boolean;
  globalListening: boolean;
  firstGlobalListeningIOS: boolean;
  firstGlobalSpeakingIOS: boolean;
  firstOneTimeSpeakingIOS: boolean;
};

// INJECT
//payload types_________________________________
export type FillThemeActionPayload = ThemeVariant;

//common types__________________________________
export enum ThemeVariant {
  dark = 'dark',
  light = 'light',
}
