export const processSvg = (svgString: string | null) => {
  if (svgString === null) {
    return null;
  }
  const parser = new DOMParser();
  const doc = parser.parseFromString(svgString, 'image/svg+xml');
  const svg = doc.querySelector('svg');

  if (!svg) {
    return null;
  }

  svg.querySelectorAll('*').forEach((node) => {
    node.removeAttribute('fill');
    node.removeAttribute('stroke');
  });

  return Array.from(svg.children)
    .map((child) => child.outerHTML)
    .join('');
};
