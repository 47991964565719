import React, { FC } from 'react';

import { Box, Typography } from '@mui/material';

import { styles } from './styles';
import IconContainer from '@components/common/IconContainer';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getProfile } from '@bus/profile/selectors';
import { book } from '@routes/book';
import { profileActions } from '@bus/profile/actions';

type FeatureItemProps = {
  iconDark: string;
  iconLight: string;
  name: string;
  link: string;
  handleClick: () => void;
  description: string;
};

export const FeatureItem: FC<FeatureItemProps> = ({
  iconDark,
  iconLight,
  name,
  link,
  handleClick,
  description,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const profile = useSelector(getProfile);

  const handleCardClick = () => {
    if (!profile?.subscription) {
      navigate(book.forUnsubscribedUsers);

      return;
    }
    dispatch(profileActions.setDatasetsClosed());
    handleClick();
    navigate(link);
  };

  return (
    <Box sx={styles.featureItem} onClick={handleCardClick}>
      <Box display={'flex'} alignItems={'flex-start'}>
        <IconContainer darkIcon={iconDark} lightIcon={iconLight} size={32} />
      </Box>
      <Box display={'flex'} flexDirection={'column'}>
        <Typography variant={'h6'} color={'text.primary'}>
          {name}
        </Typography>
        <Typography variant={'caption'} color={'text.primary'} mt={'8px'}>
          {description}
        </Typography>
      </Box>
    </Box>
  );
};

export default FeatureItem;
