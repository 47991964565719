import * as effects from 'redux-saga/effects';
import { api } from '@REST/api';
import { handleError } from '@bus/ui/saga/workers/handleError';
import { throwError } from '@bus/ui/saga/workers/throwError';

import { createdImagesActions } from '../../actions';
import { DeleteImageStudioFacesActionPayload } from '@bus/createdImages/typedefs';
import { PayloadActionWithPromiseMeta } from '@core/FinalForm/typedefs';

export function* deleteFaceSwapImages({
  payload,
  meta: { resolve, reject },
}: PayloadActionWithPromiseMeta<DeleteImageStudioFacesActionPayload>) {
  try {
    yield effects.put(createdImagesActions.startFetching());
    const response: Response = yield effects.apply(api, api.delete, [
      { endpoint: `image-studio/face-swaps/${payload.id}/` },
    ]);
    if (!response.ok) {
      reject();
      yield throwError(response);
    }
    yield effects.put(createdImagesActions.removeSwapImage({ id: payload.id }));
    resolve();
  } catch (e) {
    yield handleError(e);
  } finally {
    yield effects.put(createdImagesActions.stopFetching());
  }
}
