import React, { Suspense, useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';

import { Box } from '@mui/material';

import { styles } from './styles';
import AppBar from '@components/common/AppBar';
import { registeredModals } from '@components/modals/registeredModals';
import { Modals } from '@core/Modal';
import SuspenseLoading from '@components/common/SuspenseLoading';
import { useDispatch, useSelector } from 'react-redux';
import { getBillingStatus } from '@bus/subscription/selectors';
import { BillingStatus } from '@bus/subscription/typedefs';
import SubscriptionBillingLoading from '@components/common/SubscriptionBillingLoading';
import {
  getCurrentDataset,
  getPaypalPaymentStatus,
} from '@bus/profile/selectors';
import { profileActions } from '@bus/profile/actions';
import { getProjects } from '@bus/projects/selectors';

export type ProfileLayoutProps = {};

const ProfileLayout: React.FC<ProfileLayoutProps> = () => {
  const billingStatus = useSelector(getBillingStatus);
  const paypalPaymentStatus = useSelector(getPaypalPaymentStatus);
  const { projectName } = useParams();
  const projects = useSelector(getProjects);
  const dispatch = useDispatch();
  const currentDataset = useSelector(getCurrentDataset);

  useEffect(() => {
    if (projects.length && currentDataset === null) {
      const index = projects.findIndex((i) => i.name === projectName);
      dispatch(
        profileActions.setCurrentDataset(projects[index === -1 ? 0 : index]),
      );
    }
  }, [projects.length, projectName, currentDataset]);

  if (
    billingStatus === BillingStatus.processing ||
    paypalPaymentStatus === BillingStatus.processing
  ) {
    return <SubscriptionBillingLoading status={paypalPaymentStatus} />;
  } else if (billingStatus === BillingStatus.success) {
    return <SubscriptionBillingLoading status={billingStatus} />;
  } else if (billingStatus === BillingStatus.error) {
    return <SubscriptionBillingLoading status={billingStatus} />;
  } else {
    return (
      <Box sx={styles.root}>
        <AppBar />
        <Modals registeredModals={registeredModals} />
        <Suspense fallback={<SuspenseLoading />}>
          <Box sx={styles.wrapper}>
            <Outlet />
          </Box>
        </Suspense>
      </Box>
    );
  }
};

export default ProfileLayout;
