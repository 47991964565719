import React from 'react';

import { Box, Button, Typography, useTheme } from '@mui/material';

import { ModalTypes } from '@core/Modal/types';

import { styles } from './styles';
import ErrorIcon from '@components/icons/Error';

type ServerErrorProps = {
  text: string;
  cancelButton: {
    text: string;
  };
  confirmButton: {
    text: string;
  };
};

export const ServerError: React.FC<
  ModalTypes.ModalComponentProps<ServerErrorProps>
> = ({ closeFn }) => {
  const theme = useTheme();
  const closeModal = () => {
    closeFn();
    window.location.reload();
  };

  return (
    <Box sx={styles.serverError}>
      <Box display={'flex'} gap={'16px'}>
        <ErrorIcon fontSize={'small'} htmlColor={theme.palette.error.main} />
        <Box>
          <Typography variant={'subtitle1'} mb={'8px'} color={'text.primary'}>
            Error
          </Typography>
          <Typography variant={'body2'} color={'text.secondary'}>
            Oops. Ethernet Server Error
          </Typography>
        </Box>
      </Box>
      <Box alignSelf={'flex-end'}>
        <Button
          onClick={closeModal}
          variant={'primary'}
          sx={{ p: '14px 16px' }}>
          <Typography variant={'button'}>Reload</Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default ServerError;
