// @ts-nocheck
import { handleError } from '@bus/ui/saga/workers/handleError';
import * as effects from 'redux-saga/effects';
import { wsActions } from '@bus/ws/actions';

export function* openedSockets() {
  try {
    yield effects.put(wsActions.setSocketsStatus('OPEN'));
    yield effects.put(wsActions.setOpenedSockets(true));
    // yield effects.put(wsActions.setClosedSockets(false));
  } catch (e) {
    yield handleError(e);
  }
}
