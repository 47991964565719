// eslint-disable-next-line @typescript-eslint/no-unused-vars,prettier/prettier
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
// eslint-disable-next-line prettier/prettier
import {FillThemeActionPayload, UiState,} from './typedefs';

const xlScreenDown = window.innerWidth < 1690;

const initialState: UiState = {
  isFetching: false,
  theme: null,
  isSidebarOpen: !xlScreenDown,
  isMobileSidebarOpen: false,
  isSidebarDataset: false,
  isSidebarDescription: false,
  isSidebarConversationDataset: false,
  isSidebarConversationDescription: false,
  globalSpeaking: false,
  oneTimeSpeaking: false,
  globalListening: false,
  firstGlobalListeningIOS: false,
  firstGlobalSpeakingIOS: false,
  firstOneTimeSpeakingIOS: false,
};

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    startFetching(state) {
      state.isFetching = true;
    },
    stopFetching(state) {
      state.isFetching = false;
    },
    startOneTimeSpeaking(state) {
      state.oneTimeSpeaking = true;
    },
    startFirstGlobalListeningIOS(state) {
      state.firstGlobalListeningIOS = true;
    },
    startFirstGlobalSpeakingIOS(state) {
      state.firstGlobalSpeakingIOS = true;
    },
    startFirstOneTimeSpeakingIOS(state) {
      state.firstOneTimeSpeakingIOS = true;
    },
    stopOneTimeSpeaking(state) {
      state.oneTimeSpeaking = false;
    },
    toggleSidebar(state) {
      state.isSidebarOpen = !state.isSidebarOpen;
    },
    toggleGlobalSpeaking(state) {
      state.globalSpeaking = !state.globalSpeaking;
    },
    toggleGlobalListening(state) {
      state.globalListening = !state.globalListening;
    },
    toggleSidebarDataset(state) {
      state.isSidebarDataset = !state.isSidebarDataset;
    },
    toggleSidebarDescription(state) {
      state.isSidebarDescription = !state.isSidebarDescription;
    },
    toggleSidebarConversationDataset(state) {
      state.isSidebarConversationDataset = !state.isSidebarConversationDataset;
    },
    toggleSidebarConversationDescription(state) {
      state.isSidebarConversationDescription =
        !state.isSidebarConversationDescription;
    },
    toggleMobileSidebar(state) {
      state.isMobileSidebarOpen = !state.isMobileSidebarOpen;
    },
    fillTheme(state, action: PayloadAction<FillThemeActionPayload>) {
      state.theme = action.payload;
    },
    // INJECT
  },
});

export default uiSlice;
