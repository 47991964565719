import { Theme } from '@mui/material/styles';

export const styles = {
  trialInformationWindow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: '0 32px',
  },
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    padding: '40px 0',
    maxWidth: '1180px',
    width: '100%',
  },
  wrapper: (theme: Theme) => ({
    marginTop: '20px',
    maxWidth: '218px',
    width: '100%',
    '&:not(:last-of-type)': {
      [theme.breakpoints.down('md')]: {
        display: 'flex',
        justifyContent: 'space-between',
      },
    },
  }),
};
