import React, { FC, useState } from 'react';

import {
  Box,
  CircularProgress,
  IconButton,
  Typography,
  useTheme,
} from '@mui/material';

import { styles } from './styles';
import Done from '@components/icons/Done';
import Link from '@components/icons/Link';
import { handleErrors } from '@helpers/handleErrors';
import { useDispatch, useSelector } from 'react-redux';
import {
  getIsFetchingSummary,
  getSocialNetworks,
  getUploadedFaces,
} from '@bus/createdImages/selectors';
import Twitter from '@components/icons/Twitter';
import Linkedin from '@components/icons/Linkedin';
import Facebook from '@components/icons/Facebook';
import TelegramIcon from '@mui/icons-material/Telegram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import RedditIcon from '@mui/icons-material/Reddit';
import { truncateText } from '@helpers/truncateText';
import { useSnackbar } from 'notistack';
import WrapFace from '@components/icons/WrapFace';
import { createdImagesActions } from '@bus/createdImages/actions';
import { profileActions } from '@bus/profile/actions';
import AutoModeIcon from '@mui/icons-material/AutoMode';

type ShareProps = {
  summary: string;
  shortImage: string;
  image: string;
  type: string;
  id?: number | string;
};

export const Share: FC<ShareProps> = ({
  summary,
  shortImage,
  image,
  type,
  id,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isFetching = useSelector(getIsFetchingSummary);
  const networks = useSelector(getSocialNetworks);
  const uploadedFaces = useSelector(getUploadedFaces);
  const [copyIcon, setCopyIcon] = useState(<Link fontSize={'small'} />);
  const maxChars: { [key: string]: number } = {
    twitter: 280,
    reddit: 300,
    linkedin: 1300,
    facebook: 63206,
    telegram: 4096,
    whatsApp: 4096,
  };
  const encodedUrl = encodeURIComponent(image);
  const iconMap: { [key: string]: React.JSX.Element } = {
    Twitter: <Twitter fontSize={'small'} />,
    Linkedin: <Linkedin fontSize={'small'} />,
    Facebook: <Facebook fontSize={'small'} />,
    Telegram: <TelegramIcon fontSize={'small'} />,
    WhatsApp: <WhatsAppIcon fontSize={'small'} />,
    Reddit: <RedditIcon fontSize={'small'} />,
  };
  const handleClickShare = (event: any) => {
    event.stopPropagation();
  };

  const { enqueueSnackbar } = useSnackbar();

  const handleCopy = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    navigator.clipboard
      .writeText(`${image}\n\n${summary}`)
      .then(() => {
        setCopyIcon(<Done fontSize={'small'} />);
        setTimeout(() => {
          setCopyIcon(<Link fontSize={'small'} />);
        }, 1000);
      })
      .catch((error) => {
        handleErrors(error);
      });
    enqueueSnackbar('The text was copied', { variant: 'infoSnackbar' });
  };

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    social: string,
  ) => {
    event.stopPropagation();
    const popupWidth = 600;
    const popupHeight = 400;
    const left = window.innerWidth / 2 - popupWidth / 2;
    const top = window.innerHeight / 2 - popupHeight / 2;
    const popupParams = `scrollbars=no, width=${popupWidth}, height=${popupHeight}, top=${top}, left=${left}`;
    let shareUrl = '';

    const socialNetwork = networks.find((item) => item.name === social);
    const truncatedValue = truncateText(
      `${summary}`,
      maxChars[social] || 500,
      false,
      socialNetwork?.tags.join(' ') ?? '',
      shortImage,
    );
    const truncatedValueWithUrl = truncateText(
      `${shortImage ?? ''}\n\n${summary}`,
      maxChars[social] || 500,
      true,
      socialNetwork?.tags.join(' ') ?? '',
      shortImage,
    );
    const encodedValueWithUrl = encodeURIComponent(truncatedValueWithUrl);
    const encodedValue = encodeURIComponent(truncatedValue);

    switch (social) {
      case 'twitter':
        shareUrl = `https://twitter.com/intent/tweet?text=${encodedValueWithUrl}`;
        break;
      case 'linkedin':
        shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodedUrl}&text=${encodedValue}`;
        break;
      case 'facebook':
        shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`;
        break;
      case 'telegram':
        shareUrl = `https://t.me/share/url?url=${encodedUrl}&text=${encodedValue}`;
        break;
      case 'whatsApp':
        shareUrl = `https://api.whatsapp.com/send?text=${encodedValue} ${encodedUrl}`;
        break;
      case 'reddit':
        shareUrl = `https://www.reddit.com/submit?url=${encodedUrl}&title=${encodedValue}`;
        break;
      default:
        break;
    }

    window.open(shareUrl, 'sharePopup', popupParams);
  };

  const handleOpenSwap = () => {
    dispatch(profileActions.setScaleImage(null));
    dispatch(
      createdImagesActions.createSwap({
        target_image_url: image,
        swap_image_url: uploadedFaces[0].image,
      }),
    );
    dispatch(
      createdImagesActions.openWrapStudio({
        status: true,
        targetImage: image,
      }),
    );
  };

  const handleRegenerate = () => {
    new Promise((resolve, reject) => {
      dispatch(
        createdImagesActions.regenerateGeneratedImage({
          values: { id },
          resolve,
          reject,
        }),
      );
    })
      .then()
      .catch();
  };

  return (
    <Box sx={styles.share} onClick={handleClickShare}>
      <Box sx={styles.wrapper}>
        <Box sx={styles.leftWrapper} display={'flex'}>
          <Box display={'flex'} alignItems={'center'}>
            <IconButton
              color={'warning'}
              onClick={handleOpenSwap}
              sx={styles.button}
              disabled={isFetching}>
              <WrapFace
                fontSize={'small'}
                htmlColor={theme.palette.text.primary}
              />
            </IconButton>
            <Typography
              variant={'subtitle2'}
              color={'text.primary'}
              width={'50px'}
              ml={'10px'}
              mr={'20px'}>
              Face Swap
            </Typography>
          </Box>
          {type === 'studio' && (
            <Box display={'flex'} alignItems={'center'} position={'relative'}>
              <IconButton
                color={'warning'}
                onClick={handleRegenerate}
                sx={[styles.button, styles.copy, { mr: '10px' }]}
                disabled={isFetching}>
                <AutoModeIcon fontSize={'small'} />
              </IconButton>
              {isFetching && (
                <CircularProgress
                  sx={styles.progress}
                  thickness={1}
                  size={40}
                />
              )}
              <Typography
                variant={'subtitle2'}
                color={'text.primary'}
                width={'85px'}
                mr={'10px'}>
                Regenerate Image
              </Typography>
            </Box>
          )}
        </Box>
        <Box display={'flex'} gap={'10px'} alignItems={'center'}>
          <Typography
            variant={'subtitle2'}
            color={'text.primary'}
            mr={'10px'}
            width={'46px'}>
            Share Image
          </Typography>
          <Box display={'flex'} position={'relative'}>
            <IconButton
              color={'warning'}
              onClick={handleCopy}
              sx={[styles.button, styles.copy]}
              disabled={isFetching}>
              {copyIcon}
            </IconButton>
            {isFetching && (
              <CircularProgress sx={styles.progress} thickness={1} size={40} />
            )}
          </Box>
          {networks.map((item) => (
            <Box key={item.id} display={'flex'} position={'relative'}>
              <IconButton
                id={item.id}
                key={item.id}
                sx={styles.button}
                onClick={(e) => handleClick(e, item.name)}
                color={'warning'}
                disabled={isFetching}>
                {iconMap[item.icon]}
              </IconButton>
              {isFetching && (
                <CircularProgress
                  sx={styles.progress}
                  thickness={1}
                  size={40}
                />
              )}
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default Share;
