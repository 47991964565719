export const getEndpointParams = (params: any, urlParams: URLSearchParams) => {
  Object.keys(params).forEach((key: string) => {
    if (params[key] instanceof Array && !!params[key].length) {
      return params[key].forEach((item: any) => {
        urlParams.append(key, item);
      });
    }
    if (params[key] && !Array.isArray(params[key])) {
      return urlParams.set(key, params[key]);
    }
  });

  return decodeURIComponent(urlParams.toString());
};
