import { createAction } from '@reduxjs/toolkit';

import savedMessagesSlice from './slice';
import prepareActions from '@helpers/prepareActions';

export const savedMessagesActions = {
  ...savedMessagesSlice.actions,
  saveMessage: createAction(
    'savedMessages/saveMessage',
    prepareActions.movePromiseToMeta,
  ),
  fetchSavedProjects: createAction('savedMessages/fetchSavedProjects'),
  deleteSavedMessage: createAction(
    'savedMessages/deleteSavedMessage',
    prepareActions.withNavigate,
  ),
  fetchMessagesSaved: createAction(
    'savedMessages/fillMessagesSaved',
    (payload: any) => {
      return { payload };
    },
  ),
  loadMoreSavedMessages: createAction(
    'savedMessages/loadMoreSavedMessages',
    (nextPageUrl: string, projectId: number) => ({
      payload: { nextPageUrl, projectId },
    }),
  ),
  fetchMessagePagination: createAction(
    'savedMessages/fetchMessagePagination',
    prepareActions.withNavigate,
  ),
  // INJECT
};
