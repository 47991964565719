import createSagaMiddleware from 'redux-saga';
import reduxWebsocket from '@giantmachines/redux-websocket';

import { Middleware, configureStore } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/browser';

import { rootReducer } from './rootReducer';
import { rootSaga } from './rootSaga';
import { createBrowserHistory } from 'history';

import { persistStore } from 'redux-persist';
import { WSPrefix } from '@setup/typedefs';

const sagaMiddleware = createSagaMiddleware();

const reduxWebsocketMiddleware = reduxWebsocket({
  prefix: WSPrefix.ws,
  reconnectOnClose: true,
  reconnectOnError: true,
});

process.env.NODE_ENV !== 'development' &&
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  });

const middleware: Middleware[] = [sagaMiddleware, reduxWebsocketMiddleware];

export const history = createBrowserHistory();

export const Store = (initialState: any) => {
  const store = configureStore({
    reducer: rootReducer,
    preloadedState: initialState,
    devTools: process.env.NODE_ENV !== 'production',
    middleware,
  });

  const persistor = persistStore(store);

  sagaMiddleware.run(rootSaga);

  return { store, persistor };
};

export const createStore = (initialState = {}) => Store(initialState);
