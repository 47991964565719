import * as effects from 'redux-saga/effects';
import { api } from '@REST/api';
import { handleError } from '@bus/ui/saga/workers/handleError';
import { throwError } from '@bus/ui/saga/workers/throwError';

import { chatActions } from '../../actions';
import {
  LoadPrevMessagesActionPayload,
  PushMoreMessagesActionPayload,
} from '../../typedefs';
import { PayloadActionWithPromiseMeta } from '@core/FinalForm/typedefs';
import { projectsActions } from '@bus/projects/actions';

export function* loadPrevMessages({
  payload,
  meta: { resolve, reject },
}: PayloadActionWithPromiseMeta<LoadPrevMessagesActionPayload>) {
  try {
    yield effects.put(chatActions.startFetching());
    const response: Response = yield effects.apply(api, api.get, [
      { endpoint: payload.url, direct: true },
    ]);
    if (!response.ok) {
      reject();
      yield throwError(response);
    }

    const data: PushMoreMessagesActionPayload = yield effects.call([
      response,
      'json',
    ]);

    yield effects.put(
      projectsActions.pushPrevMoreMessages({ data, chatId: payload.chatId }),
    );

    resolve();
  } catch (e) {
    yield handleError(e);
  } finally {
    yield effects.put(chatActions.stopFetching());
  }
}
