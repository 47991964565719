// eslint-disable-next-line @typescript-eslint/no-unused-vars,prettier/prettier
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// eslint-disable-next-line prettier/prettier
import {
  WsState,
} from './typedefs';

const initialState: WsState = {
  isFetching: false,
  socketStatus: '',
  isClosedSockets: false,
  isOpenedSockets: false,
  isApprovedOrder: false,
};

const wsSlice = createSlice({
  name: 'ws',
  initialState,
  reducers: {
    startFetching(state) {
      state.isFetching = true;
    },
    stopFetching(state) {
      state.isFetching = false;
    },
    setSocketsStatus(state, action: PayloadAction<string>) {
      state.socketStatus = action.payload;
    },
    setClosedSockets(state, action: PayloadAction<boolean>) {
      state.isClosedSockets = action.payload;
    },
    setOpenedSockets(state, action: PayloadAction<boolean>) {
      state.isOpenedSockets = action.payload;
    },
    setApprovedOrder(state, action: PayloadAction<boolean>) {
      state.isApprovedOrder = action.payload;
    },
    // INJECT
  },
});

export default wsSlice;
