import React, { FC } from 'react';

import { Box } from '@mui/material';

import { styles } from './styles';
import { keyframes } from '@emotion/react';

type LiveAnimationProps = {};

export const LiveAnimation: FC<LiveAnimationProps> = () => {
  const typing = keyframes`
  0% {
        opacity: 0;
      }
  25% {
    opacity: 0.25;
       }
  75% {
    opacity: 1;
       }
`;

  return (
    <Box
      sx={{
        ...styles.dotsAnimation,
        animation: `${typing} 1s linear infinite alternate`,
      }}>
      <Box sx={styles.first} />
      <Box sx={styles.second} />
      <Box sx={styles.third} />
    </Box>
  );
};

export default LiveAnimation;
