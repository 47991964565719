import { put } from 'redux-saga/effects';
import { authActions } from '@bus/auth/actions';
import * as Sentry from '@sentry/react';
import { profileActions } from '@bus/profile/actions';
import { modalActions } from '@core/Modal/state/actions';
import { projectsActions } from '@bus/projects/actions';
import { createdImagesActions } from '@bus/createdImages/actions';
export function* handleError(error: any) {
  const statusCode = error.cause?.status;
  if (statusCode >= 500) {
    yield put(
      modalActions.modal({
        component: 'ServerError',
        forceClose: false,
      }),
    );
  }
  if (statusCode === 401) {
    yield put(authActions.clearData());
    yield put(createdImagesActions.clearData());
    yield put(profileActions.clearData());
    yield put(projectsActions.clearProjects());
  } else {
    process.env.NODE_ENV !== 'development' && Sentry.captureException(error);
  }
}
