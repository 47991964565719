import React from 'react';

import { Box, Button, Typography } from '@mui/material';

import { ModalTypes } from '@core/Modal/types';

import { styles } from './styles';
import TurnOnAudioBigIcon from '@components/icons/TurnOnAudioBigIcon';

type TurnOnAudioProps = {
  title: string;
  description: string;
};

export const TurnOnAudio: React.FC<
  ModalTypes.ModalComponentProps<TurnOnAudioProps>
> = ({ closeFn, onConfirm, title, description }) => {
  const closeModal = () => {
    closeFn();
  };
  const handleClick = () => {
    onConfirm();
    closeFn();
  };

  return (
    <Box sx={styles.turnOnAudio}>
      <Box sx={styles.iconWrapper}>
        <TurnOnAudioBigIcon
          viewBox={'0 0 260 260'}
          sx={{
            width: '260px',
            height: '260px',
          }}
        />
      </Box>
      <Box p={'20px 20px 6px'}>
        <Box mb={'20px'}>
          <Typography variant={'h5'} color={'text.primary'} mb={'20px'}>
            {title}
          </Typography>
          <Typography variant={'body1'} color={'text.primary'}>
            {description}
          </Typography>
        </Box>
        <Box
          width={'100%'}
          display={'flex'}
          flexDirection={'column'}
          gap={'6px'}>
          <Button fullWidth onClick={handleClick} variant={'primary'}>
            <Typography variant={'button'} color={'primary.contrastText'}>
              Turn On the sound
            </Typography>
          </Button>
          <Button
            fullWidth
            onClick={closeModal}
            variant={'secondary'}
            color={'secondary'}>
            <Typography variant={'button'} color={'common.text.link'}>
              Skip
            </Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default TurnOnAudio;
