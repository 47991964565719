import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const Success: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M17.0089 7.74026H15.7526C15.4794 7.74026 15.2196 7.87151 15.0589 8.09651L10.8481 13.9358L8.94099 11.2894C8.78028 11.0671 8.52313 10.9331 8.24724 10.9331H6.99099C6.81689 10.9331 6.7151 11.1313 6.81689 11.2733L10.1544 15.9019C10.2332 16.0119 10.3372 16.1016 10.4576 16.1634C10.578 16.2253 10.7114 16.2576 10.8468 16.2576C10.9822 16.2576 11.1156 16.2253 11.236 16.1634C11.3564 16.1016 11.4604 16.0119 11.5392 15.9019L17.1803 8.08044C17.2847 7.93848 17.183 7.74026 17.0089 7.74026Z" />
      <path d="M12 0C5.37321 0 0 5.37321 0 12C0 18.6268 5.37321 24 12 24C18.6268 24 24 18.6268 24 12C24 5.37321 18.6268 0 12 0ZM12 21.9643C6.49821 21.9643 2.03571 17.5018 2.03571 12C2.03571 6.49821 6.49821 2.03571 12 2.03571C17.5018 2.03571 21.9643 6.49821 21.9643 12C21.9643 17.5018 17.5018 21.9643 12 21.9643Z" />
    </SvgIcon>
  );
};

export default Success;
