export const shortTokensAmount = (
  num: number | string,
  withSpaces: boolean = true,
  withSymbol: boolean = true,
) => {
  let amount = '';

  if (withSpaces) {
    const floorNum = Math.floor(+num).toString().split('');
    const result = [];
    let counter = 0;

    for (let i = floorNum.length - 1; i >= 0; i--) {
      counter++;
      result.unshift(floorNum[i]);
      if (counter % 3 === 0 && i !== 0) {
        result.unshift(' ');
      }
    }

    amount = result.join('');
  } else {
    amount = Math.floor(+num).toString();
  }

  return withSymbol ? amount + ' CREATE' : amount;
};
