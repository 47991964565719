// eslint-disable-next-line @typescript-eslint/no-unused-vars,prettier/prettier
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// eslint-disable-next-line prettier/prettier
import {
  SettingsState,
  FillSettingsActionPayload,
} from './typedefs';

const initialState: SettingsState = {
  isFetching: false,
  settings: null,
  allowCountdown: false,
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    startFetching(state) {
      state.isFetching = true;
    },
    stopFetching(state) {
      state.isFetching = false;
    },
    fillSettings(state, action: PayloadAction<FillSettingsActionPayload>) {
      state.settings = action.payload;
    },
    clearVisualizeTimer(state) {
      if (state.settings) {
        state.settings = {
          ...state.settings,
          time_left_to_visualize: 0,
        };
      }
    },
    allowCountdown(state, action: PayloadAction<boolean>) {
      state.allowCountdown = action.payload;
    },
    // INJECT
  },
});

export default settingsSlice;
