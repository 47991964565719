import { useEffect, useState } from 'react';
import { aiConversationActions } from '@bus/aiConversation/actions';
import { useDispatch } from 'react-redux';

export const useTimer = (targetDate: string) => {
  const dispatch = useDispatch();
  const [isFinished, setIsFinished] = useState(false);
  useEffect(() => {
    if (targetDate) {
      const timer = setInterval(() => {
        const timeRemaining = getTimeRemaining();
        setRemainingTime(timeRemaining);
        if (
          timeRemaining.days === 0 &&
          timeRemaining.hours === 0 &&
          timeRemaining.minutes === 0 &&
          timeRemaining.seconds === 0
        ) {
          setIsFinished(true);
          dispatch(aiConversationActions.fetchSessions());
          clearInterval(timer);
        } else {
          setIsFinished(false);
        }
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [targetDate]);

  const getTimeRemaining = () => {
    const targetTime = new Date(targetDate).getTime();
    const currentTime = new Date().getTime();
    const timeDifference = targetTime - currentTime;

    if (timeDifference <= 0 || !timeDifference) {
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }

    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
    );
    const minutes = Math.floor(
      (timeDifference % (1000 * 60 * 60)) / (1000 * 60),
    );
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

    return {
      days: days > 9 ? days : '0' + days,
      hours: hours > 9 ? hours : '0' + hours,
      minutes: minutes > 9 ? minutes : '0' + minutes,
      seconds: seconds > 9 ? seconds : '0' + seconds,
    };
  };

  const [remainingTime, setRemainingTime] = useState(getTimeRemaining());

  return { remainingTime, isFinished };
};
