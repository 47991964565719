import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getNextSession } from '@bus/aiConversation/selectors';
import { authActions } from '@bus/auth/actions';
import { aiConversationActions } from '@bus/aiConversation/actions';
import { createdImagesActions } from '@bus/createdImages/actions';
import { getScaleImage } from '@bus/profile/selectors';

export const useRoutersHook = () => {
  const dispatch = useDispatch();
  const nextSession = useSelector(getNextSession);
  const [timerInterval, setTimerInterval] = useState<any>(null);
  const scaleImage = useSelector(getScaleImage);

  useEffect(() => {
    dispatch(authActions.authenticate());
  }, [dispatch]);

  useEffect(() => {
    if (nextSession === null) {
      dispatch(aiConversationActions.nextSession());
    }
  }, [nextSession]);

  useEffect(() => {
    if (scaleImage && scaleImage.type === 'gallery' && !scaleImage.isSwap) {
      dispatch(
        createdImagesActions.fetchSummary({ chatId: scaleImage.chatId }),
      );
    }
    if (scaleImage && scaleImage.type === 'studio' && !scaleImage.isSwap) {
      dispatch(
        createdImagesActions.fetchStudioImageSummary({
          imageId: scaleImage.id,
        }),
      );
    }
  }, [scaleImage]);

  const updateTimer = () => {
    if (nextSession?.id) {
      const timeDifference = getTimeDifference(nextSession.start_at);

      if (timeDifference <= 0) {
        dispatch(aiConversationActions.nextSession());

        return;
      }
    }
  };

  const getTimeDifference = (targetDate: string) => {
    const targetTime = new Date(targetDate).getTime();
    const now = new Date();

    return targetTime - now.getTime();
  };

  useEffect(() => {
    if (nextSession?.id) {
      const interval = setInterval(updateTimer, 1000);
      setTimerInterval(interval);
    } else {
      clearInterval(timerInterval);
      setTimerInterval(null);
    }

    return () => {
      clearInterval(timerInterval);
      setTimerInterval(null);
    };
  }, [nextSession]);

  useEffect(() => {
    if (nextSession !== null) {
      dispatch(aiConversationActions.fetchSessions());
    }
  }, [nextSession]);
};
