// eslint-disable-next-line @typescript-eslint/no-unused-vars,prettier/prettier
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// eslint-disable-next-line prettier/prettier
import {
  CreatedImagesState,
  FillCreatedImagesActionPayload,
  ICreatedImage,
  IGallery,
  PushMoreImagesActionPayload,
  FillSummaryActionPayload,
  FillStudioImagesActionPayload,
  IImages,
  PushMoreStudioImagesActionPayload,
  FillImageStudioFacesActionPayload,
  IImageWrapStudioOpen,
  IUploadedFaces,
  FillSwapActionPayload,
  ChangeSwapActionPayload,
  FillFaceSwapImagesActionPayload,
  IWrapGroup,
  PushMoreSwapImagesActionPayload,
  IWrapImage,
  ICreatedStudioImage,
} from './typedefs';
import { getDate, getDateTime } from '@helpers/getDateTime';
import FaceSwapDark from '@assets/faceSwapDark.png';
import FaceSwapLight from '@assets/faceSwapLight.png';
import GenerationDark from '@assets/imageGenerationDark.png';
import GenerationLight from '@assets/imageGenerationLight.png';
import { v4 as uuidv4 } from 'uuid';

const networks = [
  {
    id: 'twitter',
    name: 'twitter',
    icon: 'Twitter',
    active: true,
    default: true,
    tags: ['@sibyls_ai'],
  },
  {
    id: 'linkedin',
    name: 'linkedin',
    icon: 'Linkedin',
    active: true,
    default: true,
    tags: [],
  },
  {
    id: 'facebook',
    name: 'facebook',
    icon: 'Facebook',
    active: true,
    default: true,
    tags: ['@sibylsai'],
  },
  {
    id: 'telegram',
    name: 'telegram',
    icon: 'Telegram',
    active: false,
    default: false,
    tags: [],
  },
  {
    id: 'whatsApp',
    name: 'whatsApp',
    icon: 'WhatsApp',
    active: false,
    default: false,
    tags: [],
  },
  {
    id: 'reddit',
    name: 'reddit',
    icon: 'Reddit',
    active: false,
    default: false,
    tags: [],
  },
];

const initialState: CreatedImagesState = {
  isFetching: false,
  isFetchingSummary: false,
  isFetchingUpdateImage: false,
  gallery: null,
  studioImages: null,
  wrapImages: null,
  filters: [],
  summary: '',
  socialNetworks: networks,
  isImageGenerationStudioOpen: false,
  isImageWrapStudioOpen: {
    status: false,
    targetImage: '',
  },
  imageUploadedFaces: [],
  createSwapId: null,
};

const createdImagesSlice = createSlice({
  name: 'createdImages',
  initialState,
  reducers: {
    startFetching(state) {
      state.isFetching = true;
    },
    clearData(state) {
      state.createSwapId = null;
      state.wrapImages = null;
      state.studioImages = null;
      state.gallery = null;
    },
    stopFetching(state) {
      state.isFetching = false;
    },
    startFetchingUpdateImage(state) {
      state.isFetchingUpdateImage = true;
    },
    stopFetchingUpdateImage(state) {
      state.isFetchingUpdateImage = false;
    },
    openStudio(state) {
      state.isImageGenerationStudioOpen = true;
    },
    closeStudio(state) {
      state.isImageGenerationStudioOpen = false;
    },
    openWrapStudio(state, action: PayloadAction<IImageWrapStudioOpen>) {
      state.isImageWrapStudioOpen = {
        ...action.payload,
      };
    },
    closeWrapStudio(state, action: PayloadAction<IImageWrapStudioOpen>) {
      state.isImageWrapStudioOpen = {
        ...action.payload,
      };
    },
    startFetchingSummary(state) {
      state.isFetchingSummary = true;
    },
    stopFetchingSummary(state) {
      state.isFetchingSummary = false;
    },
    fillCreatedImages(
      state,
      action: PayloadAction<FillCreatedImagesActionPayload>,
    ) {
      const newImages: IGallery[] = [];

      action.payload.results.forEach((item: ICreatedImage) => {
        const date = getDateTime(item.created_at, 'dd MMM yyyy');

        const existingDateGroup = newImages.find((group) => group.id === date);

        if (existingDateGroup) {
          existingDateGroup.images.push({ ...item, summary: '' });
        } else {
          newImages.push({
            id: date,
            date: date,
            images: [{ ...item, summary: '' }],
          });
        }
      });

      state.gallery = {
        count: action.payload.count,
        next: action.payload.next,
        previous: action.payload.previous,
        results: newImages,
      };
    },
    fillFilters(state, action: PayloadAction<any>) {
      state.filters = [
        { id: 0, name: 'All Datasets' },
        {
          id: 'self-generated',
          name: 'Self Generated',
          icon_light: GenerationLight,
          icon_dark: GenerationDark,
        },
        {
          id: 'face-swap',
          name: 'Face Swap',
          icon_light: FaceSwapLight,
          icon_dark: FaceSwapDark,
        },
        ...action.payload,
      ];
    },
    pushMoreImages(state, action: PayloadAction<PushMoreImagesActionPayload>) {
      const newImages: IGallery[] = state.gallery?.results ?? [];

      action.payload.results.forEach((item: ICreatedImage) => {
        const date = getDateTime(item.created_at, 'dd MMM yyyy');

        const existingDateGroup = newImages.find((group) => group.id === date);

        if (existingDateGroup) {
          existingDateGroup.images.push({ ...item, summary: '' });
        } else {
          newImages.push({
            id: date,
            date: date,
            images: [{ ...item, summary: '' }],
          });
        }
      });

      state.gallery = {
        count: action.payload.count,
        next: action.payload.next,
        previous: action.payload.previous,
        results: newImages,
      };
    },
    fillSummary(state, action: PayloadAction<FillSummaryActionPayload>) {
      state.summary = action.payload.summary;
    },
    updateSocialNetworks(state, action: PayloadAction<string>) {
      state.socialNetworks = state.socialNetworks.map((item) => {
        if (!item.default && item.name !== action.payload) {
          return { ...item, active: false };
        }

        if (!item.default && item.name !== action.payload) {
          return { ...item, active: true };
        }

        return { ...item, active: true };
      });
    },
    fillStudioImages(
      state,
      action: PayloadAction<FillStudioImagesActionPayload>,
    ) {
      const newImages: IImages[] = [];

      action.payload.results.forEach((item) => {
        const date = getDateTime(item.created_at, 'dd MMM yyyy');

        const existingDateGroup = newImages.find((group) => group.id === date);

        if (existingDateGroup) {
          existingDateGroup.images.push({ ...item });
        } else {
          newImages.push({
            id: date,
            date: date,
            images: [{ ...item }],
          });
        }
      });

      state.studioImages = {
        count: action.payload.count,
        results: newImages,
      };
    },
    addStudioImages(
      state,
      action: PayloadAction<{ images: ICreatedStudioImage[] }>,
    ) {
      const newImages: IImages[] = state.studioImages?.results ?? [];

      action.payload.images.forEach((item) => {
        const date = getDateTime(item.created_at, 'dd MMM yyyy');

        const existingDateGroup = newImages.find((group) => group.id === date);

        if (existingDateGroup) {
          existingDateGroup.images.unshift({ ...item });
        } else {
          newImages.unshift({
            id: date,
            date: date,
            images: [{ ...item }],
          });
        }
      });

      const totalCount = newImages.reduce(
        (acc, group) => acc + group.images.length,
        0,
      );

      state.studioImages = {
        count: totalCount,
        results: newImages,
      };
    },
    pushMoreStudioImages(
      state,
      action: PayloadAction<PushMoreStudioImagesActionPayload>,
    ) {
      const newImages: IImages[] = state.studioImages?.results ?? [];

      action.payload.results.forEach((item) => {
        const date = getDateTime(item.created_at, 'dd MMM yyyy');

        const existingDateGroup = newImages.find((group) => group.id === date);

        if (existingDateGroup) {
          existingDateGroup.images.push({ ...item });
        } else {
          newImages.push({
            id: date,
            date: date,
            images: [{ ...item }],
          });
        }
      });

      state.studioImages = {
        count: action.payload.count,
        results: newImages,
      };
    },
    updateStudioImages(
      state,
      action: PayloadAction<{ images: ICreatedStudioImage[] }>,
    ) {
      const { images } = action.payload;
      const date = getDateTime(images[0].created_at, 'dd MMM yyyy');
      if (state.studioImages) {
        state.studioImages = {
          ...state.studioImages,
          results: state.studioImages.results.map((group) => {
            if (date === group.date) {
              const updatedGroupImages = [...group.images];
              images.forEach((newImage) => {
                const existingIndex = updatedGroupImages.findIndex(
                  (image) => image.id === newImage.id,
                );
                if (existingIndex !== -1) {
                  updatedGroupImages[existingIndex] = newImage;
                } else {
                  updatedGroupImages.unshift(newImage);
                }
              });

              return {
                ...group,
                images: updatedGroupImages,
              };
            }

            return group;
          }),
        };
      }
    },
    fillImageStudioFaces(
      state,
      action: PayloadAction<FillImageStudioFacesActionPayload>,
    ) {
      const neededFakeObjects = 4 - action.payload.length;
      const fakeObjects = Array.from(
        { length: neededFakeObjects },
        (_, index) => ({
          created_at: '',
          image: '',
          ratio: null,
          user: 0,
          id: uuidv4(),
        }),
      );

      state.imageUploadedFaces = [...fakeObjects, ...action.payload].reverse();
    },
    removeImageStudioFaces(state, action: PayloadAction<{ id: number }>) {
      state.imageUploadedFaces = state.imageUploadedFaces.filter(
        (item) => item.id !== action.payload.id,
      );

      const neededFakeObjects = 4 - state.imageUploadedFaces.length;
      const fakeObjects = Array.from(
        { length: neededFakeObjects },
        (_, index) => ({
          created_at: '',
          image: '',
          ratio: null,
          user: 0,
          id: uuidv4(),
        }),
      );

      state.imageUploadedFaces = [...state.imageUploadedFaces, ...fakeObjects];
    },
    updateImageStudioFaces(state, action: PayloadAction<IUploadedFaces>) {
      state.imageUploadedFaces = [action.payload, ...state.imageUploadedFaces];

      const fakeIndex = state.imageUploadedFaces.findIndex(
        (face) => typeof face.id === 'string',
      );

      if (fakeIndex !== -1) {
        state.imageUploadedFaces = state.imageUploadedFaces.filter(
          (_, index) => index !== fakeIndex,
        );
      }
    },
    updateFirstFaces(state, action: PayloadAction<string>) {
      state.imageUploadedFaces = state.imageUploadedFaces.map((item, index) => {
        if (index === 0) {
          return {
            ...item,
            image: action.payload,
          };
        }

        return item;
      });
    },
    fillSwap(state, action: PayloadAction<FillSwapActionPayload>) {
      state.createSwapId = action.payload;
    },
    changeSwap(state, action: PayloadAction<ChangeSwapActionPayload | null>) {
      state.createSwapId = action.payload;
    },
    fillFaceSwapImages(
      state,
      action: PayloadAction<FillFaceSwapImagesActionPayload>,
    ) {
      const newImages: IWrapGroup[] = [];

      action.payload.results.forEach((item) => {
        const date = getDateTime(item.created_at, 'dd MMM yyyy');

        const existingDateGroup = newImages.find((group) => group.id === date);

        if (existingDateGroup) {
          existingDateGroup.images.push({ ...item });
        } else {
          newImages.push({
            id: date,
            date: date,
            images: [{ ...item }],
          });
        }
      });

      state.wrapImages = {
        count: action.payload.count,
        results: newImages,
      };
    },
    updateFaceSwapImages(state, action: PayloadAction<{ image: IWrapImage }>) {
      const { image } = action.payload;
      const date = getDateTime(image.created_at, 'dd MMM yyyy');
      if (state.wrapImages) {
        state.wrapImages = {
          ...state.wrapImages,
          results: state.wrapImages.results.map((group) => {
            if (date === group.date) {
              return {
                ...group,
                images: group.images.some((item) => item.id === image.id)
                  ? group.images.map((item) => {
                      if (item.id === image.id) {
                        return image;
                      }

                      return item;
                    })
                  : [image, ...group.images],
              };
            }

            return group;
          }),
        };
      }
    },
    addSwapImages(state, action: PayloadAction<{ image: IWrapImage }>) {
      const newImage = action.payload.image;
      const newImages: IWrapGroup[] = state.wrapImages?.results ?? [];
      const date = getDateTime(newImage.created_at, 'dd MMM yyyy');

      const existingDateGroup = newImages.find((group) => group.id === date);

      if (existingDateGroup) {
        existingDateGroup.images.unshift({ ...newImage });
      } else {
        newImages.unshift({
          id: date,
          date: date,
          images: [{ ...newImage }],
        });
      }

      const totalCount = newImages.reduce(
        (acc, group) => acc + group.images.length,
        0,
      );

      state.wrapImages = {
        count: totalCount,
        results: newImages,
      };
    },

    pushMoreSwapImages(
      state,
      action: PayloadAction<PushMoreSwapImagesActionPayload>,
    ) {
      const newImages: IWrapGroup[] = state.wrapImages?.results ?? [];

      action.payload.results.forEach((item) => {
        const date = getDateTime(item.created_at, 'dd MMM yyyy');

        const existingDateGroup = newImages.find((group) => group.id === date);

        if (existingDateGroup) {
          existingDateGroup.images.push({ ...item });
        } else {
          newImages.push({
            id: date,
            date: date,
            images: [{ ...item }],
          });
        }
      });

      state.wrapImages = {
        count: action.payload.count,
        results: newImages,
      };
    },

    removeSwapImage(state, action: PayloadAction<{ id: number }>) {
      if (state.wrapImages) {
        state.wrapImages = {
          ...state.wrapImages,
          results: state.wrapImages.results
            .map((images) => {
              return {
                ...images,
                images: images.images.filter(
                  (image) => image.id !== action.payload.id,
                ),
              };
            })
            .filter((images) => !!images.images.length),
        };
      }
    },

    removeCreatedImage(state, action: PayloadAction<{ id: number }>) {
      if (state.gallery) {
        state.gallery = {
          ...state.gallery,
          results: state.gallery.results.map((images) => {
            return {
              ...images,
              images: images.images.map((image) => {
                if (image.id === action.payload.id) {
                  return {
                    ...image,
                    image: null,
                    deleted_at: getDate(new Date(), 'dd MMM yyyy'),
                  };
                }

                return image;
              }),
            };
          }),
        };
      }
    },

    removeStudioImage(state, action: PayloadAction<{ id: number }>) {
      if (state.studioImages) {
        state.studioImages = {
          ...state.studioImages,
          results: state.studioImages.results.map((images) => {
            return {
              ...images,
              images: images.images.map((image) => {
                if (image.id === action.payload.id) {
                  return {
                    ...image,
                    image: null,
                    deleted_at: getDate(new Date(), 'dd MMM yyyy'),
                  };
                }

                return image;
              }),
            };
          }),
        };
      }
    },
  },
});

export default createdImagesSlice;
