import React, { FC } from 'react';

import { Box, CircularProgress, Typography, useTheme } from '@mui/material';

import { styles } from './styles';
import Logo from '@components/icons/Logo';

type SuspenseLoadingProps = {};

export const SuspenseLoading: FC<SuspenseLoadingProps> = () => {
  const theme = useTheme();

  return (
    <Box sx={styles.suspenseLoading}>
      <Box sx={styles.wrapper}>
        <CircularProgress sx={styles.progress} thickness={2} size={80} />
        <Logo
          sx={styles.icon}
          viewBox={'0 0 60 60'}
          htmlColor={theme.palette.text.primary}
        />
      </Box>
      <Typography variant={'subtitle1'} my={'20px'} color={'text.primary'}>
        Loading...
      </Typography>
      <Typography
        variant={'subtitle1'}
        color={
          'text.disabled'
        }>{`The world's first neural network`}</Typography>
      <Typography variant={'subtitle1'} color={'text.disabled'}>
        trained on ancient spiritual wisdom.
      </Typography>
    </Box>
  );
};

export default SuspenseLoading;
