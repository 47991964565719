// eslint-disable-next-line @typescript-eslint/no-unused-vars,prettier/prettier
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

// eslint-disable-next-line prettier/prettier
import {
  StoryTellerState,
  FillStoryActionPayload,
  FillStoryMessagesActionPayload,
  FillMoreStoryMessagesActionPayload,
  UpdateStoryChatActionPayload,
  ChangeStoryChatActionPayload,
  FillRatioListActionPayload,
  IStoryMessage,
  IStoryTellerSettings,
} from './typedefs';

const initialState: StoryTellerState = {
  isFetching: false,
  isFetchingStory: false,
  isFetchingStoryDelay: false,
  isFetchingStoryUpload: false,
  storyTeller: null,
  allRatio: [],
  progressChatId: [],
  storyFile: null,
  isFileLoading: false,
  isShowStoryTellerSettings: null,
};

const storyTellerSlice = createSlice({
  name: 'storyTeller',
  initialState,
  reducers: {
    startFetching(state) {
      state.isFetching = true;
    },
    stopFetching(state) {
      state.isFetching = false;
    },
    startFetchingStoryUpload(state) {
      state.isFetchingStoryUpload = true;
    },
    stopFetchingStoryUpload(state) {
      state.isFetchingStoryUpload = false;
    },
    setShowStoryTellerSettings(
      state,
      action: PayloadAction<IStoryTellerSettings | null>,
    ) {
      state.isShowStoryTellerSettings = action.payload;
    },
    startFetchingStoryDelay(state) {
      state.isFetchingStoryDelay = true;
    },
    stopFetchingStoryDelay(state) {
      state.isFetchingStoryDelay = false;
    },
    startFetchingStory(state) {
      state.isFetchingStory = true;
    },
    stopFetchingStory(state) {
      state.isFetchingStory = false;
    },
    fillStory(state, action: PayloadAction<FillStoryActionPayload>) {
      state.storyTeller = action.payload[0];
    },
    setProgressChatId(state, action: PayloadAction<number>) {
      state.progressChatId = [...state.progressChatId, action.payload];
    },
    updateProgressChatId(state, action: PayloadAction<number>) {
      state.progressChatId = state.progressChatId.filter(
        (id) => id !== action.payload,
      );
    },
    updateStoryMessages(state, action: PayloadAction<IStoryMessage>) {
      if (state.storyTeller) {
        state.storyTeller = {
          ...state.storyTeller,
          chats: state.storyTeller.chats.map((chat) => {
            if (chat.id === action.payload.chat) {
              const messages = {
                count: 1,
                next: null,
                previous: null,
                results: [action.payload],
              };

              return {
                ...chat,
                messages,
              };
            }

            return chat;
          }),
        };
      }
    },
    fillStoryMessages(
      state,
      action: PayloadAction<{
        data: FillStoryMessagesActionPayload;
        chatId: number;
      }>,
    ) {
      if (state.storyTeller) {
        state.storyTeller = {
          ...state.storyTeller,
          chats: state.storyTeller.chats.map((chat) => {
            if (chat.id === action.payload.chatId) {
              const skeletonArray = Array(6)
                .fill({
                  id: Date.now(),
                  prompt: '',
                  created_at: '',
                  isSkeleton: true,
                  images: [],
                })
                .map((item, index) => ({
                  ...item,
                  id: Date.now() + index,
                }));
              const skeleton = {
                ...action.payload.data.results[0],
                prompts: skeletonArray,
              };

              return {
                ...chat,
                messages:
                  action.payload.data.results[0]?.prompts.length === 0
                    ? {
                        ...action.payload.data,
                        results: [skeleton],
                      }
                    : action.payload.data,
              };
            }

            return chat;
          }),
        };
      }
    },
    fillMoreStoryMessages(
      state,
      action: PayloadAction<{
        data: FillMoreStoryMessagesActionPayload;
        chatId: number;
      }>,
    ) {
      if (state.storyTeller) {
        state.storyTeller = {
          ...state.storyTeller,
          chats: state.storyTeller.chats.map((chat) => {
            if (chat.id === action.payload.chatId) {
              return {
                ...chat,
                messages: {
                  ...action.payload.data,
                  results: [
                    ...(chat.messages ? chat.messages.results : []),
                    ...action.payload.data.results,
                  ],
                },
              };
            }

            return chat;
          }),
        };
      }
    },
    updateChat(state, action: PayloadAction<UpdateStoryChatActionPayload>) {
      if (state.storyTeller) {
        state.storyTeller = {
          ...state.storyTeller,
          chats: [action.payload, ...state.storyTeller.chats],
        };
      }
    },
    removeStoryChat(state, action: PayloadAction<number>) {
      if (state.storyTeller) {
        state.storyTeller = {
          ...state.storyTeller,
          chats: state.storyTeller.chats.filter(
            (chat) => chat.id !== action.payload,
          ),
        };
      }
    },
    changeStoryChat(
      state,
      action: PayloadAction<ChangeStoryChatActionPayload>,
    ) {
      if (state.storyTeller) {
        state.storyTeller = {
          ...state.storyTeller,
          chats: state.storyTeller.chats.map((chat) => {
            if (chat.id === action.payload.id) {
              return {
                ...chat,
                name: action.payload.name,
              };
            }

            return chat;
          }),
        };
      }
    },
    fillRatioList(state, action: PayloadAction<FillRatioListActionPayload>) {
      state.allRatio = action.payload.map((item) => ({
        ...item,
        name: item.ratio,
        id: uuidv4(),
      }));
    },
    setFile(state, action: PayloadAction<FormData | null>) {
      state.storyFile = action.payload;
    },
    setIsFileLoading(state, action: PayloadAction<boolean>) {
      state.isFileLoading = action.payload;
    },
    // INJECT
  },
});

export default storyTellerSlice;
