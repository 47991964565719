import { createSelector } from 'reselect';

import { RootState } from '@setup/typedefs';

const authSelector = (state: RootState) => state.auth;

export const getIsAuthFetching = createSelector([authSelector], (result) => {
  return result.isFetching;
});

export const getAllowCountdown = createSelector([authSelector], (result) => {
  return result.allowCountdown;
});

export const getIsInitialize = createSelector([authSelector], (result) => {
  return result.isInitialised;
});

export const getIsAuthenticate = createSelector([authSelector], (result) => {
  return result.isAuthenticated;
});

export const getEmail = createSelector([authSelector], (result) => {
  return result.signUpEmail;
});

export const getResentCodeTimer = createSelector([authSelector], (result) => {
  return result.resentCodeTimer;
});
