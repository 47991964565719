import * as effects from 'redux-saga/effects';
import { api } from '@REST/api';
import { handleError } from '@bus/ui/saga/workers/handleError';
import { throwError } from '@bus/ui/saga/workers/throwError';

import { profileActions } from '../../actions';
import { FillTokensSettingsActionPayload } from '@bus/profile/typedefs';

export function* fetchTokensSettings() {
  try {
    yield effects.put(profileActions.startFetching());
    const response: Response = yield effects.apply(api, api.get, [
      { endpoint: 'tokens/settings/' },
    ]);
    if (!response.ok) {
      yield throwError(response);
    }
    const data: FillTokensSettingsActionPayload = yield effects.call([
      response,
      'json',
    ]);

    yield effects.put(profileActions.fillTokensSettings(data));
  } catch (e) {
    yield handleError(e);
  } finally {
    yield effects.put(profileActions.stopFetching());
  }
}
