import React, { FC } from 'react';

import { Box, Button, Typography, useTheme } from '@mui/material';

import { styles } from './styles';
import { Link, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import { book } from '@routes/book';
import LogoIcon from '@components/common/Logo/LogoIcon';

type AuthAppBarProps = {};

export const AuthAppBar: FC<AuthAppBarProps> = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const handleClick = () => {
    navigate(location.pathname === book.signIn ? book.signUp : book.signIn);
  };

  return (
    <Box sx={styles.authAppBar}>
      <Box>
        <Link to={book.signIn} style={{ textDecoration: 'none' }}>
          <Box display={'flex'} alignItems={'center'}>
            <LogoIcon
              sx={{
                width: '41px',
                height: '40px',
              }}
              viewBox={'0 0 41 40'}
              htmlColor={theme.palette.text.primary}
            />
          </Box>
        </Link>
      </Box>
      <Button variant={'primary'} onClick={handleClick}>
        <Typography variant={'button'} color={'primary.contrastText'}>
          {location.pathname === book.signIn ? 'Sign Up' : 'Sign In'}
        </Typography>
      </Button>
    </Box>
  );
};

export default AuthAppBar;
