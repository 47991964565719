import * as effects from 'redux-saga/effects';
import { api } from '@REST/api';
import { handleError } from '@bus/ui/saga/workers/handleError';
import { throwError } from '@bus/ui/saga/workers/throwError';

import { profileActions } from '../../actions';
import { IProfile } from '@bus/profile/typedefs';
import { call, put } from 'redux-saga/effects';

export function* finishOnboarding({ payload }: any) {
  try {
    yield effects.put(profileActions.startFetching());
    const body: string = yield effects.call(JSON.stringify, payload);
    const response: Response = yield effects.apply(api, api.patch, [
      { endpoint: 'users/', body },
    ]);

    const data: IProfile = yield call([response, 'json']);

    if (!response.ok) {
      yield throwError(response);
    }

    yield put(profileActions.fillProfile(data));
  } catch (e) {
    yield handleError(e);
  } finally {
    yield effects.put(profileActions.stopFetching());
  }
}
