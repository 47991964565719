import { Theme } from '@mui/material';

export const styles = {
  imageWrapper: (theme: Theme, width: number, height: number) => ({
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '90svw',
    maxWidth: `calc(90svh * (${width} / ${height}))`,
    height: `calc(90svw * (${height} / ${width}))`,
    maxHeight: '90svh',
    transform: 'translate(-50%,-50%)',
    zIndex: 10003,
    background: theme.palette.primary.contrastText,
    border: `1px solid ${theme.palette.primary.contrastText}`,
    borderRadius: '10px',
    boxShadow:
      '0px 0.4981061816215515px 3.154672384262085px 0px rgba(30, 6, 39, 0.02), 0px 1.1970183849334717px 7.581116199493408px 0px rgba(30, 6, 39, 0.03), 0px 2.2538793087005615px 14.274569511413574px 0px rgba(30, 6, 39, 0.04), 0px 4.020535469055176px 25.46339225769043px 0px rgba(30, 6, 39, 0.05), 0px 7.519969463348389px 47.6264762878418px 0px rgba(30, 6, 39, 0.06), 0px 18px 114px 0px rgba(30, 6, 39, 0.08)',
  }),
  imageWrapperSwap: (theme: Theme, aspectRatio: number) => ({
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: '50%',
    left: '50%',
    aspectRatio,
    maxWidth: '90svw',
    maxHeight: '90svh',
    transform: 'translate(-50%,-50%)',
    zIndex: 10003,
    background: theme.palette.primary.contrastText,
    border: `1px solid ${theme.palette.primary.contrastText}`,
    borderRadius: '10px',
    boxShadow:
      '0px 0.4981061816215515px 3.154672384262085px 0px rgba(30, 6, 39, 0.02), 0px 1.1970183849334717px 7.581116199493408px 0px rgba(30, 6, 39, 0.03), 0px 2.2538793087005615px 14.274569511413574px 0px rgba(30, 6, 39, 0.04), 0px 4.020535469055176px 25.46339225769043px 0px rgba(30, 6, 39, 0.05), 0px 7.519969463348389px 47.6264762878418px 0px rgba(30, 6, 39, 0.06), 0px 18px 114px 0px rgba(30, 6, 39, 0.08)',
  }),
  overlay: (theme: Theme) => ({
    display: 'flex',
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    transition: 'all 0.3s ease-in-out',
    background: theme.palette.common.black,
    zIndex: 10002,
    opacity: 0.4,
  }),
  closeButton: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    background: 'rgba(0, 0, 0, 0.4)',
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.4)',
    },
  },
  deleteButton: {
    position: 'absolute',
    bottom: 'calc(10px + 60px)',
    right: '10px',
    background: 'rgba(0, 0, 0, 0.4)',
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.4)',
    },
  },
  dark: (isOpacityBackground: boolean) => ({
    background: isOpacityBackground ? 'none' : 'rgba(0, 0, 0, 0.5)',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 9999,
  }),
  withoutBg: {
    background: 'none',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1299,
  },
  notificationWrapper: {
    '& .notistack-SnackbarContainer': {
      maxWidth: '100%',
      width: '100%',
      top: '80px',
      zIndex: '9997',
      '& > div': {
        width: '100%',
        '& > .notistack-CollapseWrapper': {
          padding: 0,
        },
      },
    },
  },
};
