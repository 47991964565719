import { ModalTypes } from '@core/Modal/types';

import { ConfirmOrCancel } from './ConfirmOrCancel';

import { Confirm } from './Confirm';

import { LimitExceeded } from './LimitExceeded';

import { ServerError } from './ServerError';

import { EditChat } from './EditChat';

import { AppUpdates } from './AppUpdates';

import { VisualAdjustments } from './VisualAdjustments';

import { CropperWindow } from './CropperWindow';

import { Subscribe } from './Subscribe';
import { TurnOnAudio } from './TurnOnAudio';
import { EditStoryChat } from './EditStoryChat';
import { OrderTokens } from './OrderTokens';
import { LimitExhausted } from './LimitExhausted';
import { TrialInformationWindow } from './TrialInformationWindow';
// IMPORTS
export const registeredModals: ModalTypes.RegisteredModals = {
  ConfirmOrCancel,
  Confirm,
  LimitExceeded,
  LimitExhausted,
  ServerError,
  EditChat,
  AppUpdates,
  VisualAdjustments,
  CropperWindow,
  Subscribe,
  TurnOnAudio,
  EditStoryChat,
  OrderTokens,
  TrialInformationWindow,
  // INJECT
};
