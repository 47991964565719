import * as effects from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { api } from '@REST/api';
import { handleError } from '@bus/ui/saga/workers/handleError';
import { throwError } from '@bus/ui/saga/workers/throwError';

import { createdImagesActions } from '../../actions';
import { FetchCreatedImagesActionPayload } from '../../typedefs';
import { FetchListWorkers } from '@setup/typedefs';

export function* fetchCreatedImages({
  payload,
}: PayloadAction<FetchListWorkers>) {
  try {
    yield effects.put(createdImagesActions.startFetching());
    const response: Response = yield effects.apply(api, api.get, [
      { endpoint: 'users/images/', query: payload.query },
    ]);
    if (!response.ok) {
      yield throwError(response);
    }
    const data: FetchCreatedImagesActionPayload = yield effects.call([
      response,
      'json',
    ]);
    yield effects.put(createdImagesActions.fillCreatedImages(data));
  } catch (e) {
    yield handleError(e);
  } finally {
    yield effects.put(createdImagesActions.stopFetching());
  }
}
