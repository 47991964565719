import { Theme } from '@mui/material/styles';

export const styles = {
  limitExceeded: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '450px',
    width: '100%',
  },
  limit: (theme: Theme) => ({
    position: 'relative',
    width: '260px',
    height: '260px',
    borderRadius: '50%',
    background: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '30px 0 40px',
    boxShadow:
      '0px 18px 114px rgba(30, 6, 39, 0.08), 0px 7.51997px 47.6265px rgba(30, 6, 39, 0.0575083), 0px 4.02054px 25.4634px rgba(30, 6, 39, 0.0476886), 0px 2.25388px 14.2746px rgba(30, 6, 39, 0.04), 0px 1.19702px 7.58112px rgba(30, 6, 39, 0.0323114), 0px 0.498106px 3.15467px rgba(30, 6, 39, 0.0224916)',
  }),
  wrapper: {
    padding: '0 20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  buttonWrapper: (theme: Theme) => ({
    width: '100%',
    mt: '40px',
    padding: '10px 20px',
    borderTop: `1px solid ${theme.palette.divider}`,
  }),
};
