import * as effects from 'redux-saga/effects';

import { projectsActions } from '../actions';
// eslint-disable-next-line prettier/prettier
import {
  fetchProjects,
  postRate,
  readGuidance,
  createNewChat,
  updateChat,
  deleteChat,
  fetchMessageAudio,
  fetchVisualizePrompt,
  fetchAutoName,
  createNewMessageChat,
  projectMatching,
  fetchSuggestingQuestions,
} from './workers';

// IMPORTS
function* watchFetchProjects() {
  yield effects.takeEvery(projectsActions.fetchProjects.type, fetchProjects);
}
function* watchPostRate() {
  yield effects.takeEvery(projectsActions.postRate.type, postRate);
}
function* watchReadGuidance() {
  yield effects.takeEvery(projectsActions.readGuidance.type, readGuidance);
}
function* watchCreateNewChat() {
  yield effects.takeEvery(projectsActions.createNewChat.type, createNewChat);
}
function* watchUpdateChat() {
  yield effects.takeEvery(projectsActions.updateChat.type, updateChat);
}
function* watchDeleteChat() {
  yield effects.takeEvery(projectsActions.deleteChat.type, deleteChat);
}
function* watchFetchMessageAudio() {
  yield effects.takeEvery(
    projectsActions.fetchMessageAudio.type,
    fetchMessageAudio,
  );
}
function* watchFetchVisualizePrompt() {
  yield effects.takeEvery(
    projectsActions.fetchVisualizePrompt.type,
    fetchVisualizePrompt,
  );
}
function* watchFetchAutoName() {
  yield effects.takeEvery(projectsActions.fetchAutoName.type, fetchAutoName);
}
function* watchCreateNewMessageChat() {
  yield effects.takeEvery(
    projectsActions.createNewMessageChat.type,
    createNewMessageChat,
  );
}
function* watchProjectMatching() {
  yield effects.takeEvery(
    projectsActions.projectMatching.type,
    projectMatching,
  );
}
function* watchFetchSuggestingQuestions() {
  yield effects.takeEvery(
    projectsActions.fetchSuggestingQuestions.type,
    fetchSuggestingQuestions,
  );
}
// WATCHERS
export function* watchProjects() {
  // eslint-disable-next-line prettier/prettier
  yield effects.all([
    effects.call(watchFetchProjects),
    effects.call(watchPostRate),
    effects.call(watchReadGuidance),
    effects.call(watchCreateNewChat),
    effects.call(watchUpdateChat),
    effects.call(watchDeleteChat),
    effects.call(watchFetchMessageAudio),
    effects.call(watchFetchVisualizePrompt),
    effects.call(watchFetchAutoName),
    effects.call(watchCreateNewMessageChat),
    effects.call(watchProjectMatching),
    effects.call(watchFetchSuggestingQuestions),
    // INJECT
  ]);
}
