import * as effects from 'redux-saga/effects';
import { api } from '@REST/api';
import { handleError } from '@bus/ui/saga/workers/handleError';
import { throwError } from '@bus/ui/saga/workers/throwError';

import { savedMessagesActions } from '../../actions';
import {
  FetchMessagePaginationActionPayload,
  FillMessagePaginationActionPayload,
} from '../../typedefs';
import { PayloadActionWithNavigateMeta } from '@core/FinalForm/typedefs';
import { book } from '@routes/book';
import { projectsActions } from '@bus/projects/actions';

export function* fetchMessagePagination({
  payload,
  meta: { navigate },
}: PayloadActionWithNavigateMeta<FetchMessagePaginationActionPayload>) {
  try {
    yield effects.put(savedMessagesActions.startFetching());
    const response: Response = yield effects.apply(api, api.get, [
      { endpoint: `messages/${payload.messageId}/position` },
    ]);
    if (!response.ok) {
      yield throwError(response);
    }

    const data: FillMessagePaginationActionPayload = yield effects.call([
      response,
      'json',
    ]);
    yield effects.put(
      projectsActions.clearMessages({ chatId: data.results[0].chat.id }),
    );
    yield effects.put(
      projectsActions.fillMessages({ data, chatId: data.results[0].chat.id }),
    );
    yield effects.put(savedMessagesActions.setMessageId(+payload.messageId));
    navigate(`${book.chat}/${payload.project}/${data.results[0].chat.id}`);
  } catch (e) {
    yield handleError(e);
  } finally {
    yield effects.put(savedMessagesActions.stopFetching());
  }
}
