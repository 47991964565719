// eslint-disable-next-line @typescript-eslint/no-unused-vars,prettier/prettier
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// eslint-disable-next-line prettier/prettier
import {
  SavedMessagesState,
  FillSavedMessagesActionPayload,
  ISavedMessages,
  PushMoreSavedMessagesActionPayload,
} from './typedefs';

const initialState: SavedMessagesState = {
  isFetching: false,
  savedProjects: [],
  scrollToMessageId: null,
};

const savedMessagesSlice = createSlice({
  name: 'savedMessages',
  initialState,
  reducers: {
    startFetching(state) {
      state.isFetching = true;
    },
    stopFetching(state) {
      state.isFetching = false;
    },
    setMessageId(state, action: PayloadAction<number>) {
      state.scrollToMessageId = action.payload;
    },
    clearMessageId(state) {
      state.scrollToMessageId = null;
    },
    fillSavedProjects(
      state,
      action: PayloadAction<FillSavedMessagesActionPayload>,
    ) {
      const withMessages = action.payload.map((project) => ({
        ...project,
        messages: null,
      }));
      if (action.payload.length) {
        state.savedProjects = [
          { id: 0, name: 'All saved', description: '', messages: null },
          ...withMessages,
        ];
      } else {
        state.savedProjects = withMessages;
      }
    },
    fillSavedMessages(
      state,
      action: PayloadAction<{
        data: ISavedMessages;
        projectId: string;
      }>,
    ) {
      state.savedProjects = state.savedProjects
        .map((project) => {
          if (project.id === +action.payload.projectId) {
            return {
              ...project,
              messages: {
                ...action.payload.data,
                results: [...action.payload.data.results],
              },
            };
          }

          return project;
        })
        .filter((project) => {
          if (!project.messages) {
            return true;
          }

          return !!project.messages.results.length;
        });
    },
    removeSavedMessages(state, action: PayloadAction<number>) {
      state.savedProjects = state.savedProjects.map((project) => {
        return {
          ...project,
          messages: project.messages
            ? {
                ...project.messages,
                results: project.messages.results.filter(
                  (message) => message.id !== action.payload,
                ),
              }
            : null,
        };
      });
    },
    removeSavedProject(state, action: PayloadAction<number>) {
      state.savedProjects = state.savedProjects.filter((project) => {
        if (!project.messages) {
          return true;
        }

        return project.messages.results.some(
          (message) => message.id === action.payload,
        );
      });
    },
    pushMoreSavedMessages(
      state,
      action: PayloadAction<{
        data: PushMoreSavedMessagesActionPayload;
        projectId: number | null;
      }>,
    ) {
      state.savedProjects = state.savedProjects.map((project) => {
        if (project.id === action.payload.projectId) {
          return {
            ...project,
            messages: {
              ...action.payload.data,
              results: [
                ...(project.messages ? project.messages.results : []),
                ...action.payload.data.results,
              ],
            },
          };
        }

        return project;
      });
    },
    // INJECT
  },
});

export default savedMessagesSlice;
