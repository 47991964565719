// @ts-nocheck
import { handleError } from '@bus/ui/saga/workers/handleError';
import * as effects from 'redux-saga/effects';
import { profileActions } from '@bus/profile/actions';

export function* handleNotificationAlive(payload: any) {
  try {
    yield effects.put(profileActions.setAmountOfUsers(payload.data.users));
  } catch (e) {
    yield handleError(e);
  }
}
