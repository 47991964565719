import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const Collapse: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M10.4133 20.8333C9.83986 20.8333 9.37501 20.3685 9.37501 19.7951L9.37501 15.625L5.20834 15.625C4.63305 15.625 4.16668 15.1586 4.16668 14.5833C4.16668 14.008 4.63305 13.5417 5.20834 13.5417L11.4583 13.5417L11.4515 19.7962C11.4509 20.3692 10.9862 20.8333 10.4133 20.8333Z" />
      <path d="M14.5868 4.16667C15.1602 4.16667 15.625 4.63152 15.625 5.20493L15.625 9.375L19.7917 9.375C20.367 9.375 20.8333 9.84137 20.8333 10.4167C20.8333 10.992 20.367 11.4583 19.7917 11.4583L13.5417 11.4583L13.5485 5.2038C13.5491 4.63083 14.0138 4.16667 14.5868 4.16667Z" />
    </SvgIcon>
  );
};

export default Collapse;
