import React from 'react';

import {
  FormControlLabel,
  Checkbox as MuiCheckbox,
  // Theme,
  Typography,
  TypographyTypeMap,
} from '@mui/material';
import DefaultIcon from '@components/common/inputs/Checkbox/DefaultIcon';
import CheckedIcon from '@components/common/inputs/Checkbox/CheckedIcon';
import { styles } from '@components/common/inputs/Checkbox/styles';

type CheckboxProps = {
  checked?: boolean;
  color?: string;
  onChange?: (e: boolean) => void;
  label?: string;
  error?: boolean;
  disabled?: boolean;
  helperText?: string;
  labelVariant?: TypographyTypeMap['props']['variant'];
};

export const Checkbox: React.FC<CheckboxProps> = ({
  onChange,
  label,
  error,
  helperText,
  labelVariant = 'body1',
  checked,
  color = 'primary',
  disabled = false,
  ...props
}) => {
  return (
    <FormControlLabel
      {...props}
      checked={checked}
      sx={[error ? styles.error : styles.label]}
      control={
        <MuiCheckbox
          className={'checkbox'}
          disabled={disabled}
          icon={<DefaultIcon />}
          checkedIcon={<CheckedIcon />}
          onClick={() => {
            onChange?.(!checked);
          }}
        />
      }
      label={
        label && (
          <Typography
            ml={'10px'}
            variant={labelVariant}
            color={error ? 'error' : color}>
            {label}
          </Typography>
        )
      }
    />
  );
};
