import { useState, useEffect } from 'react';

const useAspectRatio = (src?: string) => {
  const [aspectRatio, setAspectRatio] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (src) {
      const img = new Image();
      img.onload = () => {
        // Вычисляем соотношение сторон после загрузки изображения
        const ratio = img.width / img.height;
        setAspectRatio(ratio);
        setIsLoading(false);
      };
      img.onerror = () => {
        // Обработка ошибки загрузки изображения
        setIsLoading(false);
      };
      img.src = src;
    }
  }, [src]); // Зависимость от src, чтобы реагировать на изменение входного URL

  return [aspectRatio, isLoading];
};

export default useAspectRatio;
