import * as effects from 'redux-saga/effects';
import { api } from '@REST/api';
import { handleError } from '@bus/ui/saga/workers/handleError';
import { throwError } from '@bus/ui/saga/workers/throwError';

import { profileActions } from '../../actions';
import { getIsAuthenticate } from '@bus/auth/selectors';

export function* fetchUserAliveEveryMinute() {
  const isAuthenticated: string = yield effects.select(getIsAuthenticate);
  try {
    yield effects.put(profileActions.startFetching());
    const response: Response = yield effects.apply(api, api.get, [
      { endpoint: 'users/alive', unsafe: !isAuthenticated },
    ]);
    if (!response.ok) {
      yield throwError(response);
    }
  } catch (e) {
    yield handleError(e);
  } finally {
    yield effects.put(profileActions.stopFetching());
  }
}

export function* fetchUserAlive() {
  while (true) {
    yield effects.call(fetchUserAliveEveryMinute);
    yield effects.delay(60000);
  }
}
