import React from 'react';

import { Box, Button, Typography } from '@mui/material';

import { ModalTypes } from '@core/Modal/types';

import { styles } from './styles';
import { useNavigate } from 'react-router-dom';
import { book } from '@routes/book';
import { useSelector } from 'react-redux';
import { getProfile } from '@bus/profile/selectors';

type LimitExhaustedProps = {
  text: string;
  buttonLabel: string;
  limitText: string;
};

export const LimitExhausted: React.FC<
  ModalTypes.ModalComponentProps<LimitExhaustedProps>
> = ({ closeFn, text, buttonLabel, limitText }) => {
  const navigate = useNavigate();
  const profile = useSelector(getProfile);

  const closeModal = () => {
    closeFn();
    navigate(book.profile);
  };

  return (
    <Box sx={styles.limitExhausted}>
      <Box sx={styles.container}>
        <Typography variant={'h5'} color={'text.primary'}>
          {profile?.subscription?.subscription_plan.code === 'free'
            ? 'Free CREATE tokens exhausted!'
            : 'Limits exhausted!'}
        </Typography>
        {profile?.subscription?.subscription_plan.code !== 'free' && (
          <Typography variant={'subtitle2'} color={'text.primary'}>
            {limitText}
          </Typography>
        )}
        <Typography variant={'body1'} color={'text.primary'}>
          {text}
        </Typography>
        <Button
          variant={'primary'}
          fullWidth
          sx={{ marginTop: '40px' }}
          onClick={closeModal}>
          {buttonLabel}
        </Button>
      </Box>
    </Box>
  );
};

export default LimitExhausted;

export const LimitExhaustedContent = {
  updateSubscription: {
    text: 'CREATE available, but inactive without subscription. Renew for complete access.',
    buttonLabel: 'Upgrade plan',
  },
  buyTokens: {
    text: 'Extend your access instantly with CREATE. Purchase now for uninterrupted usage.',
    buttonLabel: 'Purchase CREATE on Demand',
  },
};
