export const styles = {
  orderTokens: {
    padding: '20px',
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
  },
  buttonWrapper: {
    '& > div': {
      display: 'flex',
      width: '100%',
    },
  },
};
