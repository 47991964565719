import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const Support: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M8.99463 14C9.54691 14 9.99463 13.5523 9.99463 13C9.99463 12.4477 9.54691 12 8.99463 12C8.44234 12 7.99463 12.4477 7.99463 13C7.99463 13.5523 8.44234 14 8.99463 14Z" />
      <path d="M14.9946 14C15.5469 14 15.9946 13.5523 15.9946 13C15.9946 12.4477 15.5469 12 14.9946 12C14.4423 12 13.9946 12.4477 13.9946 13C13.9946 13.5523 14.4423 14 14.9946 14Z" />
      <path d="M20 11.0186C19.3597 7.04186 16.0516 4 12.0631 4C8.02127 4 3.67265 7.50233 4.01947 13C7.31429 11.5907 9.79541 8.52093 10.5024 4.7814C12.2498 8.45116 15.8381 10.9767 20 11.0186Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2.5C6.75329 2.5 2.5 6.75329 2.5 12H1.5C1.5 6.20101 6.20101 1.5 12 1.5C17.799 1.5 22.5 6.20101 22.5 12C22.5 17.799 17.799 22.5 12 22.5V21.5C17.2467 21.5 21.5 17.2467 21.5 12C21.5 6.75329 17.2467 2.5 12 2.5Z"
      />
      <rect x="9" y="21" width="3" height="2" />
    </SvgIcon>
  );
};

export default Support;
