import { Theme } from '@mui/material/styles';

export const styles = {
  turnOnAudio: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '480px',
    width: '100%',
  },
  iconWrapper: (theme: Theme) => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '2px 10px',
    background: theme.palette.common.surface['surface 1'],
  }),
};
