import React from 'react';

import { Box, Button, Typography, useTheme } from '@mui/material';

import { ModalTypes } from '@core/Modal/types';

import { styles } from './styles';
import Circle from '@components/modals/LimitExceeded/Circle';
import CircleBar from '@components/modals/LimitExceeded/CircleBar';
import CircleBack from '@components/modals/LimitExceeded/CircleBack';
import { useSelector } from 'react-redux';
import { getProfile } from '@bus/profile/selectors';
import { useNavigate } from 'react-router-dom';
import { book } from '@routes/book';
import { SubPlansCode } from '@bus/subscription/typedefs';

type LimitExceededProps = {
  text: string;
  limit: number;
  description: string;
};

export const LimitExceeded: React.FC<
  ModalTypes.ModalComponentProps<LimitExceededProps>
> = ({ closeFn, text, limit, description }) => {
  const theme = useTheme();
  const profile = useSelector(getProfile);
  const navigate = useNavigate();
  const closeModal = () => {
    closeFn();
    profile?.subscription?.subscription_plan.code === SubPlansCode.free &&
      navigate(book.profile);
  };

  return (
    <Box sx={styles.limitExceeded}>
      <Box sx={styles.limit}>
        <Circle
          sx={{ position: 'absolute', top: 0, left: 0, fontSize: '260px' }}
          width={'260'}
          height={'260'}
          viewBox={'0 0 260 260'}
          stroke={theme.palette.secondary.main}
          fill={'none'}
        />
        <CircleBar
          sx={{
            position: 'absolute',
            top: '20px',
            left: '50%',
            fontSize: '220px',
            transform: 'translateX(-50%)',
            zIndex: 2,
          }}
          width={'220'}
          height={'220'}
          viewBox={'0 0 220 220'}
          stroke={theme.palette.text.primary}
          fill={'none'}
        />
        <CircleBack
          sx={{
            position: 'absolute',
            top: '20px',
            left: '50%',
            fontSize: '220px',
            transform: 'translateX(-50%)',
          }}
          width={'220'}
          height={'220'}
          viewBox={'0 0 220 220'}
          stroke={theme.palette.secondary.main}
          fill={'none'}
        />
        <Typography variant={'h2'} color={'text.primary'}>
          {limit}
        </Typography>
        <Typography
          variant={'body1'}
          color={'text.secondary'}
          maxWidth={'82px'}
          textAlign={'center'}>
          {text}
        </Typography>
      </Box>
      <Box sx={styles.wrapper}>
        <Typography
          variant={'h4'}
          color={'text.primary'}
          mb={'16px'}
          textAlign={'center'}>
          Limits exhausted!
        </Typography>
        <Typography
          variant={'body1'}
          color={'text.primary'}
          textAlign={'center'}>
          {description}
        </Typography>
      </Box>
      <Box sx={styles.buttonWrapper}>
        <Button onClick={closeModal} variant={'primary'} fullWidth>
          <Typography variant={'button'} color={'primary.contrastText'}>
            {profile?.subscription?.subscription_plan.code !== SubPlansCode.free
              ? `Go back`
              : `Upgrade plan`}
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default LimitExceeded;
