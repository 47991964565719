import React, { FC } from 'react';

import { Box, Typography } from '@mui/material';

import { styles } from './styles';
import { profileActions } from '@bus/profile/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getProfile } from '@bus/profile/selectors';
import { book } from '@routes/book';
import { getStories } from '@bus/storyTeller/selectors';
import IconContainer from '@components/common/IconContainer';

type ExperienceItemProps = {
  iconDark: string;
  iconLight: string;
  name: string;
  link: string;
};

export const ExperienceItem: FC<ExperienceItemProps> = ({
  iconDark,
  iconLight,
  name,
  link,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const profile = useSelector(getProfile);
  const stories = useSelector(getStories);

  const handleCardClick = () => {
    if (!profile?.subscription) {
      navigate(book.forUnsubscribedUsers);

      return;
    }
    const project = {
      id: 1,
      name,
      chats: [],
      description: '',
      icon: '',
      guidances: [],
      icon_dark: iconDark,
      icon_light: iconLight,
      years: [],
    };
    dispatch(profileActions.setCurrentDataset(project));
    dispatch(profileActions.setDatasetsClosed());
    if (stories?.chats.length) {
      navigate(`${book.storyTeller}/${stories.chats[0].id}`);
    } else {
      navigate(link);
    }
  };

  return (
    <Box sx={styles.experienceItem} onClick={handleCardClick}>
      <Box sx={styles.wrapper}>
        <IconContainer darkIcon={iconDark} lightIcon={iconLight} size={32} />
        <Box
          display={'flex'}
          alignItems={'center'}
          width={'100%'}
          justifyContent={'flex-end'}>
          <Box sx={styles.beta}>
            <Typography
              variant={'overline'}
              fontWeight={500}
              letterSpacing={'0.4px'}
              color={'common.warning.contrast text'}>
              BETA
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box display={'flex'} flexDirection={'column'}>
        <Typography variant={'h6'} color={'text.primary'}>
          {name}
        </Typography>
        <Typography variant={'caption'} color={'text.primary'} mt={'8px'}>
          Illustrate your stories with incredible imagery in moments.
        </Typography>
      </Box>
    </Box>
  );
};

export default ExperienceItem;
