import { createSelector } from 'reselect';

import { RootState } from '@setup/typedefs';

export const guidanceSelector = (state: RootState) => state.guidance;

export const getIsGuidanceFetching = createSelector(
  [guidanceSelector],
  (result) => {
    return result.isFetching;
  },
);

export const getGuidance = createSelector([guidanceSelector], (result) => {
  return result.guidance;
});

export const getNextGuidanceUrl = createSelector(
  [guidanceSelector],
  ({ guidance }) => {
    return guidance?.next ?? null;
  },
);
