import * as effects from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { api } from '@REST/api';
import { handleError } from '@bus/ui/saga/workers/handleError';
import { throwError } from '@bus/ui/saga/workers/throwError';

import { projectsActions } from '../../actions';
import {
  FetchSuggestingQuestionsActionPayload,
  FillSuggestingQuestionsActionPayload,
} from '../../typedefs';

export function* fetchSuggestingQuestions({
  payload,
}: PayloadAction<FetchSuggestingQuestionsActionPayload>) {
  try {
    // yield effects.put(projectsActions.startFetching());
    const body: string = yield effects.call(JSON.stringify, payload);
    const response: Response = yield effects.apply(api, api.get, [
      { endpoint: `chats/${payload.chatId}/suggesting_questions`, body },
    ]);
    if (!response.ok) {
      yield throwError(response);
    }

    const data: FillSuggestingQuestionsActionPayload = yield effects.call([
      response,
      'json',
    ]);

    yield effects.put(
      projectsActions.fillSuggestingQuestions({
        chatId: payload.chatId,
        questions: data.questions,
      }),
    );
  } catch (e) {
    yield handleError(e);
  } finally {
    // yield effects.put(projectsActions.stopFetching());
  }
}
