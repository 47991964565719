import * as effects from 'redux-saga/effects';

import { profileActions } from '../actions';
// eslint-disable-next-line prettier/prettier
import {
  fetchProfile,
  updateProfile,
  finishOnboarding,
  fetchUserAlive,
  deletePhoto,
  deleteAccount,
  fetchTokensSettings,
} from './workers';

// IMPORTS
function* watchFetchProfile() {
  yield effects.takeEvery(profileActions.fetchProfile.type, fetchProfile);
}
function* watchUpdateProfile() {
  yield effects.takeEvery(profileActions.updateProfile.type, updateProfile);
}
function* watchFinishOnboarding() {
  yield effects.takeEvery(
    profileActions.finishOnboarding.type,
    finishOnboarding,
  );
}
function* watchFetchUserAlive() {
  yield effects.takeEvery(profileActions.fetchUserAlive.type, fetchUserAlive);
}
function* watchDeletePhoto() {
  yield effects.takeEvery(profileActions.deletePhoto.type, deletePhoto);
}
function* watchDeleteAccount() {
  yield effects.takeEvery(profileActions.deleteAccount.type, deleteAccount);
}

function* watchFetchTokensSettings() {
  yield effects.takeEvery(
    profileActions.fetchTokensSettings.type,
    fetchTokensSettings,
  );
}
// WATCHERS
export function* watchProfile() {
  // eslint-disable-next-line prettier/prettier
  yield effects.all([
    effects.call(watchFetchProfile),
    effects.call(watchUpdateProfile),
    effects.call(watchFinishOnboarding),
    effects.call(watchFetchUserAlive),
    effects.call(watchDeletePhoto),
    effects.call(watchDeleteAccount),
    effects.call(watchFetchTokensSettings),
    // INJECT
  ]);
}
