import { createAction } from '@reduxjs/toolkit';

import optionsSlice from './slice';

export const optionsActions = {
  ...optionsSlice.actions,
  midjourneyParameters: createAction('options/midjourneyParameters'),
  fetchStorytellersStyles: createAction('options/fetchStorytellersStyles'),
  // INJECT
};
